import { Account } from '../models/account.model';
import { Settings } from '../models/settings.model';
import { AccountActions, AccountActionsTypes } from './account.actions';

export const featureName = 'account';

export interface AccountState {
  account: Account;
  settings: Settings;
  gettingAccount: boolean;
  gettingSettings: boolean;
}

export const initialAccountState: AccountState = {
  account: null,
  settings: null,
  gettingAccount: false,
  gettingSettings: false,
};

export function accountReducer(
  state: AccountState = initialAccountState,
  action: AccountActions
): AccountState {
  switch (action.type) {
    case AccountActionsTypes.AccountLoaded:
      return {
        ...state,
        account: action.payload.data,
      };
    case AccountActionsTypes.CustomerIdCreated:
      return {
        ...state,
        account: {
          ...state.account,
          customerId: action.payload.customerId,
        },
      };
    case AccountActionsTypes.SettingsLoaded:
      return {
        ...state,
        settings: action.payload.data,
      };
    case AccountActionsTypes.GettingAccountChange:
      return {
        ...state,
        gettingAccount: action.payload.state,
      };
    case AccountActionsTypes.GettingSettingsChange:
      return {
        ...state,
        gettingSettings: action.payload.state,
      };
    default:
      return state;
  }
}
