import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sd-selected-item',
  templateUrl: './sd-selected-item.component.html',
  styleUrls: ['./sd-selected-item.component.scss'],
})
export class SdSelectedItemComponent implements OnInit {
  @Input() value: string;
  @Input() avatar: string;
  @Input() avatarRounded = true;
  @Input() removable: boolean;
  @Input() isTag: boolean;
  @Input() tooltip: string;
  @Output() removeButtonClicked = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
}
