import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Locale } from '../../../../dictionary/models/locale.model';
import { DateAdapter } from '@angular/material/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-sd-date-picker',
  templateUrl: './sd-date-picker.component.html',
  styleUrls: ['./sd-date-picker.component.scss'],
})
export class SdDatePickerComponent implements OnInit {
  @Input() placeholder: string;
  @Input() controller: UntypedFormControl;
  @Input() error: string;
  @Input() startValue: string;
  @Input() locale: Locale;
  @Input() suffixText: string;
  @Input() suffix: string;
  @Input() suffixIsClickable: boolean;
  @Input() suffixIconHasPrimaryColor: boolean;
  @Input() suffixIsImage = true;
  @Output() suffixClicked = new EventEmitter<boolean>();
  @Input() minDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1000)
  );
  @Input() maxDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1000)
  );
  @Output() dateChange = new EventEmitter<Date>();
  cdn = environment.cdn;
  primaryAccentColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primaryAccentColor');

  constructor(private readonly dateAdapter: DateAdapter<any>) {}

  ngOnInit(): void {
    this.dateAdapter.setLocale(this.locale.localeid.split('-')[0]);
  }

  onDateChange(event: Date): void {
    this.dateChange.emit(event);
  }

  onSuffix(): void {
    if (this.suffixIsClickable) {
      this.suffixClicked.emit(true);
    }
  }
}
