import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DictionaryState, featureName } from './dictionary.reducer';
import { SubscriptionStatus } from '../models/subscription-status.model';
import {
  currentRoleSelector,
  currentTenantSelector,
} from '../../tenants/store/tenant.selectors';
import { brandsListSelector } from '../../branding/store/brand-list.selectors';
import { ProviderTypeEnum } from '../enums/provider-type.enum';
import { UtilsHelper } from '../../core/helpers/utils.helper';

export const dictionaryStateSelector =
  createFeatureSelector<DictionaryState>(featureName);

export const localesSelector = createSelector(dictionaryStateSelector, state =>
  state.locales ? state.locales : null
);

export const localeDefaultSelector = createSelector(
  dictionaryStateSelector,
  state => (state.localeDefault ? state.localeDefault : null)
);

export const dictionarySelector = createSelector(
  dictionaryStateSelector,
  state => (state.dictionary ? state.dictionary : null)
);

export const sdTypesSelector = createSelector(dictionaryStateSelector, state =>
  state.sideDrawerTypes
    ? state.sideDrawerTypes.filter(type => type.sdtype_enumid !== 'other')
    : null
);

export const sdTypeOtherSelector = createSelector(
  dictionaryStateSelector,
  state =>
    state.sideDrawerTypes
      ? state.sideDrawerTypes.find(type => type.sdtype_enumid === 'other')
      : null
);

export const dicPricesSelector = createSelector(
  dictionaryStateSelector,
  state => (!!state.prices && state.prices.length > 0 ? state.prices : null)
);

export const dicPricesSelectorByPriceId = (payload: { priceId: string }) =>
  createSelector(dicPricesSelector, prices =>
    !!prices && prices.length > 0
      ? prices.find(price => price.stripePriceId === payload.priceId)
      : null
  );

export const relationshipSelector = createSelector(
  dictionaryStateSelector,
  state => state.relationships
);

export const relationshipTypesSelector = createSelector(
  dictionaryStateSelector,
  state => state.relationshipTypes
);

export const relationshipByRelationShipTypeSelector = (payload: {
  relationshipType: string;
}) =>
  createSelector(dictionaryStateSelector, state =>
    state.relationships.filter(
      relationship =>
        !!relationship &&
        relationship.relationship_relationshipgroupid ===
          payload.relationshipType
    )
  );

export const blockedFilesFormatSelector = createSelector(
  dictionaryStateSelector,
  state => state.blockedFileFormats
);

export const imageFilesFormatSelector = createSelector(
  dictionaryStateSelector,
  state => state.imageFileFormats
);

export const videoFilesFormatSelector = createSelector(
  dictionaryStateSelector,
  state => state.videoFileFormats
);

export const cloudIntegrationSelector = createSelector(
  dictionaryStateSelector,
  state =>
    !!state.cloudDrives && state.cloudDrives.length > 0
      ? state.cloudDrives
      : null
);

export const subscriptionsStatusesSelector = createSelector(
  dictionaryStateSelector,
  state => state.subscriptionsStatuses
);

export const subscriptionsStatusByKeyIdSelector = (payload: {
  keyId: string;
}) =>
  createSelector(
    subscriptionsStatusesSelector,
    (subscriptionsStatuses: SubscriptionStatus[]) =>
      subscriptionsStatuses.find(
        item => item.subsstatus_keyid === payload.keyId
      )
  );

export const dicPlanItemFormTypesSelector = createSelector(
  dictionaryStateSelector,
  state => state.planItemFormTypes
);

export const dicNestablePlanItemFormTypesSelector = (payload: {
  nestable: boolean;
}) =>
  createSelector(dicPlanItemFormTypesSelector, items =>
    items.filter(item => item.nestable === payload.nestable)
  );

export const tenantRolesSelector = createSelector(
  dictionaryStateSelector,
  state => state.tenantRoles
);

export const jumpOffButtonsSelector = createSelector(
  dictionaryStateSelector,
  state => state.jumpOffButtons
);

export const tenantRoleByRoleKeyIdSelector = (payload: { keyId: string }) =>
  createSelector(tenantRolesSelector, dicRoles =>
    dicRoles.find(dicRole => dicRole.keyId === payload.keyId)
  );

export const dicCurrenciesSelector = createSelector(
  dictionaryStateSelector,
  state => state.currencies?.filter(currency => currency.enabled)
);

export const dicCurrencyByCurrencySelector = (payload: { currency: string }) =>
  createSelector(
    dicCurrenciesSelector,
    currencies =>
      currencies?.find(currency => currency.currency === payload.currency)
  );

export const vCardLinksSelector = createSelector(
  dictionaryStateSelector,
  state => state.vCardLinks
);
export const dicSdTypeByTenantSelector = createSelector(
  dictionaryStateSelector,
  currentTenantSelector,
  brandsListSelector,
  currentRoleSelector,
  (dictionary, tenant, brands, role) =>
    dictionary?.sdTypeByTenant
      ?.filter(sdType => sdType?.tenantId === tenant?.id)
      ?.map(sdType => ({
        name: sdType.sdType,
        brand: brands.some(brand => brand.brandCode === tenant.defaultBrandCode)
          ? brands.find(brand => brand.brandCode === tenant.defaultBrandCode)
          : brands.some(brand => brand.brandCode === role?.brandCode)
          ? brands.find(brand => brand.brandCode === role?.brandCode)
          : brands[0],
      }))
);

export const etlFieldsSelector = createSelector(
  dictionaryStateSelector,
  state => state.etlFields
);

export const dataBaseRegionsSelector = createSelector(
  dictionaryStateSelector,
  state => state.dataBaseRegions
);

export const dataBaseRegionsWithoutDuplicatesSelector = createSelector(
  dataBaseRegionsSelector,
  dataBaseRegions =>
    UtilsHelper.removeElementsWithDuplicatedKeyFromArray(
      'databaseregion',
      dataBaseRegions
    )
);

export const gettingDictionarySelector = createSelector(
  dictionaryStateSelector,
  state => state.gettingDictionary
);

export const socialIdentityProvidersSelector = createSelector(
  dictionaryStateSelector,
  state => state.socialIdentityProviders
);

export const helpSectionsSelector = createSelector(
  dictionaryStateSelector,
  state =>
    !!state.helpSections && state.helpSections.length > 0
      ? state.helpSections
      : null
);

export const helpVideosSelector = createSelector(
  dictionaryStateSelector,
  state =>
    !!state.helpVideos && state.helpVideos.length > 0 ? state.helpVideos : null
);

export const dicTosSelector = createSelector(
  dictionaryStateSelector,
  state => state.tos[0]
);

export const dicPrivacyPoliciesSelector = createSelector(
  dictionaryStateSelector,
  state => state.privacyPolicies[0]
);

export const dicSdRolesSelector = createSelector(
  dictionaryStateSelector,
  state => state.sdRoles
);

export const dicCustomMessagesSelector = createSelector(
  dictionaryStateSelector,
  state => state.customMessages
);

export const dicCustomMessageByMessageNameSelector = (payload: {
  name: string;
}) =>
  createSelector(dicCustomMessagesSelector, messages =>
    messages.find(message => message.name === payload.name)
  );

export const dicSdRolesByEntitySelector = (payload: {
  entity: 'sidedrawer' | 'record' | 'tile';
}) =>
  createSelector(
    dicSdRolesSelector,
    sdRoles => sdRoles?.filter(sdRole => sdRole.entity === payload.entity)
  );

export const dicIntegrationProvidersSelector = createSelector(
  dictionaryStateSelector,
  state => state.integrationProviders
);

export const dicIntegrationProvidersByProviderTypeSelector = (payload: {
  providerType: ProviderTypeEnum;
}) =>
  createSelector(
    dicIntegrationProvidersSelector,
    dicIntegrations =>
      dicIntegrations?.filter(
        dicIntegration => dicIntegration?.providerType === payload.providerType
      )
  );
