import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alert-button',
  templateUrl: './alert-button.component.html',
  styleUrls: ['./alert-button.component.scss'],
})
export class AlertButtonComponent implements OnInit {
  @Input() loading: boolean;
  @Input() icon: string;
  @Input() tooltip: string;
  @Input() disabled: boolean;
  @Output() buttonClicked = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    if (!this.disabled) {
      this.buttonClicked.emit(true);
    }
  }
}
