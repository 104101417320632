import { Action } from '@ngrx/store';
import { BulkUploadQueue } from '../models/bulk-upload-queue.model';
import { BulkUploadStatus } from '../models/bulk-upload-status.model';
import { RecordType } from '../../record-types/models/record-type.model';
import { BulkUploadItem } from '../models/bulk-upload-item.model';
import { SubscriptionFeatures } from '../../subscriptions/models/subscription-features.model';

export enum BulkUploadActionsTypes {
  BulkUploadStatusChange = '[] Bulk Upload Status Change',
  BulkUploadQueueAddItem = '[] Bulk Upload Queue Add Item',
  BulkUploadQueueUpdateItem = '[] Bulk Upload Queue Update Item',
  BulkUploadQueueDeleteItem = '[] Bulk Upload Queue Delete Item',
  BulkUploadRecordTypesLoaded = '[] Bulk Upload Record Types Loaded',
  BulkUploadItemAdd = '[] Bulk Upload Item Add',
  BulkUploadItemDelete = '[] Bulk Upload Item Delete',
  BulkUploadErrorAddItem = '[] Bulk Upload Error Add Item',
  BulkUploadSubscriptionFeaturesRequested = '[] Bulk Upload Subscription Features Requested',
  BulkUploadSubscriptionFeaturesLoaded = '[] Bulk Upload Subscription Features Loaded',
  BulkUploadClearInformation = '[] Bulk Upload Clear Information',
  BulkUploadCancelUpload = '[] Bulk Upload Cancel Upload',
  BulkUploadCancelUploadSuccess = '[] Bulk Upload Cancel Upload Success',
  BulkUploadInitBulkUpload = '[] Bulk Upload Init Bulk Upload',
}

export class BulkUploadInitBulkUpload implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadInitBulkUpload;
  constructor(public payload: { sidedrawerId: string }) {}
}

export class BulkUploadCancelUploadSuccess implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadCancelUploadSuccess;
}
export class BulkUploadCancelUpload implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadCancelUpload;
}
export class BulkUploadStatusChange implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadStatusChange;

  constructor(public payload: { status: BulkUploadStatus }) {}
}

export class BulkUploadQueueAddItem implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadQueueAddItem;

  constructor(public payload: { item: BulkUploadQueue }) {}
}

export class BulkUploadQueueUpdateItem implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadQueueUpdateItem;

  constructor(public payload: { item: BulkUploadQueue }) {}
}

export class BulkUploadQueueDeleteItem implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadQueueDeleteItem;

  constructor(public payload: { id: string }) {}
}

export class BulkUploadRecordTypesLoaded implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadRecordTypesLoaded;

  constructor(public payload: { recordTypes: RecordType[] }) {}
}

export class BulkUploadItemAdd implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadItemAdd;

  constructor(public payload: { item: BulkUploadItem }) {}
}

export class BulkUploadItemDelete implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadItemDelete;

  constructor(public payload: { id: string }) {}
}

export class BulkUploadErrorAddItem implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadErrorAddItem;

  constructor(public payload: { error: string }) {}
}

export class BulkUploadSubscriptionFeaturesRequested implements Action {
  readonly type =
    BulkUploadActionsTypes.BulkUploadSubscriptionFeaturesRequested;

  constructor(public payload: { sidedrawerId: string }) {}
}

export class BulkUploadSubscriptionFeaturesLoaded implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadSubscriptionFeaturesLoaded;

  constructor(public payload: { features: SubscriptionFeatures }) {}
}

export class BulkUploadClearInformation implements Action {
  readonly type = BulkUploadActionsTypes.BulkUploadClearInformation;
}

export type BulkUploadActions =
  | BulkUploadStatusChange
  | BulkUploadQueueAddItem
  | BulkUploadQueueUpdateItem
  | BulkUploadQueueDeleteItem
  | BulkUploadRecordTypesLoaded
  | BulkUploadItemAdd
  | BulkUploadItemDelete
  | BulkUploadErrorAddItem
  | BulkUploadSubscriptionFeaturesRequested
  | BulkUploadSubscriptionFeaturesLoaded
  | BulkUploadClearInformation
  | BulkUploadCancelUpload
  | BulkUploadCancelUploadSuccess
  | BulkUploadInitBulkUpload;
