import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-sd-selectable-item',
  templateUrl: './sd-selectable-item.component.html',
  styleUrls: ['./sd-selectable-item.component.scss'],
})
export class SdSelectableItemComponent implements OnInit {
  @Input() value: string;
  @Input() description: string;
  @Input() selected: boolean;
  @Input() disabled: boolean;
  @Output() buttonClicked = new EventEmitter<boolean>();
  checkIcon = environment.cdn + 'svgs/check-solid.svg';

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    if (this.disabled) {
      return;
    }
    this.buttonClicked.emit(true);
  }
}
