import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { SideDrawer } from 'src/app/sidedrawers/models/side-drawer.model';
import { CreateTemplateDto } from '../models/create-template.dto';

export enum TemplatesActionsTypes {
  TemplatesLoaded = '[Template service] Templates Loaded',
  TemplatesRequested = '[Template List effect] Templates Requested',
  TemplateAdded = '[New Template Dialog] New Template Added',
  TemplateUpdated = '[Template Table / Template details view] Template Updated',
  TemplateDeleted = '[Template Table / Template details view] Template Deleted',
  TemplatesClearInformation = '[Switch Tenant] Clear all Template List information',
  GettingTemplatesChange = '[Template List effect] Getting Templates Change',
  TemplatesCreateRequest = '[Template List effect] Templates Create Request',
  TemplatesCreateSuccess = '[Template service] Templates Create Success',
}

export class TemplatesCreateRequest implements Action {
  readonly type = TemplatesActionsTypes.TemplatesCreateRequest;

  constructor(public payload: { template: CreateTemplateDto }) {}
}

export class TemplatesCreateSuccess implements Action {
  readonly type = TemplatesActionsTypes.TemplatesCreateSuccess;

  constructor(public payload: { templateId: string }) {}
}
export class TemplatesLoaded implements Action {
  readonly type = TemplatesActionsTypes.TemplatesLoaded;

  constructor(public payload: { templates: SideDrawer[] }) {}
}

export class TemplatesRequested implements Action {
  readonly type = TemplatesActionsTypes.TemplatesRequested;
}

export class TemplateAdded implements Action {
  readonly type = TemplatesActionsTypes.TemplateAdded;

  constructor(public payload: { template: SideDrawer }) {}
}

export class TemplateUpdated implements Action {
  readonly type = TemplatesActionsTypes.TemplateUpdated;

  constructor(public payload: { template: Update<SideDrawer> }) {}
}

export class TemplateDeleted implements Action {
  readonly type = TemplatesActionsTypes.TemplateDeleted;

  constructor(public payload: { id: string }) {}
}

export class TemplatesClearInformation implements Action {
  readonly type = TemplatesActionsTypes.TemplatesClearInformation;
}

export class GettingTemplatesChange implements Action {
  readonly type = TemplatesActionsTypes.GettingTemplatesChange;

  constructor(public payload: { state: boolean }) {}
}

export type TemplatesActions =
  | TemplatesLoaded
  | TemplatesRequested
  | TemplateAdded
  | TemplateUpdated
  | TemplateDeleted
  | GettingTemplatesChange
  | TemplatesClearInformation
  | TemplatesCreateRequest
  | TemplatesCreateSuccess;
