<div *ngIf="loading" class="spinner">
  <mat-progress-spinner
    diameter="20"
    mode="indeterminate"></mat-progress-spinner>
</div>

<button
  (click)="onClick()"
  *ngIf="!loading"
  [disabled]="disabled"
  [matTooltip]="!!tooltip ? tooltip : ''"
  [ngClass]="primary ? 'primary' : 'secondary'"
  [ngStyle]="{ 'background-color': bgColor }"
  mat-flat-button
  #matTooltip="matTooltip"
  (mouseleave)="!!tooltip ? matTooltip.hide() : ''">
  <div
    *ngIf="!!icon"
    [ngClass]="primary ? 'icon  icon-primary' : 'icon icon-secondary'"
    [ngStyle]="{
      '-webkit-mask': 'url(' + icon + ') no-repeat 50% 50%',
      mask: 'url(' + icon + ') no-repeat 50% 50%'
    }"></div>
  <span class="text">
    <ng-content></ng-content>
  </span>
</button>
