import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InactivityTimeDialogComponent } from './components/inactivity-time-dialog/inactivity-time-dialog.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdButtonsModule } from '../../../shared/sd-buttons/sd-buttons.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [InactivityTimeDialogComponent],
  exports: [InactivityTimeDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    DictionaryPipeModule,
    SdButtonsModule,
    MatDialogModule,
  ],
})
export class InactivityTimeDialogModule {}
