import { Action } from '@ngrx/store';
import { SideDrawerType } from '../models/side-drawer-type.model';
import { Update } from '@ngrx/entity';

export enum SideDrawerTypesActionsTypes {
  SideDrawerTypesLoaded = '[SideDrawerType service] SideDrawerTypes Loaded',
  SideDrawerTypesRequested = '[SideDrawerType service] SideDrawerTypes Requested',
  SideDrawerTypeAdded = '[New SideDrawerType Dialog] New SideDrawerType Added',
  SideDrawerTypeUpdated = '[SideDrawerType Table / SideDrawerType details view] SideDrawerType Updated',
  SideDrawerTypeDeleted = '[SideDrawerType Table / SideDrawerType details view] SideDrawerType Deleted',
  SideDrawerTypesClearInformation = '[Switch Tenant] Clear all SideDrawerType information',
}

export class SideDrawerTypesRequested implements Action {
  readonly type = SideDrawerTypesActionsTypes.SideDrawerTypesRequested;
}

export class SideDrawerTypesLoaded implements Action {
  readonly type = SideDrawerTypesActionsTypes.SideDrawerTypesLoaded;

  constructor(public payload: { types: SideDrawerType[] }) {}
}

export class SideDrawerTypeAdded implements Action {
  readonly type = SideDrawerTypesActionsTypes.SideDrawerTypeAdded;

  constructor(public payload: { type: SideDrawerType }) {}
}

export class SideDrawerTypeUpdated implements Action {
  readonly type = SideDrawerTypesActionsTypes.SideDrawerTypeUpdated;

  constructor(public payload: { type: Update<SideDrawerType> }) {}
}

export class SideDrawerTypeDeleted implements Action {
  readonly type = SideDrawerTypesActionsTypes.SideDrawerTypeDeleted;

  constructor(public payload: { id: number }) {}
}

export class SideDrawerTypesClearInformation implements Action {
  readonly type = SideDrawerTypesActionsTypes.SideDrawerTypesClearInformation;
}

export type SideDrawerTypesActions =
  | SideDrawerTypesLoaded
  | SideDrawerTypesRequested
  | SideDrawerTypeAdded
  | SideDrawerTypeUpdated
  | SideDrawerTypeDeleted
  | SideDrawerTypesClearInformation;
