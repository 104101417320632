import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { AccountService } from '../services/account.service';
import {
  AccountActionsTypes,
  AccountAndSettingsRequested,
  AccountLoaded,
  GettingAccountChange,
  GettingAccountOrSettingsFail,
  GettingSettingsChange,
} from './account.actions';
import { EMPTY, of, switchMap, tap } from 'rxjs';
import { authUserSelector } from '../../auth/store/auth.selectors';
import { catchError, map, take } from 'rxjs/operators';
import { AuthUser } from '../../auth/models/user.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class AccountEffects {
  accountAndSettingsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AccountAndSettingsRequested>(
        AccountActionsTypes.AccountAndSettingsRequested
      ),
      tap(() => this.store.dispatch(new GettingAccountChange({ state: true }))),
      switchMap(() =>
        this.store.pipe(
          select(authUserSelector),
          take(1),
          map((user: AuthUser) => user?.sub)
        )
      ),
      switchMap(openId =>
        this.accountService.getAccountByOpenId(openId).pipe(
          catchError(() => of(null)),
          tap(() =>
            this.store.dispatch(new GettingAccountChange({ state: false }))
          ),
          map(account => account?.id ?? null)
        )
      ),
      tap(() =>
        this.store.dispatch(new GettingSettingsChange({ state: true }))
      ),
      switchMap(accountId =>
        accountId
          ? this.accountService.getAccountSettings(accountId).pipe(
              map(() => new GettingSettingsChange({ state: false })),
              catchError(() => of(new GettingAccountOrSettingsFail()))
            )
          : of(new GettingAccountOrSettingsFail())
      ),
      tap(() =>
        this.store.dispatch(new GettingSettingsChange({ state: false }))
      )
    )
  );

  accountLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AccountLoaded>(AccountActionsTypes.AccountLoaded),
        switchMap(action => {
          if (window.Intercom) {
            return this.accountService
              .getIntercomHash(action.payload.data.emails[0].address)
              .pipe(
                tap({
                  next: ({ hash }) => {
                    window.Intercom('update', {
                      app_id: environment.intercomAppId,
                      email:
                        action.payload.data.emails?.[0]?.address ??
                        action.payload.data.username,
                      name: `${action.payload.data.firstName} ${action.payload.data.lastName}`,
                      user_hash: hash,
                    });
                  },
                  error: () => {
                    window.Intercom('update', {
                      app_id: environment.intercomAppId,
                      email:
                        action.payload.data.emails?.[0]?.address ??
                        action.payload.data.username,
                      name: `${action.payload.data.firstName} ${action.payload.data.lastName}`,
                    });
                  },
                })
              );
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly accountService: AccountService
  ) {}
}
