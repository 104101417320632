import { createSelector } from '@ngrx/store';
import { selectAll } from './brand-list.reducer';
import { brandStateSelector } from './brand.selectors';

export const brandListStateSelector = createSelector(
  brandStateSelector,
  state => state.brandList
);

export const brandsListSelector = createSelector(
  brandListStateSelector,
  selectAll
);

export const brandByBrandCodeSelector = (payload: { brandCode: string }) =>
  createSelector(brandsListSelector, brands =>
    brands.find(brand => brand.brandCode === payload.brandCode)
  );

export const brandByCoBrandIdSelector = (payload: { coBrandId: string }) =>
  createSelector(brandsListSelector, brands =>
    brands.find(brand => brand.id === payload.coBrandId)
  );
