export class Locale {
  constructor(
    public localeid: string,
    public localename: string,
    public languagename: string,
    public countryname: string,
    public languagecode: string,
    public countrycode: string,
    public languagedefault: boolean,
    public localedefault: boolean,
    public published: boolean
  ) {}

  static getLocaleId(locale: Locale): string {
    return locale?.localeid?.replace(
      locale?.countrycode.toLowerCase(),
      locale?.countrycode
    );
  }

  static getBrowserLocale(locales: Locale[]): Locale {
    const browserLang = window.navigator.language.slice(0, 2);
    const eng = locales.find(lang => lang.languagecode === 'en');
    const myLang = locales.find(lang => lang.languagecode === browserLang);
    return myLang ?? eng;
  }
}
