import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { dictionarySelector } from '../../store/dictionary.selectors';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'dictionary',
})
export class DictionaryPipe implements PipeTransform {
  constructor(private readonly store: Store<AppState>) {}

  transform(key: string): Observable<string> {
    return this.store.pipe(
      select(dictionarySelector),
      map(dictionary => dictionary[key] ?? key)
    );
  }
}
