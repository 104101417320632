import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { TemplatesService } from '../services/templates.service';
import {
  GettingTemplatesChange,
  TemplatesActionsTypes,
  TemplatesCreateRequest,
  TemplatesCreateSuccess,
  TemplatesLoaded,
  TemplatesRequested,
} from './templates.actions';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { EMPTY, forkJoin, of } from 'rxjs';
import { currentTenantSelector } from 'src/app/tenants/store/tenant.selectors';
import { GenericErrorLoad } from 'src/app/core/store/core.actions';

@Injectable()
export class TemplatesEffects {
  templatesRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TemplatesRequested>(TemplatesActionsTypes.TemplatesRequested),
      tap(() =>
        this.store.dispatch(new GettingTemplatesChange({ state: true }))
      ),
      mergeMap(() =>
        this.templatesService.getTemplatesByBrandCode().pipe(
          map(templates => new TemplatesLoaded({ templates })),
          tap(() =>
            this.store.dispatch(new GettingTemplatesChange({ state: false }))
          )
        )
      )
    )
  );

  templateCreateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TemplatesCreateRequest>(
        TemplatesActionsTypes.TemplatesCreateRequest
      ),
      mergeMap(action =>
        forkJoin([
          of(action).pipe(take(1)),
          this.store.select(currentTenantSelector).pipe(take(1)),
        ])
      ),
      mergeMap(([action, tenant]) =>
        this.templatesService
          .createTemplate(tenant.id, action.payload.template)
          .pipe(
            map(template => {
              return new TemplatesCreateSuccess({ templateId: template.id });
            }),
            catchError(error => {
              this.store.dispatch(
                new GenericErrorLoad({
                  error: {
                    httpError: error,
                    action: action.type,
                    display404: false,
                    display403: true,
                  },
                })
              );
              return EMPTY;
            })
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly templatesService: TemplatesService
  ) {}
}
