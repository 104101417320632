import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BulkUploadState, featureName } from './bulk-upload.reducer';
import { BulkUploadQueueStatus } from '../models/bulk-upload-queue-status.model';

export const bulkUploadStateSelector =
  createFeatureSelector<BulkUploadState>(featureName);

export const bulkUploadStatusSelector = createSelector(
  bulkUploadStateSelector,
  state => state.status
);

export const bulkUploadQueueSelector = createSelector(
  bulkUploadStateSelector,
  state => state.queue
);

export const bulkUploadQueueCompletedItemsSelector = createSelector(
  bulkUploadQueueSelector,
  queue =>
    queue.filter(
      item =>
        item.status === BulkUploadQueueStatus.complete ||
        item.status === BulkUploadQueueStatus.fail
    )?.length
);

export const bulkUploadQueueTotalItemsSelector = createSelector(
  bulkUploadQueueSelector,
  queue => queue.length
);

export const bulkUploadQueueProgressSelector = createSelector(
  bulkUploadQueueSelector,
  queue => {
    const totalItems = queue.length;
    if (totalItems === 0) {
      return 0;
    }
    const completedItems = queue.filter(
      item =>
        item.status === BulkUploadQueueStatus.complete ||
        item.status === BulkUploadQueueStatus.fail
    ).length;
    const currentFileProgress = queue.find(
      item => item.status === BulkUploadQueueStatus.progress
    )?.uploadProgress
      ? queue.find(item => item.status === BulkUploadQueueStatus.progress)
          ?.uploadProgress
      : 0;
    return Math.round(
      ((completedItems * 100 + currentFileProgress) / (totalItems * 100)) * 100
    );
  }
);

export const bulkUploadErrorsSelector = createSelector(
  bulkUploadStateSelector,
  state => state.errors
);

export const bulkUploadItemsSelector = createSelector(
  bulkUploadStateSelector,
  state => state.items
);

export const bulkUploadItemSelectorById = (id: string) =>
  createSelector(bulkUploadItemsSelector, items =>
    items.find(item => item.id === id)
  );

export const bulkUploadRecordTypesSelector = createSelector(
  bulkUploadStateSelector,
  state => state.recordTypes
);

export const bulkUploadSubscriptionFeaturesSelector = createSelector(
  bulkUploadStateSelector,
  state => state.subscriptionFeatures
);

export const bulkUploadMaxUploadSelector = createSelector(
  bulkUploadSubscriptionFeaturesSelector,
  features =>
    !!features && !!features['sidedrawer.maxUploadMBs']
      ? parseInt(features['sidedrawer.maxUploadMBs'], 10)
      : 0
);
