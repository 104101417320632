import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sd-svg-button',
  templateUrl: './sd-svg-button.component.html',
  styleUrls: ['./sd-svg-button.component.scss'],
})
export class SdSvgButtonComponent implements OnInit {
  @Input() src: string;
  @Input() color: string;
  @Input() backgroundColor = 'var(--appBackground)';
  @Input() width: number;
  @Input() height: number;
  @Input() disabled: boolean;
  @Input() tooltip: string;
  @Input() tabIndex = 0;
  @Output() buttonClicked = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
}
