import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { exhaustMap, map, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import { sdCacheElementSelector } from '../../../sd-cache/store/sd-cache.selectors';
import { of } from 'rxjs';
import { SdCacheElementAdded } from '../../../sd-cache/store/sd-cache.actions';

@Component({
  selector: 'app-sd-svg',
  template: `
    <div
      *ngIf="!!imageSrc()"
      [ngStyle]="{
        position: 'relative',
        height: !!height ? height + 'px' : 'auto',
        width: !!width ? width + 'px' : 'auto',
        overflown: 'hidden'
      }"
      class="sd-svg">
      <div
        *ngIf="!!color && !!imageSrc()"
        [ngStyle]="{
          opacity: invisible ? 0 : 1,
          position: 'absolute',
          top: 0,
          left: 0,
          'background-color': color,
          height: !!height ? height + 'px' : 'auto',
          width: !!width ? width + 'px' : 'auto',
          '-webkit-mask': 'url(' + imageSrc() + ') no-repeat 50% 50%',
          mask: 'url(' + imageSrc() + ') no-repeat 50% 50%'
        }"
        class="icon"></div>
    </div>
  `,
  styleUrls: ['./sd-svg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdSvgComponent {
  @Input() set src(src: string) {
    const srcQuery = src + DateHelper.getTodayQuery();
    this.store
      .pipe(
        select(sdCacheElementSelector({ key: srcQuery })),
        take(1),
        exhaustMap(windowUrl => {
          if (windowUrl) {
            return of(windowUrl);
          }
          return this.http
            .get(srcQuery, {
              responseType: 'blob' as 'json',
            })
            .pipe(
              map((file: Blob | MediaSource) =>
                window.URL.createObjectURL(file)
              ),
              tap(windowUrl => {
                this.store.dispatch(
                  new SdCacheElementAdded({
                    key: srcQuery,
                    value: windowUrl,
                  })
                );
              })
            );
        }),
        tap(windowUrl => {
          this.imageSrc.set(windowUrl);
        })
      )
      .subscribe();
  }
  @Input() color: string;
  @Input() width: number;
  @Input() height: number;
  @Input() invisible: boolean;
  // Badge inputs for svg icons.
  @Input() badge: number;
  @Input() showBadge: boolean;
  @Input() bgOnly: boolean;
  @Input() badgeSize: 'small' | 'medium' | 'large' = 'small';
  imageSrc = signal(null);

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}
}
