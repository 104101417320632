import { SdCacheActions, SdCacheActionsTypes } from './sd-cache.actions';

export const featureName = 'sd-cache';

export interface SdCacheState {
  cache: Map<string, string>;
}

export const initialSdCacheState: SdCacheState = {
  cache: new Map<string, string>(),
};

export function sdCacheReducer(
  state: SdCacheState = initialSdCacheState,
  action: SdCacheActions
): SdCacheState {
  switch (action.type) {
    case SdCacheActionsTypes.SdCacheElementAdded:
      const aux = state.cache;
      const { key, value } = action.payload;
      aux.set(key, value);
      return {
        ...state,
        cache: aux,
      };
    default:
      return state;
  }
}
