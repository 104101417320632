<mat-form-field
  [hideRequiredMarker]="true"
  [ngStyle]="{ 'margin-bottom': controller.invalid ? '10px' : 0 }">
  <input
    [formControl]="controller"
    [matAutocomplete]="auto"
    [placeholder]="placeholder"
    aria-label="Number"
    matInput
    type="text" />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      (click)="onSelectCountry(option)"
      *ngFor="let option of filteredOptions | async"
      [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="controller.invalid">
    <span>{{ error }}</span>
  </mat-error>
</mat-form-field>
