// @ts-ignore
const countriesJson = require('../../../../assets/json/countries.json');

export class Country {
  constructor(
    public countryCode: string,
    public countryName: string,
    public dialCode: string
  ) {}

  static getAllCountries(): Country[] {
    return countriesJson.countryCodes.map(
      countryInfo =>
        new Country(
          countryInfo.country_code,
          countryInfo.country_name,
          countryInfo.dialling_code
        )
    );
  }

  static getCountriesExtensionsWithoutDuplicates(): string[] {
    const extensions = [];
    countriesJson.countryCodes.forEach(countryInfo => {
      if (
        !extensions.find(extension => extension === countryInfo.dialling_code)
      ) {
        extensions.push(countryInfo.dialling_code);
      }
    });
    return extensions;
  }

  static getCountryByCountryName(countryName: string): Country {
    return this.getAllCountries().find(
      country => country.countryName === countryName
    );
  }

  static getCountryByCountryCode(countryCode: string): Country {
    return this.getAllCountries().find(
      country => country.countryCode === countryCode
    );
  }

  static getCountryByDialCode(dialCode: string): Country {
    return this.getAllCountries().find(
      country => country.dialCode === dialCode
    );
  }
}
