import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthRoutes } from '../../auth/routes/auth.routes';
import { CoreRoutes } from '../../core/routes/core.routes';
import { AuthGuard } from '../../auth/guards/auth.guard';
import { tenantRolesResolver } from '../../core/resolvers/tenant-roles.resolver';

const routes: Routes = [
  {
    path: AuthRoutes.root,
    loadChildren: () =>
      import('./keycloak-auth/keycloak-auth.module').then(
        m => m.KeycloakAuthModule
      ),
    resolve: {
      tenantRoles: tenantRolesResolver,
    },
  },
  {
    path: CoreRoutes.root,
    loadChildren: () =>
      import('./core/td-core.module').then(m => m.TdCoreModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'auth/authorize',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class TdAppRoutingModule {}
