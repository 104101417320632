import { Action } from '@ngrx/store';
import { MyOtherSideDrawer } from '../models/my-other-side-drawer.model';

export enum SidedrawersNetworkActionsTypes {
  SidedrawersNetworkLoaded = '[Network Effects] Sidedrawers Network Loaded',
  SidedrawersNetworkClearInformation = '[Network Effects] Sidedrawers Network Clear all information',
  GettingSidedrawersNetworksChange = '[Network Effects] Getting Sidedrawers Networks Change',
  ForcingSidedrawersNetworkUser = '[Network Service] Forcing Sidedrawers Network User',
  SidedrawerNetworkRequested = '[] Sidedrawer Network Requested',
  MultipleSidedrawersNetworkRequested = '[] Multiple Sidedrawers Network Requested',
  SidedrawerNetworkLoaded = '[Network Effects] Sidedrawer Network Loaded',
  SidedrawerNetworkRemove = '[Network Effects] Remove Sidedrawer Network',
}

export class SidedrawersNetworkLoaded implements Action {
  readonly type = SidedrawersNetworkActionsTypes.SidedrawersNetworkLoaded;

  constructor(public payload: { networks: MyOtherSideDrawer[] }) {}
}

export class SidedrawersNetworkClearInformation implements Action {
  readonly type =
    SidedrawersNetworkActionsTypes.SidedrawersNetworkClearInformation;
}

export class GettingSidedrawersNetworksChange implements Action {
  readonly type =
    SidedrawersNetworkActionsTypes.GettingSidedrawersNetworksChange;

  constructor(public payload: { state: boolean }) {}
}

export class ForcingSidedrawersNetworkUser implements Action {
  readonly type = SidedrawersNetworkActionsTypes.ForcingSidedrawersNetworkUser;

  constructor(public payload: { state: boolean }) {}
}

export class SidedrawerNetworkRequested implements Action {
  readonly type = SidedrawersNetworkActionsTypes.SidedrawerNetworkRequested;

  constructor(public payload: { sidedrawerId: string }) {}
}

export class MultipleSidedrawersNetworkRequested implements Action {
  readonly type =
    SidedrawersNetworkActionsTypes.MultipleSidedrawersNetworkRequested;

  constructor(public payload: { sidedrawerIds: string[] }) {}
}

export class SidedrawerNetworkLoaded implements Action {
  readonly type = SidedrawersNetworkActionsTypes.SidedrawerNetworkLoaded;

  constructor(public payload: { network: MyOtherSideDrawer }) {}
}

export class SidedrawerNetworkRemove implements Action {
  readonly type = SidedrawersNetworkActionsTypes.SidedrawerNetworkRemove;

  constructor(public payload: { id: string }) {}
}

export type SidedrawersNetworkActions =
  | SidedrawersNetworkLoaded
  | SidedrawersNetworkClearInformation
  | GettingSidedrawersNetworksChange
  | ForcingSidedrawersNetworkUser
  | SidedrawerNetworkRequested
  | MultipleSidedrawersNetworkRequested
  | SidedrawerNetworkLoaded
  | SidedrawerNetworkRemove;
