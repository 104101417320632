<ng-template
  #default
  [ngIf]="template === sdLocaleSelectorA11yTemplates.default">
  <mat-form-field [hideRequiredMarker]="true">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-select [formControl]="controller">
      <mat-option *ngFor="let locale of options" [value]="locale.localeid">
        {{ locale.localename }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="controller.invalid">
      <span>{{ error }}</span>
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template #menu [ngIf]="template === sdLocaleSelectorA11yTemplates.menu">
  <button
    [matMenuTriggerFor]="menuAllColumns"
    [matTooltip]="tooltip"
    class="sd-icon-button header-manage-sidedrawer-button"
    mat-button>
    <div
      [ngStyle]="{
        '-webkit-mask': 'url(' + icon + ') no-repeat 50% 50%',
        mask: 'url(' + icon + ') no-repeat 50% 50%',
        'background-color': 'var(--tertiaryAccentColor)'
      }"
      class="sd-icon-button-icon"></div>
  </button>

  <mat-menu #menuAllColumns="matMenu" class="sd-table-menu-all-columns">
    <div class="sd-table-actions">
      <div>
        <button
          [disabled]="optionSelectedSignal() === option.localeid"
          mat-menu-item
          *ngFor="let option of options; let i = index"
          (click)="onClick(option.localeid)">
          {{ option.localename }}
        </button>
      </div>
    </div>
  </mat-menu>
</ng-template>
