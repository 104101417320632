import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../auth/services/auth.service';
import { Observable, switchMap, throwError, of } from 'rxjs';
import { Branding } from '../models/branding.model';
import { catchError, map, take } from 'rxjs/operators';
import { BrandsLoaded } from '../store/brand-list.actions';
import { BrandConfig } from '../models/brand-config.model';
import { BrandCrudDtoResponse } from '../models/branding-crud-dto-response';
import { BrandCreateDtoResponse } from '../models/branding-create-dto-response';
import { BrandEditDtoResponse } from '../models/branding-update-dto-response';
import { BrandFile } from '../models/branding-files.model';
import { SampleData } from '../models/sample-data.model';
import { select, Store } from '@ngrx/store';
import { dictionarySelector } from '../../dictionary/store/dictionary.selectors';
import { AppState } from '../../reducers';

@Injectable()
export class BrandingService {
  private tenantApi = environment.tenantApi;

  constructor(
    private readonly store: Store<AppState>,
    private readonly http: HttpClient,
    private readonly authService: AuthService
  ) {}

  getBrands(tenantId: string): Observable<BrandsLoaded> {
    return this.http
      .get<Branding[]>(
        this.tenantApi + `tenant/tenant-id/${tenantId}/branding`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        map(brands => {
          return new BrandsLoaded({ brands });
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  createBrand(tenantId: string, body: any): Observable<Branding> {
    return this.http
      .post<BrandCreateDtoResponse>(
        this.tenantApi + `tenant/tenant-id/${tenantId}/branding`,
        body,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        map((brand: BrandCreateDtoResponse) => {
          const branding: Branding = {
            active: brand.active,
            brandCode: brand.brandCode,
            brandName: brand.brandName,
            createdAt: brand.createdAt,
            default: brand.default,
            hideDefaultRecordTypes: brand.hideDefaultRecordTypes,
            id: brand._id,
            multiLanguage: brand.multiLanguage,
            // tslint:disable-next-line: no-string-literal
            tenant: brand.tenant?.id || brand.tenant?.['_id'],
            updatedAt: brand.updatedAt,
            skipSponsorEditorAssignment: brand.skipSponsorEditorAssignment,
          };
          return branding;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  updateBrand(
    tenantId: string,
    id: string,
    body: Branding
  ): Observable<Branding> {
    return this.http
      .put<BrandCrudDtoResponse>(
        this.tenantApi + `tenant/tenant-id/${tenantId}/branding/brand-id/${id}`,
        body,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        map((brand: BrandEditDtoResponse) => {
          const branding: Branding = {
            active: brand.active,
            brandCode: brand.brandCode,
            brandName: body.brandName,
            createdAt: brand.createdAt,
            default: brand.default,
            hideDefaultRecordTypes: brand.hideDefaultRecordTypes,
            id: brand._id,
            multiLanguage: brand.multiLanguage,
            tenant: brand.tenant,
            updatedAt: brand.updatedAt,
            skipSponsorEditorAssignment: brand.skipSponsorEditorAssignment,
          };
          return branding;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  deleteBrand(tenantId: string, id: string): Observable<BrandCrudDtoResponse> {
    return this.http.delete<BrandCrudDtoResponse>(
      this.tenantApi + `tenant/tenant-id/${tenantId}/branding/brand-id/${id}`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getBrandByBrandCode(brandCode): Observable<BrandConfig> {
    return this.http.get<BrandConfig>(
      this.tenantApi + `tenant/branding/brand-code/${brandCode}`
    );
  }

  getBrandFlavors(
    tenantId: string,
    brandId: string
  ): Observable<BrandConfig[]> {
    return this.http.get<BrandConfig[]>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/branding/brand-id/${brandId}/flavors`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getBrandFlavor(
    tenantId: string,
    brandId: string,
    flavorId: string
  ): Observable<BrandConfig[]> {
    return this.http.get<BrandConfig[]>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/branding/brand-id/${brandId}/flavors/flavor-id/${flavorId}`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  updateBrandFlavor(
    tenantId: string,
    brandId: string,
    flavorId: string,
    dto: BrandConfig
  ): Observable<BrandConfig> {
    return this.http.put<BrandConfig>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/branding/brand-id/${brandId}/flavors/flavor-id/${flavorId}`,
      dto,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getAllFilesByTenantId(tenantId: string): Observable<BrandFile[]> {
    return this.http.get<BrandFile[]>(
      this.tenantApi + `tenant/tenant-id/${tenantId}/files`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getSampleData(): Observable<SampleData> {
    return this.store.pipe(
      select(dictionarySelector),
      take(1),
      map(
        dictionary =>
          `${environment.cdn}${dictionary.globalparams_affiliatesampledatasource}`
      ),
      switchMap(url => {
        return this.http.get<SampleData>(url);
      })
    );
  }
}
