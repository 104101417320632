<mat-form-field [hideRequiredMarker]="true">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select [formControl]="controller">
    <mat-option *ngFor="let locale of options" [value]="locale.localeid">
      {{ locale.localename }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="controller.invalid">
    <span>{{ error }}</span>
  </mat-error>
</mat-form-field>
