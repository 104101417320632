import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Country } from '../../models/country.model';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'app-country-extension-selector',
  templateUrl: './country-extension-selector.component.html',
  styleUrls: ['./country-extension-selector.component.scss'],
})
export class CountryExtensionSelectorComponent
  implements OnInit, AfterViewChecked
{
  @Input() placeholder: string;
  @Input() startValue: string;
  @Input() error: string;
  @Input() controller: UntypedFormControl;
  @Input() required: boolean;
  @Input() fullPhoneNumber: any;
  @Output() extensionSelected = new EventEmitter<string>();
  extensions = Country.getCountriesExtensionsWithoutDuplicates();
  filteredOptions: Observable<string[]>;

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.required) {
      this.controller.setValidators([
        Validators.required,
        this.countryValidator.bind(this),
      ]);
    } else {
      this.controller.setValidators(this.countryValidator.bind(this));
    }
    this.filteredOptions = this.controller.valueChanges.pipe(
      startWith(this.startValue),
      map(value => this.filter(value)),
      tap(extensions => {
        if (extensions.length === 1) {
          this.extensionSelected.emit(extensions[0]);
        } else {
          this.extensionSelected.emit(null);
        }
      })
    );
    this.controller.setValue(this.startValue);
  }

  filter(value: string): string[] {
    const filterValue = value ? value.toString().toLowerCase() : '';
    return this.extensions.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }

  countryValidator(control: UntypedFormControl): { [s: string]: boolean } {
    return !control.value
      ? null
      : this.extensions.find(
          extension => extension.toLowerCase() === control.value.toLowerCase()
        )
      ? null
      : { invalidCountry: true };
  }

  onSelectCountry(extension: string): void {
    if (!this.fullPhoneNumber.value) {
      this.controller.reset();
    }
    this.controller.setValue(extension);
    this.extensionSelected.emit(extension);
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }
}
