import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { DictionaryResponseDto } from '../models/dictionary-response.dto';
import { Locale } from '../models/locale.model';
import { catchError, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  BlockedFileFormatsLoaded,
  CloudIntegrationsLoaded,
  CurrenciesLoaded,
  CustomMessagesLoaded,
  DataBaseRegionsLoaded,
  DictionaryLoaded,
  DictionaryPricesLoaded,
  DictionarySdRolesLoaded,
  DictionarySideDrawerTypesLoaded,
  EtlFieldsLoaded,
  HelpSectionsLoaded,
  HelpVideosLoaded,
  ImageFileFormatsLoaded,
  IntegrationProvidersLoaded,
  JumpOffButtonsLoaded,
  LocalesLoaded,
  PlanItemFormTypesLoaded,
  PrivacyPoliciesLoaded,
  RelationshipsLoaded,
  RelationshipTypesLoaded,
  SdTypeByTenantLoaded,
  SocialIdentityProvidersLoaded,
  SubscriptionsStatusLoaded,
  TenantRolesLoaded,
  TosLoaded,
  VCardLinksLoaded,
  VideoFileFormatsLoaded,
} from '../store/dictionary.actions';

@Injectable()
export class DictionaryService {
  private configApi = environment.configApi;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}

  getLocales(): Observable<Locale[]> {
    return this.http
      .get<{ content: Locale[] }>(
        this.configApi + 'content/collections/locales/locale/en-CA'
      )
      .pipe(
        map(locales => {
          this.store.dispatch(
            new LocalesLoaded({
              data: environment.production
                ? locales?.content?.filter(locale => locale.published)
                : locales?.content,
            })
          );
          return locales?.content;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getDictionary(
    locale?: Locale,
    localeId?: string
  ): Observable<DictionaryResponseDto> {
    const dictionaryVersion = environment.dictionaryVersion;
    return this.http
      .get<DictionaryResponseDto>(
        this.configApi +
          `content/dictionaries/${dictionaryVersion}/locale/${
            localeId ?? Locale.getLocaleId(locale)
          }`
      )
      .pipe(
        tap(response => {
          if (response) {
            this.store.dispatch(
              new RelationshipTypesLoaded({
                data: response.collections.relationshiptypes,
              })
            );
            this.store.dispatch(
              new RelationshipsLoaded({
                data: response.collections.relationships,
              })
            );
            this.store.dispatch(
              new DictionaryPricesLoaded({ data: response.collections.prices })
            );
            this.store.dispatch(
              new DictionarySideDrawerTypesLoaded({
                data: response.collections.sdtypes,
              })
            );
            this.store.dispatch(
              new BlockedFileFormatsLoaded({
                data: response.collections.blockedfileformats,
              })
            );
            this.store.dispatch(
              new ImageFileFormatsLoaded({
                data: response.collections.imageformats,
              })
            );
            this.store.dispatch(
              new VideoFileFormatsLoaded({
                data: response.collections.videoformats,
              })
            );
            this.store.dispatch(
              new SubscriptionsStatusLoaded({
                data: response.collections.subscriptionstatuses,
              })
            );
            this.store.dispatch(
              new CurrenciesLoaded({ data: response.collections.currencies })
            );
            this.store.dispatch(
              new PlanItemFormTypesLoaded({
                data: response.collections.planitemformtype,
              })
            );
            this.store.dispatch(
              new TenantRolesLoaded({ data: response.collections.tenantroles })
            );
            this.store.dispatch(
              new VCardLinksLoaded({ data: response.collections.vcardlinks })
            );
            this.store.dispatch(
              new SdTypeByTenantLoaded({
                data: response.collections.sdtypebytenant,
              })
            );
            this.store.dispatch(
              new JumpOffButtonsLoaded({
                data: response.collections.consolejumpoffbuttons,
              })
            );
            this.store.dispatch(
              new EtlFieldsLoaded({ data: response.collections.etlfields })
            );
            this.store.dispatch(
              new DataBaseRegionsLoaded({
                data: response.collections.databaseregions,
              })
            );
            this.store.dispatch(
              new SocialIdentityProvidersLoaded({
                data: response.collections.socialidps,
              })
            );
            this.store.dispatch(
              new HelpSectionsLoaded({
                data: response.collections.helpsections,
              })
            );
            this.store.dispatch(
              new HelpVideosLoaded({ data: response.collections.helpvideos })
            );
            this.store.dispatch(
              new TosLoaded({ data: response.collections.tos })
            );
            this.store.dispatch(
              new PrivacyPoliciesLoaded({
                data: response.collections.privacypolicy,
              })
            );
            this.store.dispatch(
              new DictionarySdRolesLoaded({
                data: response.collections.sdroles,
              })
            );
            this.store.dispatch(
              new CloudIntegrationsLoaded({
                data: response.collections.clouddrives,
              })
            );
            this.store.dispatch(
              new CustomMessagesLoaded({
                data: response.collections.custommessages,
              })
            );
            this.store.dispatch(
              new IntegrationProvidersLoaded({
                data: response.collections.integrationproviders,
              })
            );
            this.store.dispatch(
              new DictionaryLoaded({
                data: response.content,
                completeDictionary: response,
              })
            );
          }
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
