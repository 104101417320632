import { environment } from '../../../environments/environment';
import { ConsoleQueryParams } from '../models/console-query.params';

export class RoutesHelper {
  public static getParams(withOrigin = true): ConsoleQueryParams {
    const queryParams = {};
    const urlParams = new URLSearchParams(window.location.search);
    const origin = urlParams.get('origin');
    const launch = urlParams.get('launch');
    const price = urlParams.get('price');
    // tslint:disable-next-line:variable-name
    const product_tour_id = urlParams.get('product_tour_id');
    if (!!origin && origin.length > 0 && withOrigin) {
      // tslint:disable-next-line
      queryParams['origin'] = origin;
    } else if (withOrigin) {
      // tslint:disable-next-line
      queryParams['origin'] = window.location.pathname;
    }
    if (!!product_tour_id && product_tour_id.length > 0) {
      // tslint:disable-next-line
      queryParams['product_tour_id'] = product_tour_id;
    }
    if (!!launch && launch.length > 0) {
      // tslint:disable-next-line
      queryParams['launch'] = launch;
    }
    if (!!price && price.length > 0) {
      // tslint:disable-next-line
      queryParams['price'] = price;
    }
    return queryParams;
  }

  public static getQuery(): string {
    const { origin, product_tour_id, launch, price } = this.getParams();
    let query = '?';
    query =
      origin != null && origin.length > 0 ? query + `&origin=${origin}` : query;
    query =
      launch != null && launch.length > 0 ? query + `&launch=${launch}` : query;
    query =
      price != null && price.length > 0 ? query + `&price=${price}` : query;
    query =
      product_tour_id != null && product_tour_id.length > 0
        ? query + `&product_tour_id=${product_tour_id}`
        : query;
    return query;
  }

  public static removeQueryFromURL(url: string): string {
    if (!!url && url.length > 0) {
      const urlHelper = url.split('?');
      urlHelper.splice(-1, 1);
      return urlHelper.join('?');
    }
    return url;
  }

  public static getApiName(url: string): string {
    if (url.includes(environment.configApi)) {
      return 'Config';
    }
    if (url.includes(environment.developersApi)) {
      return 'Developer';
    }
    if (url.includes(environment.tenantApi)) {
      return 'Tenant';
    }
    if (url.includes(environment.inboxApi)) {
      return 'Inbox';
    }
    if (url.includes(environment.integrationApi)) {
      return 'Integration';
    }
    if (url.includes(environment.networksApi)) {
      return 'Network';
    }
    if (url.includes(environment.recordsApi)) {
      return 'Records';
    }
    if (url.includes(environment.subscriptionApi)) {
      return 'Subscription';
    }
    if (url.includes(environment.userApi)) {
      return 'User';
    }
    if (url.includes(environment.plansApi)) {
      return 'Plans';
    }
    return url;
  }
}
