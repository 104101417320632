import { GeoIp } from './geo-ip.model';
import { IdpMfa } from '../../account/models/idp-mfa.model';

export class AuthUser {
  constructor(
    public givenName?: string,
    public familyName?: string,
    public nickname?: string,
    public name?: string,
    public picture?: string,
    public locale?: string,
    public email?: string,
    public emailVerified?: boolean,
    public sub?: string,
    public roles?: string[],
    public geoIp?: GeoIp,
    public region?: string,
    public ip?: string,
    public agent?: string,
    public idpMfa?: IdpMfa,
    public identityProvider?: string
  ) {}

  public static fromAuth0(authUser): AuthUser {
    const user = new AuthUser();
    user.givenName = authUser.given_name;
    user.familyName = authUser.family_name;
    user.nickname = authUser.nickname;
    user.name = authUser.name;
    user.picture = authUser.picture;
    user.locale = authUser.locale;
    user.email = authUser.email;
    user.emailVerified = authUser.email_verified;
    user.sub = authUser.sub;
    user.roles = authUser['https://sidedrawer.com/roles'];
    user.geoIp = authUser['https://sidedrawer.com/geoip'];
    user.region = authUser['https://sidedrawer.com/region'];
    user.ip = authUser['https://sidedrawer.com/ip'];
    user.agent = authUser['https://sidedrawer.com/agent'];
    user.idpMfa = authUser['https://sidedrawer.com/idpMfa'];
    user.identityProvider = 'auth0';
    return user;
  }

  public static fromKeycloakUser(keycloakUser): AuthUser {
    const user = new AuthUser();
    user.givenName = keycloakUser.given_name;
    user.familyName = keycloakUser.family_name;
    user.nickname = keycloakUser.nickname; // TODO not exist in keycloak
    user.name = keycloakUser.name;
    user.picture = keycloakUser.picture ?? undefined; // TODO not exist in keycloak
    user.locale = keycloakUser.locale; // TODO not exist in keycloak
    user.email = keycloakUser.email;
    user.emailVerified = keycloakUser.email_verified;
    user.sub = keycloakUser.sub;
    user.roles = [
      ...(keycloakUser['realm_access']['roles'] ?? []),
      ...(keycloakUser['federation_group'] ?? []),
    ];
    user.geoIp = keycloakUser['https://sidedrawer.com/geoip']; // TODO not exist in keycloak
    /* user.geoIp = {
      // TODO hardcoded
      country_code: 'CA',
      country_code3: 'CAN',
      country_name: 'Canada',
      city_name: 'Toronto',
      latitude: 43.6982,
      longitude: -79.4072,
      time_zone: 'America/Toronto',
      continent_code: 'NA',
    }; */

    user.region = keycloakUser['https://sidedrawer.com/region'];
    user.ip = keycloakUser['https://sidedrawer.com/ip']; // TODO not exist in keycloak
    user.agent = keycloakUser['https://sidedrawer.com/agent']; // TODO not exist in keycloak
    //user.ip = '20.151.112.137';
    //user.agent =
    //  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36';
    user.idpMfa = keycloakUser['https://sidedrawer.com/idpMfa']; // TODO not exist in keycloak
    user.identityProvider = 'keycloak';
    return user;
  }
}
