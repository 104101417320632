<div
  [matTooltip]="tooltip"
  [ngClass]="isTag ? 'sd-selected-item-tag' : null"
  class="sd-selected-item-container"
  #matTooltip="matTooltip"
  (mouseleave)="!!tooltip ? matTooltip.hide() : ''">
  <img
    *ngIf="!!avatar && avatar.length > 0"
    [alt]="value"
    [ngClass]="avatarRounded ? 'sd-selected-item-image-rounded' : null"
    [src]="avatar"
    class="sd-selected-item-image" />
  <div class="sd-selected-item-text">{{ value }}</div>
  <button
    (click)="removeButtonClicked.emit(true)"
    *ngIf="removable"
    class="sd-selected-item-text-button"
    mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
