import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/services/auth.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { SubscriptionPlan } from '../models/subscription-plan.model';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { SubscriptionFeatures } from '../models/subscription-features.model';
import {
  currentRoleSelector,
  currentTenantSelector,
  currentTenantSubscriptionTypeSelector,
  userHasBrandRoleSelector,
} from '../../tenants/store/tenant.selectors';
import { License } from '../models/license.model';
import { CreateSideDrawerFromSubscriptionDto } from '../models/create-side-drawer-from-subscription.dto';
import { PendingSponsorship } from '../views/subscriptions-pending/models/pending-sponsorship.model';
import { CreateSubscriptionDto } from '../models/create-subscription.dto';
import { CalculatorPlan } from '../models/calculator-plan.model';
import { GetCustomerDto } from '../models/get-customer.dto';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { AssignLicenseToExistingUserDto } from '../models/assign-license-to-existing-user.dto';
import { DetachLicenseToExistingUserDto } from '../models/detach-license-to-user.dto';
import { SubscriptionType } from '../models/subscription-type.model';

@Injectable()
export class SubscriptionsService {
  private tenantApi = environment.tenantApi;
  private subscriptionApi = environment.subscriptionApi;

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store<AppState>,
    private readonly http: HttpClient
  ) {}

  getUserSubscriptionsByCustomerId(
    customerId: string
  ): Observable<SubscriptionPlan[]> {
    return this.http.get<SubscriptionPlan[]>(
      this.subscriptionApi +
        `customers/customer-id/${customerId}/subscriptions`,
      { headers: this.authService.getHeaders() }
    );
  }

  getTenantSubscriptions(tenantId: string): Observable<SubscriptionPlan[]> {
    return this.http.get<SubscriptionPlan[]>(
      this.tenantApi + `tenant/tenant-id/${tenantId}/subscriptions`,
      { headers: this.authService.getHeaders() }
    );
  }

  getSubscriptionFeaturesBySidedrawerId(
    sidedrawerId: string
  ): Observable<SubscriptionFeatures> {
    return this.http.get<SubscriptionFeatures>(
      this.subscriptionApi + `features/sidedrawer-id/${sidedrawerId}`,
      { headers: this.authService.getHeaders() }
    );
  }

  getLicenses(subscriptionId: string): Observable<License[]> {
    return this.http.get<License[]>(
      this.subscriptionApi +
        `subscriptions/subscription-id/${subscriptionId}/licenses`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getTenantLicenses(
    tenantId: string,
    subscriptionId: string
  ): Observable<License[]> {
    return this.http.get<License[]>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/subscriptions/subscription-id/${subscriptionId}/licenses`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  createTenantSubscription(
    tenantId: string,
    dto: CreateSubscriptionDto
  ): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      this.tenantApi + `tenant/tenant-id/${tenantId}/subscriptions`,
      dto,
      { headers: this.authService.getHeaders() }
    );
  }

  updateSubscriptionQuantity(
    tenantId: string,
    subscriptionId: string,
    quantity: number,
    increase = true
  ): Observable<any> {
    return this.store.pipe(
      select(currentTenantSubscriptionTypeSelector),
      take(1),
      map(type => type === SubscriptionType.users),
      switchMap(isUserSubscriptionType =>
        isUserSubscriptionType
          ? this.http.put(
              this.tenantApi +
                `tenant/tenant-id/${tenantId}/subscriptions/subscription-id/${subscriptionId}/licenses/${
                  increase ? 'increase' : 'decrease'
                }`,
              { quantity },
              {
                headers: this.authService.getHeaders(),
              }
            )
          : this.http.put(
              this.subscriptionApi +
                `subscriptions/subscription-id/${subscriptionId}/licenses/${
                  increase ? 'increase' : 'decrease'
                }`,
              { quantity },
              {
                headers: this.authService.getHeaders(),
              }
            )
      ),
      map(() => true)
    );
  }

  createSideDrawerFromSubscription(
    subscriptionId: string,
    data: CreateSideDrawerFromSubscriptionDto,
    version = 1
  ): Observable<{ id: string }> {
    if (data.emailUsername) {
      delete data.emailUsername;
    }
    if (data.externalKeys?.length === 0 || !data.externalKeys) {
      delete data.externalKeys;
    }
    return this.http.post<{ id: string }>(
      UtilsHelper.changeVersionApiUrl(this.subscriptionApi, version) +
        `subscriptions/subscription-id/${subscriptionId}/sidedrawer`,
      {
        ...data,
      },
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  createSideDrawerFromTenantsSubscription(
    tenantId: string,
    subscriptionId: string,
    data: CreateSideDrawerFromSubscriptionDto,
    version = 1
  ): Observable<{ id: string }> {
    if (data.emailUsername) {
      delete data.emailUsername;
    }
    if (data.externalKeys?.length === 0 || !data.externalKeys) {
      delete data.externalKeys;
    }
    return this.http.post<{ id: string }>(
      UtilsHelper.changeVersionApiUrl(this.tenantApi, version) +
        `tenant/tenant-id/${tenantId}/subscriptions/subscription-id/${subscriptionId}/sidedrawer`,
      {
        ...data,
      },
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getPendingSponsorShipsByBrandCode(): Observable<PendingSponsorship[]> {
    return forkJoin([
      this.store.pipe(select(currentTenantSelector), take(1)),
      this.store.pipe(select(currentRoleSelector), take(1)),
      this.store.pipe(select(userHasBrandRoleSelector), take(1)),
    ]).pipe(
      mergeMap(([tenant, role, userHasBrandRole]) => {
        const operation = userHasBrandRole
          ? this.getPendingSponsorShips(tenant.id, role.brandCode)
          : this.getPendingSponsorShips(tenant.id);
        return operation.pipe(catchError(() => of([])));
      })
    );
  }

  getPendingSponsorShips(
    tenantId: string,
    brandCode?: string
  ): Observable<PendingSponsorship[]> {
    let params = {};
    if (brandCode) {
      params = { brandCode };
    }
    return this.http.get<PendingSponsorship[]>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/users/invitations/pending-sponsorship`,
      {
        headers: this.authService.getHeaders(),
        params,
      }
    );
  }

  deletePendingSponsorship(
    tenantId: string,
    invitationCode: string
  ): Observable<boolean> {
    return this.http
      .delete(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/users/invitations/invitation-code/${invitationCode}`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(map(() => true));
  }

  resendPendingSponsorship(
    tenantId: string,
    invitationCode: string
  ): Observable<boolean> {
    return this.http
      .post(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/users/invitations/invitation-code/${invitationCode}/resend`,
        {},
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(map(() => true));
  }

  revokeLicenseToSideDrawer(
    subscriptionId: string,
    licenseKey: string
  ): Observable<boolean> {
    return this.http
      .request(
        'DELETE',
        this.subscriptionApi +
          `subscriptions/subscription-id/${subscriptionId}/licenses`,
        {
          body: {
            licenseKey,
          },
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(map(() => true));
  }

  assignLicenseToSideDrawer(
    subscriptionId: string,
    licenseKey: string,
    sidedrawerId: string
  ): Observable<boolean> {
    return this.http
      .post(
        this.subscriptionApi +
          `subscriptions/subscription-id/${subscriptionId}/licenses`,
        {
          licenseKey,
          sidedrawerId,
        },
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(map(() => true));
  }

  getSubscriptionPlanPrice(
    priceId: string,
    quantity: number
  ): Observable<CalculatorPlan> {
    return this.http.get<CalculatorPlan>(
      this.subscriptionApi +
        `prices/price-id/${priceId}/calculator?quantity=${quantity}`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  deleteSubscription(subscriptionId: string): Observable<boolean> {
    return this.http
      .delete(
        this.subscriptionApi +
          `subscriptions/subscription-id/${subscriptionId}`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  deleteSponsoredUsersSubscription(
    tenantId: string,
    subscriptionId: string
  ): Observable<boolean> {
    return this.http
      .delete(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/subscriptions/subscription-id/${subscriptionId}`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  getCustomerByCustomerId(customerId: string): Observable<GetCustomerDto> {
    return this.http.get<GetCustomerDto>(
      this.subscriptionApi + `customers/customer-id/${customerId}`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getTenantCustomerByTenantId(tenantId: string): Observable<GetCustomerDto> {
    return this.http.get<GetCustomerDto>(
      this.tenantApi + `tenant/tenant-id/${tenantId}/subscriptions/customer`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  assignLicenseToExistingUser(
    tenantId: string,
    subscriptionId: string,
    dto: AssignLicenseToExistingUserDto
  ): Observable<any> {
    return this.http.post(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/subscriptions/subscription-id/${subscriptionId}/licenses`,
      dto,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  detachLicenseToExistingUser(
    tenantId: string,
    subscriptionId: string,
    dto: DetachLicenseToExistingUserDto
  ): Observable<any> {
    return this.http.request(
      'DELETE',
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/subscriptions/subscription-id/${subscriptionId}/licenses`,
      {
        body: dto,
        headers: this.authService.getHeaders(),
      }
    );
  }
}
