import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProcessStep } from '../../tenants/models/process-step.model';
import { SampleData } from '../models/sample-data.model';
import { ProcessStepStatus } from '../../tenants/models/process-step-status.enum';
import {
  BrandSamplesActions,
  BrandSamplesActionsTypes,
} from './brand-samples.actions';
import { PlanItemSampleData } from '../models/plans-sample-data.model';
import { License } from '../../subscriptions/models/license.model';

export interface BrandSamplesState extends EntityState<ProcessStep> {
  sampleDataInfo: SampleData;
  gettingSampleData: boolean;
  brandCode: string;
  subscriptionId: string;
  licenses: License[];
  sideDrawersIds: Map<number, string>;
  planIds: Map<string, { id: string; items: PlanItemSampleData[] }>;
  assignPlansOperations: { sideDrawerId: string; planId: string }[];
  sampleDataConfiguration: {
    createSD: boolean;
    createPlan: boolean;
    deliverInfoRequest: boolean;
    addCollaborators: boolean;
    sampleVCard: boolean;
  };
}

export const brandSamplesAdapter: EntityAdapter<ProcessStep> =
  createEntityAdapter<ProcessStep>({
    selectId: model => model.key,
  });

export const initialBrandSampleState: BrandSamplesState =
  brandSamplesAdapter.getInitialState({
    sampleDataInfo: null,
    gettingSampleData: false,
    brandCode: null,
    subscriptionId: null,
    licenses: [],
    sideDrawersIds: new Map<number, string>(),
    planIds: new Map<string, { id: string; items: PlanItemSampleData[] }>(),
    assignPlansOperations: [],
    sampleDataConfiguration: {
      createSD: true,
      createPlan: true,
      deliverInfoRequest: true,
      addCollaborators: true,
      sampleVCard: true,
      brandId: null,
    },
    ids: [
      'increaseLicenses',
      'creatingSideDrawers',
      'creatingPlans',
      'creatingItems',
      'checkSideDrawersPermissions',
      'assigningCollaborators',
      'assigningPlans',
      'creatingVcard',
    ],
    entities: {
      increaseLicenses: {
        key: 'increaseLicenses',
        status: ProcessStepStatus.pending,
      },
      getLicenses: {
        key: 'getLicenses',
        status: ProcessStepStatus.pending,
      },
      creatingSideDrawers: {
        key: 'creatingSideDrawers',
        status: ProcessStepStatus.pending,
      },
      creatingPlans: {
        key: 'creatingPlans',
        status: ProcessStepStatus.pending,
      },
      creatingItems: {
        key: 'creatingItems',
        status: ProcessStepStatus.pending,
      },
      checkSideDrawersPermissions: {
        key: 'checkSideDrawersPermissions',
        status: ProcessStepStatus.pending,
      },
      assigningCollaborators: {
        key: 'assigningCollaborators',
        status: ProcessStepStatus.pending,
      },
      assigningPlans: {
        key: 'assigningPlans',
        status: ProcessStepStatus.pending,
      },
      creatingVcard: {
        key: 'creatingVcard',
        status: ProcessStepStatus.pending,
      },
    },
  });

export function brandSamplesReducer(
  state: BrandSamplesState = initialBrandSampleState,
  action: BrandSamplesActions
): BrandSamplesState {
  const planAux = state.planIds;
  switch (action.type) {
    case BrandSamplesActionsTypes.SampleDataProcessStepUpdated:
      return brandSamplesAdapter.updateOne(action.payload.step, state);
    case BrandSamplesActionsTypes.GettingSampleDataChange:
      return {
        ...state,
        gettingSampleData: action.payload.state,
      };
    case BrandSamplesActionsTypes.SampleDataJsonLoaded:
      return {
        ...state,
        sampleDataInfo: action.payload.data,
      };
    case BrandSamplesActionsTypes.SampleDataInitProcess:
      return {
        ...state,
        brandCode: action.payload.brandCode,
        subscriptionId: action.payload.subscriptionId,
        sampleDataConfiguration: {
          createSD: action.payload.createSD,
          createPlan: action.payload.createPlan,
          deliverInfoRequest: action.payload.deliverInfoRequest,
          addCollaborators: action.payload.addCollaborators,
          sampleVCard: action.payload.sampleVCard,
        },
      };
    case BrandSamplesActionsTypes.SampleDataPlansOperationsLoaded:
      return {
        ...state,
        assignPlansOperations: [
          ...state.assignPlansOperations,
          ...action.payload.operations,
        ],
      };
    case BrandSamplesActionsTypes.SampleDataPlanIdLoaded:
      planAux.set(action.payload.id, {
        id: action.payload.id,
        items: action.payload.items,
      });
      return {
        ...state,
        planIds: planAux,
      };
    case BrandSamplesActionsTypes.SampleDataPlanIdRemoved:
      planAux.delete(action.payload.id);
      return {
        ...state,
        planIds: planAux,
      };
    case BrandSamplesActionsTypes.SampleDataSideDrawerIdLoaded: {
      const sdAux = state.sideDrawersIds;
      sdAux.set(action.payload.key, action.payload.id);
      return {
        ...state,
        sideDrawersIds: sdAux,
      };
    }
    case BrandSamplesActionsTypes.SampleDataLicensesLoaded:
      return {
        ...state,
        licenses: action.payload.licenses,
      };
    case BrandSamplesActionsTypes.SampleDataResetProcess:
      return initialBrandSampleState;
    default:
      return state;
  }
}

export const { selectAll, selectTotal } = brandSamplesAdapter.getSelectors();
