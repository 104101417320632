import { EtlField } from '../models/etl-field.model';
import { Dictionary } from '../models/dictionary.model';
import { Relationship } from '../models/relationship.model';
import { RelationshipType } from '../models/relationship-type';
import { SubscriptionStatus } from '../models/subscription-status.model';
import { DicSidedrawerType } from '../models/sidedrawer-type.model';
import { BlockedFileFormat } from '../models/blocked-file-format.model';
import { Tos } from '../models/tos.model';
import { PrivacyPolicy } from '../models/privacy-policy.model';
import { Support } from '../models/support.model';
import {
  DictionaryActions,
  DictionaryActionsTypes,
} from './dictionary.actions';
import { Locale } from '../models/locale.model';
import { ImageFileFormat } from '../models/image-file-format.model';
import { VideoFileFormat } from '../models/video-file-format.model';
import { Currency } from '../models/currency.model';
import { PlanItemFormType } from '../models/plan-item-form-type.model';
import { TenantRoleDictionary } from '../models/tenant-role-dictionary.model';
import { DictionaryPrice } from '../models/dictionary-price.model';
import { VCardLink } from '../models/vcard-link.model';
import { SdTypeByTenant } from '../models/sd-type-by-tenant.model';
import { JumpOffButton } from '../models/jump-off-button.model';
import { DataBaseRegion } from '../models/data-base-region.models';
import { SocialIdentityProvider } from '../models/social-identity-provider.model';
import { HelpSections } from '../models/help-sections.model';
import { HelpVideos } from '../models/help-videos.model';
import { DicSdRole } from '../models/dic-sd-role.model';
import { CloudDrive } from '../models/cloud-integration.model';
import { DicCustomMessage } from '../models/custom-message.model';
import { DicIntegrationProviders } from '../models/dic-integration-providers.model';

export const featureName = 'dictionary';

export interface DictionaryState {
  locales: Locale[];
  localeDefault: Locale;
  dictionary: Dictionary;
  relationships: Relationship[];
  relationshipTypes: RelationshipType[];
  prices: DictionaryPrice[];
  subscriptionsStatuses: SubscriptionStatus[];
  sideDrawerTypes: DicSidedrawerType[];
  blockedFileFormats: BlockedFileFormat[];
  imageFileFormats: ImageFileFormat[];
  videoFileFormats: VideoFileFormat[];
  tos: Tos[];
  privacyPolicies: PrivacyPolicy[];
  supports: Support[];
  currencies: Currency[];
  planItemFormTypes: PlanItemFormType[];
  tenantRoles: TenantRoleDictionary[];
  vCardLinks: VCardLink[];
  sdTypeByTenant: SdTypeByTenant[];
  etlFields: EtlField[];
  jumpOffButtons: JumpOffButton[];
  dataBaseRegions: DataBaseRegion[];
  socialIdentityProviders: SocialIdentityProvider[];
  gettingDictionary: boolean;
  gettingFails: boolean;
  helpSections: HelpSections[];
  helpVideos: HelpVideos[];
  sdRoles: DicSdRole[];
  cloudDrives: CloudDrive[];
  customMessages: DicCustomMessage[];
  integrationProviders: DicIntegrationProviders[];
}

export const initialDictionaryState: DictionaryState = {
  locales: null,
  localeDefault: null,
  dictionary: null,
  relationships: null,
  relationshipTypes: null,
  prices: null,
  subscriptionsStatuses: null,
  sideDrawerTypes: null,
  blockedFileFormats: null,
  imageFileFormats: null,
  videoFileFormats: null,
  tos: null,
  privacyPolicies: null,
  supports: null,
  currencies: null,
  planItemFormTypes: null,
  tenantRoles: null,
  vCardLinks: null,
  sdTypeByTenant: null,
  etlFields: null,
  jumpOffButtons: null,
  dataBaseRegions: null,
  socialIdentityProviders: null,
  gettingDictionary: false,
  gettingFails: false,
  helpSections: null,
  helpVideos: null,
  sdRoles: null,
  cloudDrives: null,
  customMessages: null,
  integrationProviders: null,
};

export function dictionaryReducer(
  state: DictionaryState = initialDictionaryState,
  action: DictionaryActions
): DictionaryState {
  switch (action.type) {
    case DictionaryActionsTypes.BlockedFileFormatsLoaded:
      return {
        ...state,
        blockedFileFormats: action.payload.data,
      };
    case DictionaryActionsTypes.ImageFileFormatsLoaded:
      return {
        ...state,
        imageFileFormats: action.payload.data,
      };
    case DictionaryActionsTypes.VideoFileFormatsLoaded:
      return {
        ...state,
        videoFileFormats: action.payload.data,
      };
    case DictionaryActionsTypes.DictionaryLoaded:
      return {
        ...state,
        dictionary: action.payload.data,
      };
    case DictionaryActionsTypes.LocalesLoaded:
      return {
        ...state,
        locales: action.payload.data,
      };
    case DictionaryActionsTypes.LocaleDefaultChange:
      return {
        ...state,
        localeDefault: action.payload.data,
      };
    case DictionaryActionsTypes.DictionaryPricesLoaded:
      return {
        ...state,
        prices: action.payload.data,
      };
    case DictionaryActionsTypes.RelationshipsLoaded:
      return {
        ...state,
        relationships: action.payload.data,
      };
    case DictionaryActionsTypes.RelationshipTypesLoaded:
      return {
        ...state,
        relationshipTypes: action.payload.data,
      };
    case DictionaryActionsTypes.DictionarySideDrawerTypesLoaded:
      return {
        ...state,
        sideDrawerTypes: action.payload.data,
      };
    case DictionaryActionsTypes.SubscriptionsStatusLoaded:
      return {
        ...state,
        subscriptionsStatuses: action.payload.data,
      };
    case DictionaryActionsTypes.CurrenciesLoaded:
      return {
        ...state,
        currencies: action.payload.data,
      };
    case DictionaryActionsTypes.PlanItemFormTypesLoaded:
      return {
        ...state,
        planItemFormTypes: action.payload.data,
      };
    case DictionaryActionsTypes.TenantRolesLoaded:
      return {
        ...state,
        tenantRoles: action.payload.data.filter(role => role.visible),
      };
    case DictionaryActionsTypes.VCardLinksLoaded:
      return {
        ...state,
        vCardLinks: action.payload.data,
      };
    case DictionaryActionsTypes.SdTypeByTenantLoaded:
      return {
        ...state,
        sdTypeByTenant: action.payload.data,
      };
    case DictionaryActionsTypes.EtlFieldsLoaded:
      return {
        ...state,
        etlFields: action.payload.data,
      };
    case DictionaryActionsTypes.JumpOffButtonsLoaded:
      return {
        ...state,
        jumpOffButtons: action.payload.data,
      };
    case DictionaryActionsTypes.DataBaseRegionsLoaded:
      return {
        ...state,
        dataBaseRegions: action.payload.data,
      };
    case DictionaryActionsTypes.SocialIdentityProvidersLoaded:
      return {
        ...state,
        socialIdentityProviders: action.payload.data,
      };
    case DictionaryActionsTypes.GettingDictionaryChange:
      return {
        ...state,
        gettingDictionary: action.payload.state,
      };
    case DictionaryActionsTypes.GettingDictionaryFails:
      return {
        ...state,
        gettingFails: true,
      };
    case DictionaryActionsTypes.HelpSectionsLoaded:
      return {
        ...state,
        helpSections: action.payload.data,
      };
    case DictionaryActionsTypes.HelpVideosLoaded:
      return {
        ...state,
        helpVideos: action.payload.data,
      };
    case DictionaryActionsTypes.TosLoaded:
      return {
        ...state,
        tos: action.payload.data,
      };
    case DictionaryActionsTypes.PrivacyPoliciesLoaded:
      return {
        ...state,
        privacyPolicies: action.payload.data,
      };
    case DictionaryActionsTypes.DictionarySdRolesLoaded:
      return {
        ...state,
        sdRoles: action.payload.data,
      };
    case DictionaryActionsTypes.CloudIntegrationsLoaded:
      return {
        ...state,
        cloudDrives: action.payload.data,
      };
    case DictionaryActionsTypes.CustomMessagesLoaded:
      return {
        ...state,
        customMessages: action.payload.data,
      };
    case DictionaryActionsTypes.IntegrationProvidersLoaded:
      return {
        ...state,
        integrationProviders: action.payload.data,
      };
    default:
      return state;
  }
}
