import { Branding } from '../../branding/models/branding.model';
import { DataBaseRegion } from '../../dictionary/models/data-base-region.models';
import { CustomPaginatorApiResponse } from '../models/custom-paginator-api-response.model';

export class PaginatorHelper {
  public static cleanStartingAfterQueryParam(statingAfter: string): string {
    try {
      return statingAfter.split('&')[0].replace('startingAfter=', '');
    } catch (e) {
      return statingAfter;
    }
  }
  public static getInitialBrandListWithRegions<T>(
    brands: Branding[],
    defaultBrandCode: string,
    dataBaseRegions: DataBaseRegion[],
    currentTenantRegion: string
  ): Partial<CustomPaginatorApiResponse<T>>[] {
    const notTenantDataBaseRegion = dataBaseRegions.filter(
      region => region.databaseregion !== currentTenantRegion
    );
    if (brands.some(b => b.brandCode === defaultBrandCode)) {
      const notDefaultBrands = brands
        .filter(brand => brand.brandCode !== defaultBrandCode)
        .sort((a, b) => a.brandCode.localeCompare(b.brandCode));
      const requests = [
        {
          brandCode: defaultBrandCode,
          hasMore: true,
          region: currentTenantRegion,
          id: `${defaultBrandCode}-${currentTenantRegion}`,
        },
        ...notTenantDataBaseRegion.map(region => ({
          brandCode: defaultBrandCode,
          hasMore: true,
          region: region.databaseregion,
          id: `${defaultBrandCode}-${region.databaseregion}`,
        })),
      ];
      brands
        .filter(brand => brand.brandCode !== defaultBrandCode)
        .sort((a, b) => a.brandCode.localeCompare(b.brandCode))
        .forEach(brand => {
          requests.push({
            brandCode: brand.brandCode,
            hasMore: true,
            region: currentTenantRegion,
            id: `${brand.brandCode}-${currentTenantRegion}`,
          });
          notTenantDataBaseRegion.forEach(region => {
            requests.push({
              brandCode: brand.brandCode,
              hasMore: true,
              region: region.databaseregion,
              id: `${brand.brandCode}-${region.databaseregion}`,
            });
          });
        });
      return requests;
    }
    const requests = [];
    brands
      .sort((a, b) => a.brandCode.localeCompare(b.brandCode))
      .forEach(brand => {
        requests.push({
          brandCode: brand.brandCode,
          hasMore: true,
          region: currentTenantRegion,
          id: `${brand.brandCode}-${currentTenantRegion}`,
        });
        notTenantDataBaseRegion.forEach(region => {
          requests.push({
            brandCode: brand.brandCode,
            hasMore: true,
            region: region.databaseregion,
            id: `${brand.brandCode}-${region.databaseregion}`,
          });
        });
      });
    return requests;
  }
}
