import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Dictionary } from '../../../../dictionary/models/dictionary.model';
import { AppState } from '../../../../reducers';
import { dictionarySelector } from '../../../../dictionary/store/dictionary.selectors';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnInit, OnDestroy {
  @Input() passwordControl: UntypedFormControl;
  @Output() strengthChange = new EventEmitter<number>();
  strength = 0;
  hasLowerCase = false;
  hasUpperCase = false;
  hasNumbers = false;
  hasSpecialCharacters = false;
  hasMoreThan8Characters = false;
  subscription = new Subscription();
  dictionary$: Observable<Dictionary>;

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.dictionary$ = this.store.pipe(select(dictionarySelector));
    if (this.passwordControl) {
      this.subscription.add(
        this.passwordControl.valueChanges.subscribe(value => {
          this.checkLength(value);
          this.checkLowerCase(value);
          this.checkUpperCase(value);
          this.checkNumbers(value);
          this.checkSpecialCharacters(value);
          this.calculateStrength();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  calculateStrength(): void {
    this.strength = 0;
    if (this.hasLowerCase) {
      this.strength++;
    }
    if (this.hasUpperCase) {
      this.strength++;
    }
    if (this.hasNumbers) {
      this.strength++;
    }
    if (this.hasSpecialCharacters) {
      this.strength++;
    }
    this.strengthChange.emit(this.strength);
  }

  checkLowerCase(value: string): void {
    this.hasLowerCase = /[a-z]/.test(value);
  }

  checkUpperCase(value: string): void {
    this.hasUpperCase = /[A-Z]/.test(value);
  }

  checkLength(value: string): void {
    this.hasMoreThan8Characters = !!value && value.length >= 8;
  }

  checkNumbers(value: string): void {
    this.hasNumbers = /[0-9]/.test(value);
  }

  checkSpecialCharacters(value: string): void {
    this.hasSpecialCharacters = /\W|_/.test(value);
  }
}
