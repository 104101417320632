import { Injectable } from '@angular/core';
import { BrandingService } from '../services/branding.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BrandListActionsTypes, BrandsRequested } from './brand-list.actions';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class BrandListEffects {
  brandsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<BrandsRequested>(BrandListActionsTypes.BrandsRequested),
      mergeMap(action =>
        this.brandingService.getBrands(action.payload.tenantId)
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly brandingService: BrandingService
  ) {}
}
