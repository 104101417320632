import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PlanItem } from '../models/plan-item.model';
import { PlanItemActions, PlanItemActionsTypes } from './plan-items.actions';

export interface PlanItemState extends EntityState<PlanItem> {
  loading: boolean;
}

export const planItemsAdapter: EntityAdapter<PlanItem> =
  createEntityAdapter<PlanItem>({
    selectId: item => item.id,
  });

export const initialPlanItemState: PlanItemState =
  planItemsAdapter.getInitialState({
    loading: false,
  });

export function planItemsReducer(
  state = initialPlanItemState,
  action: PlanItemActions
): PlanItemState {
  switch (action.type) {
    case PlanItemActionsTypes.PlanItemsChangeSpinner:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case PlanItemActionsTypes.PlanItemsLoaded:
      return planItemsAdapter.setAll(action.payload.items, state);
    case PlanItemActionsTypes.PlanItemAdded:
      return planItemsAdapter.addOne(action.payload.item, state);
    case PlanItemActionsTypes.PlanItemUpdated:
      return planItemsAdapter.updateOne(action.payload.item, state);
    case PlanItemActionsTypes.PlanItemDeleted:
      return planItemsAdapter.removeOne(action.payload.id, state);
    case PlanItemActionsTypes.PlanItemsClearInformation:
      return planItemsAdapter.removeAll(state);
    default:
      return state;
  }
}

export const { selectAll } = planItemsAdapter.getSelectors();
