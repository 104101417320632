import { Injectable } from '@angular/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';

@Injectable()
export class KeycloakAuthService {
  constructor(
    private readonly oauthService: OAuthService,
    private readonly router: Router
  ) {
    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(() => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(
        filter(e => ['session_terminated', 'session_error'].includes(e.type))
      )
      .subscribe((oAuthEvent: OAuthEvent) => {
        console.warn({ oAuthEvent });
        // this.logout();
      });

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public async initCredentials(): Promise<boolean> {
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return (
      this.oauthService
        .loadDiscoveryDocument()

        // For demo purposes, we pretend the previous call was very slow
        .then(
          () => new Promise<void>(resolve => setTimeout(() => resolve(), 1500))
        )

        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => {
          console.log('tryLogin');
          // return this.oauthService.initLoginFlow();
          return this.oauthService.tryLogin();
          // return this.oauthService.tryLogin();
        })

        .then(() => {
          console.log(
            'hasValidAccessToken',
            this.oauthService.hasValidAccessToken()
          );
          if (this.oauthService.hasValidAccessToken()) {
            return Promise.resolve();
          }

          // 2. SILENT LOGIN:
          // Try to log in via a refresh because then we can prevent
          // needing to redirect the user:
          console.log('silentRefresh');
          return this.oauthService
            .silentRefresh()
            .then(() => Promise.resolve())
            .catch(result => {
              // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
              // Only the ones where it's reasonably sure that sending the
              // user to the IdServer will help.
              const errorResponsesRequiringUserInteraction = [
                'interaction_required',
                'login_required',
                'account_selection_required',
                'consent_required',
              ];

              if (
                result &&
                result.reason &&
                errorResponsesRequiringUserInteraction.indexOf(
                  result.reason.error
                ) >= 0
              ) {
                // 3. ASK FOR LOGIN:
                // At this point we know for sure that we have to ask the
                // user to log in, so we redirect them to the IdServer to
                // enter credentials.
                //
                // Enable this to ALWAYS force a user to login.
                // this.login();
                //
                // Instead, we'll now do this:
                console.warn(
                  'User interaction is needed to log in, we will wait for the user to manually log in.'
                );
                return Promise.resolve();
              }

              // We can't handle the truth, just pass on the problem to the
              // next handler.
              return Promise.reject(result);
            });
        })

        .then(() => {
          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (
            this.oauthService.state &&
            this.oauthService.state !== 'undefined' &&
            this.oauthService.state !== 'null'
          ) {
            let stateUrl = this.oauthService.state;
            if (stateUrl.startsWith('/') === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }
            console.log(
              `There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`
            );
            this.router.navigateByUrl(stateUrl);
          }
          return true;
        })
        .catch(() => {
          return false;
        })
    );
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    console.log(targetUrl || this.router.url);
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout(): void {
    this.oauthService.logOut();
  }

  public refresh(): Promise<OAuthEvent> {
    return this.oauthService.silentRefresh();
  }

  public hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public get refreshToken(): string {
    return this.oauthService.getRefreshToken();
  }

  public get identityForKeycloakFromJWT() {
    const identityForKeycloakFromJWT = jwtDecode(this.accessToken);
    console.log('jwtDecode', identityForKeycloakFromJWT);
    return identityForKeycloakFromJWT;
  }

  public get idToken(): string {
    return this.oauthService.getIdToken();
  }

  public get logoutUrl(): string {
    return this.oauthService.logoutUrl;
  }
}
