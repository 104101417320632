import { Component, Input, OnInit } from '@angular/core';
import { Locale } from '../../../../dictionary/models/locale.model';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-sd-locale-selector',
  templateUrl: './sd-locale-selector.component.html',
  styleUrls: ['./sd-locale-selector.component.scss'],
})
export class SdLocaleSelectorComponent implements OnInit {
  @Input() options: Locale[];
  @Input() placeholder: string;
  @Input() controller: UntypedFormControl;
  @Input() error: string;
  @Input() startValue: Locale;

  constructor() {}

  ngOnInit(): void {
    if (this.startValue) {
      this.controller.setValue(this.startValue.localeid);
    }
  }
}
