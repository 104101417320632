import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplatesRoutes } from './routes/templates.routes';

const routes: Routes = [
  {
    path: TemplatesRoutes.main,
    loadChildren: () =>
      import('./views/templates-list/templates-list.module').then(
        m => m.TemplatesListModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TemplatesRoutingModule {}
