import { SdTableSortEvent } from '../models/sd-table-sort-event.model';
import { SdTableSortDirection } from '../models/sd-table-sort-direction.model';
import { UtilsHelper } from '../../../core/helpers/utils.helper';
import { DateHelper } from '../../../core/helpers/date.helper';
import { SdTableSettings } from '../models/sd-table-settings.model';
import { SdTableColumn } from '../models/sd-table-column.model';

const sdTableSettings = 'sdTableSettings';

export class SdTableHelper {
  public static sortFilterAndSlice(
    data: any[],
    sortBy: SdTableSortEvent,
    filter: string,
    page: number,
    elementsPerPage: number
  ): any[] {
    const filtered = this.filterData([...data], filter);
    const sorted = this.sortData(filtered, sortBy);
    return this.sliceData(sorted, page, elementsPerPage);
  }

  public static sortData(data: any[], sortBy: SdTableSortEvent): any[] {
    try {
      if (!sortBy?.direction || data?.length === 0) {
        return data;
      }
      switch (typeof data[0][`${sortBy.active}`]) {
        case 'string':
          return sortBy.direction === SdTableSortDirection.asc
            ? data.sort((a, b) =>
                UtilsHelper.compareStringsWithTagNumeric(
                  a[`${sortBy.active}`],
                  b[`${sortBy.active}`]
                )
              )
            : data.sort((a, b) =>
                UtilsHelper.compareStringsWithTagNumeric(
                  b[`${sortBy.active}`],
                  a[`${sortBy.active}`]
                )
              );
        case 'number':
          return sortBy.direction === SdTableSortDirection.asc
            ? data.sort((a, b) =>
                UtilsHelper.compareNumbers(
                  a[`${sortBy.active}`],
                  b[`${sortBy.active}`]
                )
              )
            : data.sort((a, b) =>
                UtilsHelper.compareNumbers(
                  b[`${sortBy.active}`],
                  a[`${sortBy.active}`]
                )
              );
        case 'boolean':
          return sortBy.direction === SdTableSortDirection.asc
            ? data.sort((a, b) => a[`${sortBy.active}`] - b[`${sortBy.active}`])
            : data.sort(
                (a, b) => b[`${sortBy.active}`] - a[`${sortBy.active}`]
              );
        default:
          if (data[`${sortBy.active}`] instanceof Date) {
            return sortBy.direction === SdTableSortDirection.asc
              ? data.sort((a, b) =>
                  DateHelper.compareDates(
                    a[`${sortBy.active}`],
                    b[`${sortBy.active}`]
                  )
                )
              : data.sort((a, b) =>
                  DateHelper.compareDates(
                    b[`${sortBy.active}`],
                    a[`${sortBy.active}`]
                  )
                );
          }
          return data;
      }
    } catch (e) {
      console.warn(e);
      return data;
    }
  }

  public static filterData(data: any[], filter: string): any[] {
    try {
      if (!filter || filter?.length === 0) {
        return data;
      }
      const filteredData = [];
      data.forEach(element => {
        let addElement = false;
        Object.values(element).forEach(value => {
          switch (typeof value) {
            case 'string':
            case 'boolean':
            case 'number':
              if (
                value.toString().toLowerCase().includes(filter.toLowerCase())
              ) {
                addElement = true;
              }
              break;
          }
        });
        if (addElement) {
          filteredData.push(element);
        }
      });
      return filteredData;
    } catch (e) {
      console.warn('Table reducer, Filter Data' + e);
      return data;
    }
  }

  public static sliceData(
    data: any[],
    page: number,
    elementsPerPage: number
  ): any[] {
    try {
      if (elementsPerPage < data.length - page * elementsPerPage) {
        return data.slice(
          page * elementsPerPage,
          page * elementsPerPage + elementsPerPage
        );
      }
      return data.slice(page * elementsPerPage);
    } catch (e) {
      console.warn('Table reducer, Slice Data' + e);
      return data;
    }
  }

  public static removeAllHtmlTagsFromText(text: string): string {
    const div = document.createElement('div');
    div.innerHTML = text;
    return div.textContent || div.innerText || '';
  }

  public static saveDataCollection(key: string, value: SdTableSettings) {
    if (localStorage.getItem(sdTableSettings)) {
      const tableSettings = JSON.parse(localStorage.getItem(sdTableSettings));
      tableSettings[key] = value;
      localStorage.setItem(sdTableSettings, JSON.stringify(tableSettings));
    }

    if (!localStorage.getItem(sdTableSettings)) {
      const tableSettings = {};
      tableSettings[key] = value;
      localStorage.setItem(sdTableSettings, JSON.stringify(tableSettings));
    }
  }

  public static getDataFromCollection(key: string): SdTableSettings {
    if (localStorage.getItem(sdTableSettings)) {
      const tableSettings = JSON.parse(localStorage.getItem(sdTableSettings));
      return tableSettings[key];
    }
    return null;
  }

  public static checkIfColumnsChanged(
    codedColumns: SdTableColumn[],
    savedColumns: SdTableColumn[]
  ): boolean {
    if (codedColumns?.length !== savedColumns?.length) {
      return true;
    }
    codedColumns?.forEach(codedColumn => {
      if (
        !savedColumns?.some(savedColum => savedColum?.key === codedColumn?.key)
      ) {
        return true;
      }
    });
    return false;
  }
}
