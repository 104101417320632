import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { SdLocaleSelectorA11yTemplates } from '../../model/sd-locale-selector-a11y-templates.enum';

@Component({
  selector: 'app-sd-locale-selector-a11y',
  templateUrl: './sd-locale-selector-a11y.component.html',
  styleUrls: ['./sd-locale-selector-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdLocaleSelectorA11yComponent {
  @Input() options: Locale[];
  @Input() placeholder: string;
  @Input() tooltip: string;
  @Input() controller: UntypedFormControl;
  @Input() error: string;
  @Input() icon: string;
  @Input() template: SdLocaleSelectorA11yTemplates =
    SdLocaleSelectorA11yTemplates.default;
  sdAccessibility: SdAccessibilitySetting;
  startValue: Locale;
  sdLocaleSelectorA11yTemplates = SdLocaleSelectorA11yTemplates;
  @Input() set setAccessibilityForButton(value: SdAccessibilitySetting) {
    this.sdAccessibility = value;
  }
  @Input() set setStartValue(value: Locale) {
    this.startValue = value;
    if (this.startValue) {
      this.controller.setValue(this.startValue.localeid);
    }
  }
  @Output() optionSelected = new EventEmitter<string>();
  optionSelectedSignal = signal('');

  onClick(localeId: string) {
    this.optionSelectedSignal.set(localeId);
    this.optionSelected.emit(localeId);
  }
}
