import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { User } from '../models/user.model';

export enum UserListActionsTypes {
  UsersLoaded = '[User service] Users Loaded',
  UserUpdated = '[User Table / User details view] User Updated',
  UserDeleted = '[User Table / User details view] User Deleted',
  UsersClearInformation = '[Switch Tenant] Clear all User information',
}

export class UsersLoaded implements Action {
  readonly type = UserListActionsTypes.UsersLoaded;

  constructor(public payload: { users: User[] }) {}
}

export class UserUpdated implements Action {
  readonly type = UserListActionsTypes.UserUpdated;

  constructor(public payload: { user: Update<User> }) {}
}

export class UserDeleted implements Action {
  readonly type = UserListActionsTypes.UserDeleted;

  constructor(public payload: { username: string }) {}
}

export class UsersClearInformation implements Action {
  readonly type = UserListActionsTypes.UsersClearInformation;
}

export type UserListActions =
  | UsersLoaded
  | UserUpdated
  | UserDeleted
  | UsersClearInformation;
