import { Action } from '@ngrx/store';
import { InitialFlowErrorType } from '../models/initial-flow-error-type.model';
import { GenericError } from '../models/generic-error.model';
import { HttpErrorResponse } from '@angular/common/http';

export enum CoreActionsTypes {
  InitialFlowErrorChange = '[Dictionary Service / Core Template / Role Guard] Initial Flow Error Change',
  SecurityCompleted = '[MFA / Security Settings Dialog] Security Completed is True',
  GenericErrorLoad = '[Http Request] Generic Error Loaded',
  ErrorLoaded = '[Error Info] Error Loaded',
  GenericErrorClear = '[Generic Error Dialog] Generic Error Information Clear',
}

export class InitialFlowErrorChange implements Action {
  readonly type = CoreActionsTypes.InitialFlowErrorChange;

  constructor(public payload: { error: InitialFlowErrorType }) {}
}

export class GenericErrorLoad implements Action {
  readonly type = CoreActionsTypes.GenericErrorLoad;

  constructor(public payload: { error: GenericError }) {}
}

export class ErrorLoaded implements Action {
  readonly type = CoreActionsTypes.ErrorLoaded;

  constructor(
    public payload: { httpError: HttpErrorResponse; display404?: boolean }
  ) {}
}

export class SecurityCompleted implements Action {
  readonly type = CoreActionsTypes.SecurityCompleted;
}

export class GenericErrorClear implements Action {
  readonly type = CoreActionsTypes.GenericErrorClear;
}

export type CoreActions =
  | InitialFlowErrorChange
  | GenericErrorLoad
  | SecurityCompleted
  | GenericErrorClear
  | ErrorLoaded;
