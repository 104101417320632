import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { PlanItem } from '../models/plan-item.model';

export enum PlanItemActionsTypes {
  PlanItemsLoaded = '[PlanItem service] PlanItems Loaded',
  PlanItemsRequested = '[PlanItem service] PlanItems ed',
  PlanItemAdded = '[New PlanItem Dialog] New PlanItem Added',
  PlanItemUpdated = '[PlanItem Table / PlanItem details view] PlanItem Updated',
  PlanItemDeleted = '[PlanItem Table / PlanItem details view] PlanItem Deleted',
  PlanItemsClearInformation = '[Switch Tenant] Clear all PlanItem information',
  PlanItemsChangeSpinner = '[PlanItem Effect] Change PlanItem Spinner',
}

export class PlanItemsChangeSpinner implements Action {
  readonly type = PlanItemActionsTypes.PlanItemsChangeSpinner;

  constructor(public payload: { loading: boolean }) {}
}
export class PlanItemsRequested implements Action {
  readonly type = PlanItemActionsTypes.PlanItemsRequested;

  constructor(public payload: { tenantId: string; planId: string }) {}
}

export class PlanItemsLoaded implements Action {
  readonly type = PlanItemActionsTypes.PlanItemsLoaded;

  constructor(public payload: { items: PlanItem[] }) {}
}

export class PlanItemAdded implements Action {
  readonly type = PlanItemActionsTypes.PlanItemAdded;

  constructor(public payload: { item: PlanItem }) {}
}

export class PlanItemUpdated implements Action {
  readonly type = PlanItemActionsTypes.PlanItemUpdated;

  constructor(public payload: { item: Update<PlanItem> }) {}
}

export class PlanItemDeleted implements Action {
  readonly type = PlanItemActionsTypes.PlanItemDeleted;

  constructor(public payload: { id: string }) {}
}

export class PlanItemsClearInformation implements Action {
  readonly type = PlanItemActionsTypes.PlanItemsClearInformation;
}

export type PlanItemActions =
  | PlanItemsLoaded
  | PlanItemsRequested
  | PlanItemAdded
  | PlanItemUpdated
  | PlanItemDeleted
  | PlanItemsClearInformation
  | PlanItemsChangeSpinner;
