import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  SidedrawersNetworkActions,
  SidedrawersNetworkActionsTypes,
} from './sidedrawers-network.actions';
import { Network } from '../models/network.model';

export interface NetworkSidedrawersState extends EntityState<Network> {
  gettingSidedrawersNetworks: boolean;
  forcingSidedrawersNetworkUser: boolean;
}

export const networkSidedrawersAdapter: EntityAdapter<Network> =
  createEntityAdapter<Network>({
    selectId: model => model.id,
  });

export const initialNetworkSidedrawers: NetworkSidedrawersState =
  networkSidedrawersAdapter.getInitialState({
    gettingSidedrawersNetworks: false,
    forcingSidedrawersNetworkUser: false,
  });

export function sidedrawersNetworkReducer(
  state: NetworkSidedrawersState = initialNetworkSidedrawers,
  action: SidedrawersNetworkActions
): NetworkSidedrawersState {
  switch (action.type) {
    case SidedrawersNetworkActionsTypes.SidedrawersNetworkLoaded:
      return networkSidedrawersAdapter.upsertMany(
        action.payload.networks,
        state
      );
    case SidedrawersNetworkActionsTypes.SidedrawersNetworkClearInformation:
      return networkSidedrawersAdapter.removeAll(state);
    case SidedrawersNetworkActionsTypes.GettingSidedrawersNetworksChange:
      return {
        ...state,
        gettingSidedrawersNetworks: action.payload.state,
      };
    case SidedrawersNetworkActionsTypes.ForcingSidedrawersNetworkUser:
      return {
        ...state,
        forcingSidedrawersNetworkUser: action.payload.state,
      };
    case SidedrawersNetworkActionsTypes.SidedrawerNetworkRemove:
      return networkSidedrawersAdapter.removeOne(action.payload.id, state);
    case SidedrawersNetworkActionsTypes.SidedrawerNetworkLoaded:
      return networkSidedrawersAdapter.upsertOne(action.payload.network, state);
    default:
      return state;
  }
}

export const { selectAll } = networkSidedrawersAdapter.getSelectors();
