import { AppState } from 'src/app/reducers';
import { SdDialogConfirmModule } from '../sd-dialog-confirm/sd-dialog-confirm.module';
import { NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { SdTableComponent } from './components/sd-table/sd-table.component';
import { SdFormsModule } from '../sd-forms/sd-forms.module';
import { SdButtonsModule } from '../sd-buttons/sd-buttons.module';
import { Store, StoreModule } from '@ngrx/store';
import { featureName, tableReducer } from './store/sd-table.reducer';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { SdSnackBarModule } from '../sd-snack-bar/sd-snack-bar.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { EffectsModule } from '@ngrx/effects';
import { TableEffects } from './store/table.effect';
import { TableDialogEffects } from './store/table-dialog.effect';
import { DictionaryPipeModule } from '../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { DisplayMultipleRowsPipe } from './pipes/display-multiple-rows.pipe';
import { DisplayMultipleRowsHeaderPipe } from './pipes/display-multiple-rows-header.pipe';
import { DisplayMultipleRowsSubHeaderPipe } from './pipes/display-multiple-rows-sub-header.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { SdTableTooltipPipe } from './pipes/sd-table-tooltip.pipe';
import { SdDatePipe } from '../pipes/sd-date.pipe';
import { SdLocaleSelectorA11yModule } from '../sd-locale-selector-a11y/sd-locale-selector-a11y.module';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { dictionarySelector } from 'src/app/dictionary/store/dictionary.selectors';

@NgModule({
  declarations: [
    SdTableComponent,
    DisplayMultipleRowsPipe,
    DisplayMultipleRowsHeaderPipe,
    DisplayMultipleRowsSubHeaderPipe,
    SdTableTooltipPipe,
  ],
  exports: [SdTableComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(featureName, tableReducer),
    EffectsModule.forFeature([TableEffects, TableDialogEffects]),
    SdFormsModule,
    SdButtonsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    DragDropModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSlideToggleModule,
    SdDialogConfirmModule,
    SdSnackBarModule,
    MatButtonModule,
    DictionaryPipeModule,
    SafeHtmlPipe,
    SdDatePipe,
    SdLocaleSelectorA11yModule,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: () => new MatPaginatorIntlCustom(),
    },
  ],
})
export class SdTablesModule {}

export class MatPaginatorIntlCustom extends MatPaginatorIntl {
  private store = inject(Store<AppState>);
  constructor() {
    super();
    this.updateLabelsForTranslate();
  }

  updateLabelsForTranslate() {
    const dictionary = this.store.selectSignal(dictionarySelector)();
    this.itemsPerPageLabel =
      dictionary?.globalparams_itemsperpagelabel ??
      'globalparams_itemsperpagelabel';
    const ofLabel = dictionary?.globalparams_oflabel ?? 'globalparams_of';
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      return `${page * pageSize + 1} - ${
        length < (page + 1) * pageSize ? length : (page + 1) * pageSize
      } ${ofLabel} ${length}`;
    };
  }
}
