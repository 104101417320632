<div
  (click)="onClick()"
  [ngClass]="disabled ? 'disabled' : selected ? 'active' : null"
  class="sd-selectable-item">
  <div class="sd-selectable-item-text">{{ value }}</div>

  <div class="sd-selectable-item-description">{{ description }}</div>

  <div class="sd-selectable-item-check">
    <app-sd-svg
      *ngIf="selected"
      [color]="'var(--primaryColor)'"
      [height]="18"
      [src]="checkIcon"
      [width]="18"></app-sd-svg>
  </div>
</div>
