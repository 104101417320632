import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { AppState } from 'src/app/reducers';
import {
  TableActionsTypes,
  TableColumnsChange,
  TableGetConfigLoaded,
  TableGetConfigRequested,
  TableSaveConfigRequested,
  TableSaveConfigSaved,
  TableSortByDefault,
} from './table.actions';
import { SdTableHelper } from '../helper/sd-table.helper';

@Injectable()
export class TableEffects {
  tableSaveConfigRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TableSaveConfigRequested>(
        TableActionsTypes.TableSaveConfigRequested
      ),
      map(action => {
        SdTableHelper.saveDataCollection(
          action.payload?.data.key,
          action.payload.data
        );

        return new TableSaveConfigSaved();
      })
    );
  });
  tableGetConfigRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TableGetConfigRequested>(
        TableActionsTypes.TableGetConfigRequested
      ),
      map(action => {
        let settingObject = SdTableHelper.getDataFromCollection(
          action.payload?.data.key
        );
        if (
          settingObject &&
          SdTableHelper.checkIfColumnsChanged(
            action.payload.data?.settings?.columnsForEdit,
            settingObject?.settings?.columnsForEdit
          )
        ) {
          settingObject = null;
        }
        if (settingObject?.settings?.sortBy) {
          this.store.dispatch(
            new TableSortByDefault({ sort: settingObject!.settings.sortBy })
          );
        }
        if (!settingObject?.settings?.sortBy) {
          this.store.dispatch(
            new TableSortByDefault({
              sort: action.payload?.data.settings.sortBy,
            })
          );
        }
        if (settingObject?.settings?.columnsForEdit) {
          this.store.dispatch(
            new TableColumnsChange({
              columns: settingObject!.settings.columnsForEdit,
            })
          );
        }
        if (!settingObject?.settings?.columnsForEdit) {
          this.store.dispatch(
            new TableColumnsChange({
              columns: action.payload?.data.settings.columnsForEdit,
            })
          );
        }
        return new TableGetConfigLoaded();
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>
  ) {}
}
