import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sd-check-button',
  templateUrl: './sd-check-button.component.html',
  styleUrls: ['./sd-check-button.component.scss'],
})
export class SdCheckButtonComponent implements OnInit {
  @Input() checked: boolean;
  @Input() value: string;
  @Input() img: string;
  @Input() svg: string;
  @Input() color: string;
  @Input() description: string;

  constructor() {}

  ngOnInit(): void {}
}
