import { Action } from '@ngrx/store';

export enum SdCacheActionsTypes {
  SdCacheElementAdded = '[SdSVG / Others] Sd Cache element Added',
}

export class SdCacheElementAdded implements Action {
  readonly type = SdCacheActionsTypes.SdCacheElementAdded;

  constructor(public payload: { key: string; value: string }) {}
}

export type SdCacheActions = SdCacheElementAdded;
