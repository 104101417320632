import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { dictionarySelector } from '../../dictionary/store/dictionary.selectors';
import { map } from 'rxjs/operators';
import { SfWebMessagesService } from './core/services/sf-web-messages.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';

@Component({
  selector: 'app-root',
  template: `
    <mat-progress-bar
      class="main-progress-bar"
      mode="indeterminate"
      *ngIf="(loadingData$ | async) === true" />
    <div *ngIf="(loadingData$ | async) === false" class="sf-app-root">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      .sf-app-root {
        background-color: var(--appBackground);
        height: 100%;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfAppComponent {
  private readonly service = inject(SfWebMessagesService);
  private readonly store = inject(Store<AppState>);
  loadingData$ = this.store
    .select(dictionarySelector)
    .pipe(map(dictionary => !dictionary));
}
