import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sd-icon-button',
  templateUrl: './sd-icon-button.component.html',
  styleUrls: ['./sd-icon-button.component.scss'],
})
export class SdIconButtonComponent implements OnInit {
  @Input() primary: boolean;
  @Input() disabled: boolean;
  @Input() icon: string;
  @Input() tooltip: string;
  @Input() size = 24;
  @Input() iconSize = 24;
  @Output() btnClicked = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
}
