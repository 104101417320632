import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { dictionaryReducer, featureName } from './store/dictionary.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DictionaryEffects } from './store/dictionary.effects';
import { DictionaryService } from './services/dictionary.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureName, dictionaryReducer),
    EffectsModule.forFeature([DictionaryEffects]),
  ],
  providers: [DictionaryService],
})
export class DictionaryModule {}
