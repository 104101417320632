<button
  (click)="!disabled ? btnClicked.emit(true) : null"
  [disabled]="disabled"
  [matTooltip]="!!tooltip ? tooltip : ''"
  [ngClass]="
    disabled
      ? 'sd-iconEnabled-button disabled'
      : primary
      ? 'sd-iconEnabled-button primary'
      : 'sd-iconEnabled-button secondary'
  "
  [ngStyle]="{ cursor: disabled ? 'default' : 'pointer' }"
  class="sd-icon-button"
  mat-button
  #matTooltip="matTooltip"
  (mouseleave)="!!tooltip ? matTooltip.hide() : ''">
  <mat-icon>{{ icon }}</mat-icon>
</button>
