import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdDialogConfirmComponent } from './sd-dialog-confirm/sd-dialog-confirm.component';
import { MatIconModule } from '@angular/material/icon';
import { SdButtonsModule } from '../sd-buttons/sd-buttons.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DictionaryPipeModule } from '../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { EffectsModule } from '@ngrx/effects';
import { SdDialogConfirmEffects } from './store/sd-dialog-confirm.effects';

@NgModule({
  declarations: [SdDialogConfirmComponent],
  imports: [
    CommonModule,
    EffectsModule.forFeature([SdDialogConfirmEffects]),
    MatIconModule,
    SdButtonsModule,
    MatButtonModule,
    DictionaryPipeModule,
    MatDialogModule,
  ],
  exports: [SdDialogConfirmComponent],
})
export class SdDialogConfirmModule {}
