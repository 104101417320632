import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountState, featureName } from './account.reducer';

export const accountStateSelector =
  createFeatureSelector<AccountState>(featureName);

export const accountSelector = createSelector(
  accountStateSelector,
  state => state.account
);

export const settingsSelector = createSelector(
  accountStateSelector,
  state => state.settings
);

export const accountOpenIdSelector = createSelector(
  accountSelector,
  account => account.openId
);

export const gettingAccountSelector = createSelector(
  accountStateSelector,
  state => state.gettingAccount
);

export const gettingSettingsSelector = createSelector(
  accountStateSelector,
  state => state.gettingSettings
);

export const userCurrencySelector = createSelector(
  settingsSelector,
  settings => settings.currency
);
