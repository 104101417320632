import { Action } from '@ngrx/store';
import { Branding } from '../models/branding.model';
import { Update } from '@ngrx/entity';

export enum BrandListActionsTypes {
  BrandsLoaded = '[Branding service] Brands Loaded',
  BrandsRequested = '[Branding service] Brands Requested',
  BrandAdded = '[New Branding Dialog] New Brand Added',
  BrandUpdated = '[Brand Table / Brand details view] Brand Updated',
  BrandDeleted = '[Brand Table / Brand details view] Brand Deleted',
  BrandsClearInformation = '[Switch Tenant] Clear all Brands information',
}

export class BrandsRequested implements Action {
  readonly type = BrandListActionsTypes.BrandsRequested;

  constructor(public payload: { tenantId: string }) {}
}

export class BrandsLoaded implements Action {
  readonly type = BrandListActionsTypes.BrandsLoaded;

  constructor(public payload: { brands: Branding[] }) {}
}

export class BrandAdded implements Action {
  readonly type = BrandListActionsTypes.BrandAdded;

  constructor(public payload: { branding: Branding }) {}
}

export class BrandUpdated implements Action {
  readonly type = BrandListActionsTypes.BrandUpdated;

  constructor(public payload: { brand: Update<Branding> }) {}
}

export class BrandDeleted implements Action {
  readonly type = BrandListActionsTypes.BrandDeleted;

  constructor(public payload: { id: string }) {}
}

export class BrandsClearInformation implements Action {
  readonly type = BrandListActionsTypes.BrandsClearInformation;
}

export type BrandListActions =
  | BrandsLoaded
  | BrandsRequested
  | BrandAdded
  | BrandUpdated
  | BrandDeleted
  | BrandsClearInformation;
