import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Country } from '../../models/country.model';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
})
export class CountrySelectorComponent implements OnInit, AfterViewChecked {
  @Input() placeholder: string;
  @Input() startValue: string;
  @Input() error: string;
  @Input() required: boolean;
  @Input() controller: UntypedFormControl;
  @Output() countrySelected = new EventEmitter<Country>();
  countries = Country.getAllCountries();
  filteredOptions: Observable<Country[]>;
  currentFlag = '';

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.required) {
      this.controller.setValidators([
        Validators.required,
        this.countryValidator.bind(this),
      ]);
    } else {
      this.controller.setValidators(this.countryValidator.bind(this));
    }
    this.filteredOptions = this.controller.valueChanges.pipe(
      startWith(this.startValue),
      map(value => this.filter(value)),
      tap(countries => {
        if (countries.length === 1) {
          this.countrySelected.emit(countries[0]);
          this.currentFlag =
            '/assets/flags/' + countries[0].countryCode.toLowerCase() + '.svg';
        } else {
          this.currentFlag = '';
        }
      })
    );
    this.controller.setValue(this.startValue);
  }

  filter(value: string): Country[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(option =>
      option.countryName.toLowerCase().includes(filterValue)
    );
  }

  countryValidator(control: UntypedFormControl): { [s: string]: boolean } {
    return !control.value
      ? null
      : this.countries.find(
          country =>
            country.countryName.toLowerCase() === control.value.toLowerCase()
        )
      ? null
      : { invalidCountry: true };
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }
}
