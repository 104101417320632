import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SdRadioGroupInput } from '../../models/sd-radio-group-input.model';

@Component({
  selector: 'app-sd-radio-group',
  templateUrl: './sd-radio-group.component.html',
  styleUrls: ['./sd-radio-group.component.scss'],
})
export class SdRadioGroupComponent implements OnInit, OnChanges {
  currentOption: string;
  @Input() options: SdRadioGroupInput[];
  @Input() startValue: string;
  @Input() filter: string;
  @Input() inlineDescription = false;
  @Output() optionSelected = new EventEmitter<string>();
  @Input() wrap = false;

  ngOnInit(): void {
    if (this.startValue) {
      this.onSelect(this.startValue);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.filter?.currentValue) {
      this.filter = changes.filter.currentValue;
    }
  }

  onSelect(key: string): void {
    this.currentOption = key;
    this.optionSelected.emit(key);
  }

  isVisible(option: SdRadioGroupInput): boolean {
    if (!this.filter || this.filter?.trim()?.length === 0) {
      return true;
    }
    return (
      option.title?.toLowerCase().includes(this.filter?.toLowerCase()) ||
      option.description?.toLowerCase().includes(this.filter?.toLowerCase())
    );
  }

  verifyCheck(key = ''): boolean {
    return this.currentOption === key;
  }
}
