import { ResolveFn } from '@angular/router';
import { AccountService } from '../../../account/services/account.service';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { tokenSelector } from '../../../auth/store/auth.selectors';
import jwtDecode from 'jwt-decode';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { SfAuthActions } from '../core/store/sf-auth.store';
import { of } from 'rxjs';
import { AccountLoaded } from '../../../account/store/account.actions';

export const sfAccountResolver: ResolveFn<boolean> = (
  route,
  state,
  accountService: AccountService = inject(AccountService),
  store: Store<AppState> = inject(Store<AppState>)
) => {
  const token = store.selectSignal(tokenSelector)();
  return accountService.getAccountByOpenId(jwtDecode(token)?.['sub']).pipe(
    catchError((error: HttpErrorResponse) => {
      store.dispatch(
        SfAuthActions.generalErrorModeUpdated({
          generalErrorMode:
            error.status === 404 || error.status === 403 || error.status === 401
              ? '403'
              : 'generic',
        })
      );
      return of(null);
    }),
    tap({
      next: account => {
        store.dispatch(new AccountLoaded({ data: account }));
      },
    }),
    map(() => true)
  );
};
