<div class="content">
  <div
    [ngClass]="
      hasMoreThan8Characters ? 'length-warning ok' : 'length-warning error'
    ">
    {{
      (dictionary$ | async)
        ?.tenantsignupcreateaccount_passwordrulesminimumchars ??
        'Contain at least 8 characters'
    }}
  </div>
  <div [ngClass]="strength >= 3 ? 'fields-warning ok' : 'fields-warning error'">
    {{
      (dictionary$ | async)?.tenantsignupcreateaccount_passwordrulesruleslist ??
        'Contain at least 3 of the following 4 types of characters'
    }}
  </div>
  <div class="items-group">
    <div [ngClass]="hasLowerCase ? 'item ok' : 'item error'">
      <div
        [ngClass]="
          hasLowerCase ? 'circle circle-ok' : 'circle circle-error'
        "></div>
      {{
        (dictionary$ | async)
          ?.tenantsignupcreateaccount_passwordruleslowercase ??
          'Lower case letters'
      }}
    </div>
    <div [ngClass]="hasUpperCase ? 'item ok' : 'item error'">
      <div
        [ngClass]="
          hasUpperCase ? 'circle circle-ok' : 'circle circle-error'
        "></div>
      {{
        (dictionary$ | async)
          ?.tenantsignupcreateaccount_passwordrulesuppercase ??
          'Upper case letters'
      }}
    </div>
    <div [ngClass]="hasNumbers ? 'item ok' : 'item error'">
      <div
        [ngClass]="
          hasNumbers ? 'circle circle-ok' : 'circle circle-error'
        "></div>
      {{
        (dictionary$ | async)?.tenantsignupcreateaccount_passwordrulesnumbers ??
          'Numbers'
      }}
    </div>
    <div [ngClass]="hasSpecialCharacters ? 'item ok' : 'item error'">
      <div
        [ngClass]="
          hasSpecialCharacters ? 'circle circle-ok' : 'circle circle-error'
        "></div>
      {{
        (dictionary$ | async)
          ?.tenantsignupcreateaccount_passwordrulesspecialchars ??
          'Special characters (i.e.:!@#$%^&*)'
      }}
    </div>
  </div>
</div>
