<div class="dashboard-dialog">
  <div class="dashboard-dialog-header">
    <div class="dashboard-dialog-header-title">
      {{
        data.hasNetwork
          ? (dictionary$ | async)?.sdaccessready_alerttitle
          : (dictionary$ | async)?.restrictedsdpermission_alerttitle
      }}
    </div>

    <button
      (click)="onClose()"
      class="dashboard-dialog-header-button"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dashboard-dialog-body">
    <div
      [innerHTML]="
        data.hasNetwork
          ? (dictionary$ | async)?.sdaccessready_alertdescription
          : (dictionary$ | async)?.restrictedsdpermission_alertdescription
      "
      class="dashboard-dialog-body-description generic-dialog-description"></div>
  </div>

  <div class="dashboard-dialog-footer">
    <app-sd-flat-button (buttonClicked)="onClose()" [primary]="false">
      {{ (dictionary$ | async)?.globalparams_close }}
    </app-sd-flat-button>

    <app-sd-flat-button
      (buttonClicked)="onCollaborate()"
      *ngIf="!data.hasNetwork && !!data.sidedrawerId"
      [loading]="spinner"
      [primary]="true">
      Collaborate with this SideDrawer
    </app-sd-flat-button>
  </div>
</div>
