import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureName, SdCacheState } from './sd-cache.reducer';

export const sdCacheStateSelector =
  createFeatureSelector<SdCacheState>(featureName);

export const sdCacheSelector = createSelector(
  sdCacheStateSelector,
  state => state.cache
);

export const sdCacheElementSelector = (payload: { key: string }) =>
  createSelector(sdCacheSelector, cache => cache.get(payload.key));
