import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  SdDialogConfirmActionsTypes,
  SdDialogConfirmOpen,
} from './sd-dialog-confirm.actions';
import { switchMap, tap } from 'rxjs';
import { SdDialogConfirmComponent } from '../sd-dialog-confirm/sd-dialog-confirm.component';

@Injectable()
export class SdDialogConfirmEffects {
  SdDialogConfirmOpen$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SdDialogConfirmOpen>(
          SdDialogConfirmActionsTypes.SdDialogConfirmOpen
        ),
        switchMap(action =>
          this.dialog
            .open(SdDialogConfirmComponent, {
              data: action.payload,
              panelClass: 'no-padding-dialog',
              autoFocus: false,
            })
            .afterClosed()
            .pipe(
              tap(confirm => {
                if (confirm) {
                  action.payload.callback();
                }
              })
            )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly dialog: MatDialog
  ) {}
}
