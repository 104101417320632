import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PhoneEvent } from '../../models/phone-event.model';

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
})
export class PhoneNumberInputComponent implements OnInit, OnDestroy {
  @Input() placeholder: string;
  @Input() extensionPlaceholder: string;
  @Input() extensionError: string;
  @Input() error: string;
  @Input() controller: UntypedFormControl;
  @Input() required: boolean;
  @Output() phoneNumber = new EventEmitter<PhoneEvent>();
  controllerSubscription = new Subscription();
  @Input() extensionController = new UntypedFormControl();
  extension: string;
  @Input() startValue: PhoneEvent;

  constructor() {}

  ngOnInit(): void {
    if (this.required) {
      this.controller.setValidators([
        Validators.required,
        this.phoneValidator.bind(this),
      ]);
    } else {
      this.controller.setValidators(this.phoneValidator.bind(this));
    }
    this.controllerSubscription = this.controller.valueChanges.subscribe(
      value => {
        if (!!value && value?.toString()?.trim()?.length > 0) {
          const phone = value
            ?.toString()
            ?.trim()
            ?.replace(/[^0-9]/g, '');
          this.phoneNumber.emit(
            new PhoneEvent(this.extension, phone, this.extension + phone)
          );
        } else if (!value && this.extensionController.value) {
          this.controller.setErrors({ invalid: true });
        } else {
          this.phoneNumber.emit(null);
        }
      }
    );
    if (this.startValue) {
      this.extension = this.startValue.extension;
      this.controller.setValue(this.startValue.phone);
    }
  }

  ngOnDestroy(): void {
    this.controllerSubscription.unsubscribe();
  }

  phoneValidator(control: UntypedFormControl): { [s: string]: boolean } {
    if (!control.value) {
      return null;
    } else if (control.value === '') {
      return null;
      // tslint:disable-next-line:max-line-length
      // } else if (/^[\s()+-]*([0-9][\s()+-]*){6,20}(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i.test(`(${this.extension})` + control.value)) {
    } else if (
      !!this.extension &&
      this.extension.length > 0 &&
      !!control.value &&
      control.value.trim().length > 0
    ) {
      return null;
    } else {
      return { phoneFormat: true };
    }
  }

  onExtensionSelected(extension: string): void {
    if (extension) {
      this.extension = extension;
      this.controller.setValue(this.controller.value);
    } else {
      this.extension = null;
      this.controller.setValue(this.controller.value);
    }
  }
}
