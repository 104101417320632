import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-sd-radio-group-custom-value-input',
  templateUrl: './sd-radio-group-custom-value-input.component.html',
  styleUrls: ['./sd-radio-group-custom-value-input.component.scss'],
})
export class SdRadioGroupCustomValueInputComponent implements OnInit {
  controller: UntypedFormControl = new UntypedFormControl();

  constructor() {}

  ngOnInit(): void {}
}
