import { Action } from '@ngrx/store';
import { SdTableColumn } from '../models/sd-table-column.model';
import { SdTableSettings } from '../models/sd-table-settings.model';
import { SdTableSortEvent } from '../models/sd-table-sort-event.model';

export enum TableActionsTypes {
  LoadTableData = '[SD Table Component] Load Table information',
  ClearTableData = '[SD Table Component] Clear Table information',
  TableSortByDefault = '[SD Table Component] Set Table default sorting',
  TableColumnsChange = '[SD Table Component] Table Columns Change',
  TableFilterChange = '[SD Table Component] Table Filter Updates',
  TablePageChange = '[SD Table Component] Table Page Change',
  TableElementsPerPagesChange = '[SD Table Component] Table Elements Per Page Change',
  TableElementsPerPagesOptionsChange = '[SD Table Component] Table Elements Per Page Options Change',
  TableSortChange = '[SD Table Component] Table Sort Change',
  TableSaveConfigRequested = '[SD Table Component] Table Save Config Requested',
  TableSaveConfigSaved = '[SD Table Component] Table Save Config Saved',
  TableGetConfigRequested = '[SD Table Component] Table Get Config Requested',
  TableGetConfigLoaded = '[SD Table Component] Table Get Config Loaded',
}

export class LoadTableData implements Action {
  readonly type = TableActionsTypes.LoadTableData;

  constructor(public payload: { data }) {}
}

export class ClearTableData implements Action {
  readonly type = TableActionsTypes.ClearTableData;
}

export class TableColumnsChange implements Action {
  readonly type = TableActionsTypes.TableColumnsChange;

  constructor(public payload: { columns: SdTableColumn[] }) {}
}

export class TableSortByDefault implements Action {
  readonly type = TableActionsTypes.TableSortByDefault;

  constructor(public payload: { sort: SdTableSortEvent }) {}
}

export class TableFilterChange implements Action {
  readonly type = TableActionsTypes.TableFilterChange;

  constructor(public payload: { filter: string }) {}
}

export class TablePageChange implements Action {
  readonly type = TableActionsTypes.TablePageChange;

  constructor(public payload: { page: number }) {}
}

export class TableElementsPerPagesChange implements Action {
  readonly type = TableActionsTypes.TableElementsPerPagesChange;

  constructor(public payload: { elementsPerPage: number }) {}
}

export class TableSortChange implements Action {
  readonly type = TableActionsTypes.TableSortChange;

  constructor(public payload: { event: SdTableSortEvent }) {}
}

export class TableSaveConfigRequested implements Action {
  readonly type = TableActionsTypes.TableSaveConfigRequested;

  constructor(
    public payload: {
      data: SdTableSettings;
    }
  ) {}
}

export class TableSaveConfigSaved implements Action {
  readonly type = TableActionsTypes.TableSaveConfigSaved;
}

export class TableGetConfigRequested implements Action {
  readonly type = TableActionsTypes.TableGetConfigRequested;

  constructor(
    public payload: {
      data: SdTableSettings;
    }
  ) {}
}

export class TableGetConfigLoaded implements Action {
  readonly type = TableActionsTypes.TableGetConfigLoaded;
}

export class TableElementsPerPagesOptionsChange implements Action {
  readonly type = TableActionsTypes.TableElementsPerPagesOptionsChange;

  constructor(
    public payload: {
      defaultAmount: boolean;
    }
  ) {}
}

export type TableActions =
  | LoadTableData
  | ClearTableData
  | TableColumnsChange
  | TableSortByDefault
  | TableFilterChange
  | TablePageChange
  | TableElementsPerPagesChange
  | TableSortChange
  | TableSaveConfigRequested
  | TableSaveConfigSaved
  | TableGetConfigRequested
  | TableGetConfigLoaded
  | TableElementsPerPagesOptionsChange;
