import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SfRoutesEnum } from './routes/sf-routes.enum';
import { CoreRoutes } from '../../core/routes/core.routes';
import { HomeRoutes } from '../../home/routes/home.routes';
import { PlansRoutes } from '../../plans/routes/plans.routes';
import { AuthGuard } from '../../auth/guards/auth.guard';
import { sfTenantResolver } from './resolvers/sf-tenant.resolver';
import { sfAccountResolver } from './resolvers/sf-account.resolver';

const routes: Routes = [
  {
    path: 'auth/authorize',
    loadComponent: () =>
      import(
        './auth/views/sf-authenticate-view/sf-authenticate-view.component'
      ).then(m => m.SfAuthenticateViewComponent),
  },
  {
    path: SfRoutesEnum.forbidden,
    loadComponent: () =>
      import('./core/views/sf-forbidden-view/sf-forbidden-view.component').then(
        m => m.SfForbiddenViewComponent
      ),
  },
  {
    path: SfRoutesEnum.error,
    loadComponent: () =>
      import(
        './core/views/sf-generic-error-view/sf-generic-error-view.component'
      ).then(m => m.SfGenericErrorViewComponent),
  },
  {
    path: SfRoutesEnum.notFound,
    loadComponent: () =>
      import('./core/views/sf-not-found-view/sf-not-found-view.component').then(
        m => m.SfNotFoundViewComponent
      ),
  },
  {
    path: `${CoreRoutes.root}/${HomeRoutes.root}/:tenantId/${PlansRoutes.root}/${PlansRoutes.plan}/:planId`,
    loadChildren: () =>
      import('./plans/sf-plans.module').then(m => m.SfPlansModule),
    resolve: {
      tenant: sfTenantResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: `${CoreRoutes.root}/${HomeRoutes.root}/:tenantId/${SfRoutesEnum.blastDelivery}`,
    loadChildren: () =>
      import('./sf-blast-delivery/sf-blast-delivery.module').then(
        m => m.SfBlastDeliveryModule
      ),
    resolve: {
      tenant: sfTenantResolver,
      account: sfAccountResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: `${CoreRoutes.root}/${HomeRoutes.root}/:tenantId/${SfRoutesEnum.template}/:templateId`,
    loadChildren: () =>
      import('./sf-template/sf-template.module').then(m => m.SfTemplateModule),
    resolve: {
      tenant: sfTenantResolver,
      account: sfAccountResolver,
    },
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class SfAppRoutingModule {}
