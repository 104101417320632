import { Action } from '@ngrx/store';
import { SampleData } from '../models/sample-data.model';
import { Update } from '@ngrx/entity';
import { ProcessStep } from '../../tenants/models/process-step.model';
import { PlanItemSampleData } from '../models/plans-sample-data.model';
import { License } from '../../subscriptions/models/license.model';

export enum BrandSamplesActionsTypes {
  SampleDataJsonRequested = '[] Sample Data Json Requested',
  SampleDataJsonLoaded = '[BrandSamplesEffects] Sample Data Json Loaded',
  SampleDataInitProcess = '[BrandSamplesEffects] Sample Data Init Process',
  SampleDataLicensesRequested = '[BrandSamplesEffects] Sample Data Licenses Requested',
  SampleDataLicensesLoaded = '[BrandSamplesEffects] Sample Data Licenses Loaded',
  SampleDataCreateSideDrawersRequested = '[BrandSamplesEffects] Sample Data Create SideDrawers Requested',
  SampleDataSideDrawerIdLoaded = '[BrandSamplesEffects] Sample Data SideDrawer Id Loaded',
  SampleDataPlanIdLoaded = '[BrandSamplesEffects] Sample Data Plan Id Loaded',
  SampleDataPlanIdRemoved = '[BrandSamplesEffects] Sample Data Plan Id Removed',
  SampleDataPlansOperationsLoaded = '[BrandSamplesEffects] Sample Data Plans Operations Loaded',
  GettingSampleDataChange = '[BrandSamplesEffects] Getting Sample Data Change',
  SampleDataProcessStepUpdated = '[BrandSamplesEffects] Sample Data Process Step Updated',
  SampleDataResetProcess = '[] Sample Data Reset Process',
  SampleDataCreatePlansRequested = '[] Sample Data Create Plans Requested',
  SampleDataCreateItemsRequested = '[] Sample Data Create Items Requested',
  SampleDataCheckSideDrawersPermissionsRequested = '[] Sample Data Check SideDrawers Permissions Requested',
  SampleDataAssignPlansRequested = '[] Sample Data Assign Plans Requested',
  SampleDataAssignCollaboratorsRequested = '[] Sample Data Assign Collaborators Requested',
  SampleDataCreateVcardRequested = '[BrandSamplesEffects] Sample Data Create VCard Requested',
}

export class SampleDataJsonRequested implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataJsonRequested;
}

export class SampleDataJsonLoaded implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataJsonLoaded;

  constructor(public payload: { data: SampleData }) {}
}

export class SampleDataInitProcess implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataInitProcess;
  constructor(
    public payload: {
      brandCode: string;
      subscriptionId: string;
      createSD: boolean;
      createPlan: boolean;
      deliverInfoRequest: boolean;
      addCollaborators: boolean;
      sampleVCard: boolean;
    }
  ) {}
}

export class GettingSampleDataChange implements Action {
  readonly type = BrandSamplesActionsTypes.GettingSampleDataChange;

  constructor(public payload: { state: boolean }) {}
}

export class SampleDataProcessStepUpdated implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataProcessStepUpdated;

  constructor(public payload: { step: Update<ProcessStep> }) {}
}

export class SampleDataCreateSideDrawersRequested implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataCreateSideDrawersRequested;
}

export class SampleDataLicensesRequested implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataLicensesRequested;
}

export class SampleDataLicensesLoaded implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataLicensesLoaded;

  constructor(public payload: { licenses: License[] }) {}
}

export class SampleDataSideDrawerIdLoaded implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataSideDrawerIdLoaded;

  constructor(public payload: { id: string; key: number }) {}
}

export class SampleDataPlanIdRemoved implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataPlanIdRemoved;

  constructor(public payload: { id: string }) {}
}

export class SampleDataPlanIdLoaded implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataPlanIdLoaded;

  constructor(public payload: { id: string; items: PlanItemSampleData[] }) {}
}

export class SampleDataPlansOperationsLoaded implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataPlansOperationsLoaded;

  constructor(
    public payload: { operations: { sideDrawerId: string; planId: string }[] }
  ) {}
}

export class SampleDataResetProcess implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataResetProcess;
}

export class SampleDataCreatePlansRequested implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataCreatePlansRequested;
}

export class SampleDataCreateItemsRequested implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataCreateItemsRequested;
}

export class SampleDataCheckSideDrawersPermissionsRequested implements Action {
  readonly type =
    BrandSamplesActionsTypes.SampleDataCheckSideDrawersPermissionsRequested;
}

export class SampleDataAssignPlansRequested implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataAssignPlansRequested;
}

export class SampleDataAssignCollaboratorsRequested implements Action {
  readonly type =
    BrandSamplesActionsTypes.SampleDataAssignCollaboratorsRequested;
}

export class SampleDataCreateVcardRequested implements Action {
  readonly type = BrandSamplesActionsTypes.SampleDataCreateVcardRequested;
}

export type BrandSamplesActions =
  | SampleDataJsonRequested
  | SampleDataJsonLoaded
  | SampleDataInitProcess
  | SampleDataCreateSideDrawersRequested
  | SampleDataLicensesRequested
  | SampleDataLicensesLoaded
  | SampleDataSideDrawerIdLoaded
  | SampleDataPlanIdLoaded
  | SampleDataPlanIdRemoved
  | SampleDataPlansOperationsLoaded
  | GettingSampleDataChange
  | SampleDataProcessStepUpdated
  | SampleDataResetProcess
  | SampleDataCreatePlansRequested
  | SampleDataCreateItemsRequested
  | SampleDataCheckSideDrawersPermissionsRequested
  | SampleDataAssignPlansRequested
  | SampleDataAssignCollaboratorsRequested
  | SampleDataCreateVcardRequested;
