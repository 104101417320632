import { Injectable } from '@angular/core';
import { FilesService } from '../../files/services/files.service';
import { RecordsService } from '../../records/services/records.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  bulkUploadQueueSelector,
  bulkUploadStateSelector,
} from '../store/bulk-upload.selectors';
import { catchError, mergeMap, take, tap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { BulkUploadQueue } from '../models/bulk-upload-queue.model';
import {
  BulkUploadClearInformation,
  BulkUploadErrorAddItem,
  BulkUploadQueueUpdateItem,
  BulkUploadStatusChange,
} from '../store/bulk-upload.actions';
import { BulkUploadStatus } from '../models/bulk-upload-status.model';
import { BulkUploadQueueStatus } from '../models/bulk-upload-queue-status.model';
import { BulkUploadQueueType } from '../models/bulk-upload-queue-type.model';
import { BulkUploadQueueOperation } from '../models/bulk-upload-queue-operation.model';
import { SideDrawersService } from '../../sidedrawers/services/side-drawers.service';
import { NetworksService } from '../../networks/services/networks.service';
import { SidedrawerRoles } from '../../core/roles/sidedrawer.roles';
import { FileProgressResponse } from '../../files/models/file-progress-response.model';

@Injectable()
export class BulkUploadService {
  queue$: Observable<BulkUploadQueue[]>;
  subscription: Subscription;

  constructor(
    private readonly filesService: FilesService,
    private readonly recordsService: RecordsService,
    private readonly sidedrawersService: SideDrawersService,
    private readonly networksService: NetworksService,
    private readonly store: Store<AppState>
  ) {}

  initBulkUpload(sidedrawerId: string): void {
    this.subscription = new Subscription();
    this.queue$ = this.store.pipe(select(bulkUploadQueueSelector), take(1));
    this.store.dispatch(
      new BulkUploadStatusChange({ status: BulkUploadStatus.processing })
    );
    this.bulkOperation(sidedrawerId);
  }

  bulkOperation(sidedrawerId: string): void {
    this.queue$
      .pipe(
        tap(queue => {
          const index = queue.findIndex(
            item => item.status === BulkUploadQueueStatus.pending
          );
          if (index < 0) {
            this.completeOperation();
            return;
          }
          const queueItem = queue[index];
          this.store.dispatch(
            new BulkUploadQueueUpdateItem({
              item: { ...queueItem, status: BulkUploadQueueStatus.progress },
            })
          );
          let operation;
          if (queueItem.queueType === BulkUploadQueueType.record) {
            if (queueItem.operation === BulkUploadQueueOperation.create) {
              operation = this.recordsService
                .createRecord(sidedrawerId, queueItem.record)
                .pipe(
                  tap(
                    (recordId: string) => {
                      this.store.dispatch(
                        new BulkUploadQueueUpdateItem({
                          item: {
                            ...queueItem,
                            status: BulkUploadQueueStatus.complete,
                            record: { ...queueItem.record, id: recordId },
                          },
                        })
                      );
                      this.bulkOperation(sidedrawerId);
                    },
                    () => {
                      this.store.dispatch(
                        new BulkUploadErrorAddItem({
                          error: queueItem.record.name,
                        })
                      );
                      this.store.dispatch(
                        new BulkUploadQueueUpdateItem({
                          item: {
                            ...queueItem,
                            status: BulkUploadQueueStatus.fail,
                          },
                        })
                      );
                      this.bulkOperation(sidedrawerId);
                    }
                  )
                );
            }
            if (queueItem.operation === BulkUploadQueueOperation.update) {
              operation = this.recordsService
                .updateRecord(
                  sidedrawerId,
                  queueItem.record.id,
                  queueItem.record
                )
                .pipe(
                  tap(
                    () => {
                      this.store.dispatch(
                        new BulkUploadQueueUpdateItem({
                          item: {
                            ...queueItem,
                            status: BulkUploadQueueStatus.complete,
                          },
                        })
                      );
                      this.bulkOperation(sidedrawerId);
                    },
                    () => {
                      this.store.dispatch(
                        new BulkUploadErrorAddItem({
                          error: queueItem.record.name,
                        })
                      );
                      this.store.dispatch(
                        new BulkUploadQueueUpdateItem({
                          item: {
                            ...queueItem,
                            status: BulkUploadQueueStatus.fail,
                          },
                        })
                      );
                      this.bulkOperation(sidedrawerId);
                    }
                  )
                );
            }
            if (queueItem.operation === BulkUploadQueueOperation.delete) {
              operation = this.recordsService
                .deleteRecord(sidedrawerId, queueItem.record.id)
                .pipe(
                  tap(
                    () => {
                      this.store.dispatch(
                        new BulkUploadQueueUpdateItem({
                          item: {
                            ...queueItem,
                            status: BulkUploadQueueStatus.complete,
                          },
                        })
                      );
                      this.bulkOperation(sidedrawerId);
                    },
                    () => {
                      this.store.dispatch(
                        new BulkUploadErrorAddItem({
                          error: queueItem.record.name,
                        })
                      );
                      this.store.dispatch(
                        new BulkUploadQueueUpdateItem({
                          item: {
                            ...queueItem,
                            status: BulkUploadQueueStatus.fail,
                          },
                        })
                      );
                      this.bulkOperation(sidedrawerId);
                    }
                  )
                );
            }
          }
          if (queueItem.queueType === BulkUploadQueueType.file) {
            const recordId = queueItem.record?.id
              ? queueItem.record.id
              : queue.find(item => item.id === queueItem.id.split('-')[0])
                  ?.record.id;
            if (queueItem.operation === BulkUploadQueueOperation.create) {
              operation = this.filesService
                .uploadFile(
                  queueItem.fileUpload,
                  queueItem.fileUpload.fileType,
                  sidedrawerId,
                  recordId
                )
                .pipe(
                  tap({
                    next: (response: FileProgressResponse) => {
                      this.store.dispatch(
                        new BulkUploadQueueUpdateItem({
                          item: {
                            ...queueItem,
                            uploadProgress:
                              response.status === 'complete'
                                ? 100
                                : response.message
                                ? response.message
                                : 0,
                            status:
                              response.status === 'complete'
                                ? BulkUploadQueueStatus.complete
                                : BulkUploadQueueStatus.progress,
                          },
                        })
                      );
                      if (response.status === 'complete') {
                        this.bulkOperation(sidedrawerId);
                      }
                    },
                    error: () => {
                      this.store.dispatch(
                        new BulkUploadErrorAddItem({
                          error: queueItem.fileUpload.file.name,
                        })
                      );
                      this.store.dispatch(
                        new BulkUploadQueueUpdateItem({
                          item: {
                            ...queueItem,
                            status: BulkUploadQueueStatus.fail,
                          },
                        })
                      );
                      this.bulkOperation(sidedrawerId);
                    },
                  })
                );
            }
            if (queueItem.operation === BulkUploadQueueOperation.delete) {
              const queueIt =
              {
                ...queueItem,
                fileItem: {
                  ...queueItem.fileItem,
                  sideDrawerId: queueItem?.fileItem?.sideDrawerId ?? sidedrawerId,
                  recordId: recordId
                }
              };
              operation = this.filesService.deleteFile(queueIt.fileItem).pipe(
                tap(
                  () => {
                    this.store.dispatch(
                      new BulkUploadQueueUpdateItem({
                        item: {
                          ...queueItem,
                          status: BulkUploadQueueStatus.complete,
                        },
                      })
                    );
                    this.bulkOperation(sidedrawerId);
                  },
                  () => {
                    this.store.dispatch(
                      new BulkUploadErrorAddItem({
                        error:
                          queueItem.fileItem.fileName +
                          FilesService.getFileItemExtension(queueItem.fileItem),
                      })
                    );
                    this.store.dispatch(
                      new BulkUploadQueueUpdateItem({
                        item: {
                          ...queueItem,
                          status: BulkUploadQueueStatus.fail,
                        },
                      })
                    );
                    this.bulkOperation(sidedrawerId);
                  }
                )
              );
            }
          }
          if (queueItem.queueType === BulkUploadQueueType.sidedrawer) {
            if (queueItem.operation === BulkUploadQueueOperation.apply) {
              const request = this.sidedrawersService
                .applyTemplate(
                  queueItem.sidedrawer.id,
                  sidedrawerId,
                  true,
                  true,
                  true
                )
                .pipe(
                  tap(
                    () => {
                      this.store.dispatch(
                        new BulkUploadQueueUpdateItem({
                          item: {
                            ...queueItem,
                            status: BulkUploadQueueStatus.complete,
                          },
                        })
                      );
                      this.bulkOperation(sidedrawerId);
                    },
                    () => {
                      this.store.dispatch(
                        new BulkUploadErrorAddItem({
                          error: queueItem.sidedrawer.id,
                        })
                      );
                      this.store.dispatch(
                        new BulkUploadQueueUpdateItem({
                          item: {
                            ...queueItem,
                            status: BulkUploadQueueStatus.fail,
                          },
                        })
                      );
                      this.bulkOperation(sidedrawerId);
                    }
                  )
                );
              operation = this.networksService
                .getSideDrawerNetworks(queueItem.sidedrawer.id)
                .pipe(
                  catchError(() => {
                    return of([]);
                  }),
                  mergeMap(networks =>
                    networks.some(
                      network =>
                        network.sidedrawerRole === SidedrawerRoles.owner ||
                        network.sidedrawerRole === SidedrawerRoles.editor
                    )
                      ? request
                      : this.networksService
                          .forceUser(queueItem.sidedrawer.id)
                          .pipe(
                            catchError(() => {
                              this.store.dispatch(
                                new BulkUploadErrorAddItem({
                                  error: queueItem.sidedrawer.id,
                                })
                              );
                              this.store.dispatch(
                                new BulkUploadQueueUpdateItem({
                                  item: {
                                    ...queueItem,
                                    status: BulkUploadQueueStatus.fail,
                                  },
                                })
                              );
                              return of(null);
                            }),
                            mergeMap(response =>
                              response ? request : of(null)
                            )
                          )
                  )
                );
            }
          }
          this.subscription.add(operation.subscribe());
        })
      )
      .subscribe();
  }

  completeOperation(): void {
    this.subscription.unsubscribe();
    this.store
      .pipe(
        select(bulkUploadStateSelector),
        take(1),
        tap(state => {
          this.store.dispatch(
            new BulkUploadStatusChange({
              status:
                state.errors.length === 0
                  ? BulkUploadStatus.complete
                  : state.errors.length === state.queue.length
                  ? BulkUploadStatus.fails
                  : BulkUploadStatus.completeWithErrors,
            })
          );
        })
      )
      .subscribe();
  }

  cancelBulkUpload(): void {
    this.subscription?.unsubscribe();
    this.store.dispatch(
      new BulkUploadStatusChange({ status: BulkUploadStatus.canceled })
    );
  }

  resetBulkUpload(): void {
    this.store.dispatch(new BulkUploadClearInformation());
  }
}
