import { Action } from '@ngrx/store';
import { SdTableColumn } from '../models/sd-table-column.model';
import { SdTableSettings } from '../models/sd-table-settings.model';
import { SdTableSortEvent } from '../models/sd-table-sort-event.model';

export enum TableDialogActionsTypes {
  LoadTableDialogData = '[SD TableDialog Component] Load TableDialog information',
  ClearTableDialogData = '[SD TableDialog Component] Clear TableDialog information',
  TableDialogSortByDefault = '[SD TableDialog Component] Set TableDialog default sorting',
  TableDialogColumnsChange = '[SD TableDialog Component] TableDialog Columns Change',
  TableDialogFilterChange = '[SD TableDialog Component] TableDialog Filter Updates',
  TableDialogPageChange = '[SD TableDialog Component] TableDialog Page Change',
  TableDialogElementsPerPagesChange = '[SD TableDialog Component] TableDialog Elements Per Page Change',
  TableDialogElementsPerPagesOptionsChange = '[SD Table Component] TableDialog Elements Per Page Options Change',
  TableDialogSortChange = '[SD TableDialog Component] TableDialog Sort Change',
  TableDialogSaveConfigRequested = '[SD TableDialog Component] TableDialog Save Config Requested',
  TableDialogSaveConfigSaved = '[SD TableDialog Component] TableDialog Save Config Saved',
  TableDialogGetConfigRequested = '[SD TableDialog Component] TableDialog Get Config Requested',
  TableDialogGetConfigLoaded = '[SD TableDialog Component] TableDialog Get Config Loaded',
}

export class LoadTableDialogData implements Action {
  readonly type = TableDialogActionsTypes.LoadTableDialogData;

  constructor(public payload: { data }) {}
}

export class ClearTableDialogData implements Action {
  readonly type = TableDialogActionsTypes.ClearTableDialogData;
}

export class TableDialogColumnsChange implements Action {
  readonly type = TableDialogActionsTypes.TableDialogColumnsChange;

  constructor(public payload: { columns: SdTableColumn[] }) {}
}

export class TableDialogSortByDefault implements Action {
  readonly type = TableDialogActionsTypes.TableDialogSortByDefault;

  constructor(public payload: { sort: SdTableSortEvent }) {}
}

export class TableDialogFilterChange implements Action {
  readonly type = TableDialogActionsTypes.TableDialogFilterChange;

  constructor(public payload: { filter: string }) {}
}

export class TableDialogPageChange implements Action {
  readonly type = TableDialogActionsTypes.TableDialogPageChange;

  constructor(public payload: { page: number }) {}
}

export class TableDialogElementsPerPagesChange implements Action {
  readonly type = TableDialogActionsTypes.TableDialogElementsPerPagesChange;

  constructor(public payload: { elementsPerPage: number }) {}
}

export class TableDialogSortChange implements Action {
  readonly type = TableDialogActionsTypes.TableDialogSortChange;

  constructor(public payload: { event: SdTableSortEvent }) {}
}

export class TableDialogSaveConfigRequested implements Action {
  readonly type = TableDialogActionsTypes.TableDialogSaveConfigRequested;

  constructor(
    public payload: {
      data: SdTableSettings;
    }
  ) {}
}

export class TableDialogSaveConfigSaved implements Action {
  readonly type = TableDialogActionsTypes.TableDialogSaveConfigSaved;
}

export class TableDialogGetConfigRequested implements Action {
  readonly type = TableDialogActionsTypes.TableDialogGetConfigRequested;

  constructor(
    public payload: {
      data: SdTableSettings;
    }
  ) {}
}

export class TableDialogGetConfigLoaded implements Action {
  readonly type = TableDialogActionsTypes.TableDialogGetConfigLoaded;
}
export class TableDialogElementsPerPagesOptionsChange implements Action {
  readonly type =
    TableDialogActionsTypes.TableDialogElementsPerPagesOptionsChange;

  constructor(
    public payload: {
      defaultAmount: boolean;
    }
  ) {}
}

export type TableDialogActions =
  | LoadTableDialogData
  | ClearTableDialogData
  | TableDialogColumnsChange
  | TableDialogSortByDefault
  | TableDialogFilterChange
  | TableDialogPageChange
  | TableDialogElementsPerPagesChange
  | TableDialogSortChange
  | TableDialogSaveConfigRequested
  | TableDialogSaveConfigSaved
  | TableDialogGetConfigRequested
  | TableDialogGetConfigLoaded
  | TableDialogElementsPerPagesOptionsChange;
