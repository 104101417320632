import { createSelector } from '@ngrx/store';
import { brandStateSelector } from './brand.selectors';
import { selectAll, selectTotal } from './brand-samples.reducer';
import { ProcessStepStatus } from '../../tenants/models/process-step-status.enum';

export const brandSamplesStateSelectors = createSelector(
  brandStateSelector,
  state => state.brandSample
);

export const brandSampleDataInfoSelector = createSelector(
  brandSamplesStateSelectors,
  state => state.sampleDataInfo
);

export const brandSampleDataInfoSideDrawersSelector = createSelector(
  brandSampleDataInfoSelector,
  json => {
    return json?.sidedrawers;
  }
);

export const brandSampleDataInfoVcardSelector = createSelector(
  brandSampleDataInfoSelector,
  json => {
    return json?.vcard;
  }
);

export const brandSampleDataInfoCollaboratorsSelector = createSelector(
  brandSampleDataInfoSelector,
  json => json?.collaborators
);

export const brandSampleDataInfoPlansSelector = createSelector(
  brandSampleDataInfoSelector,
  json => json?.plans
);

export const brandSampleGettingSampleDataSelector = createSelector(
  brandSamplesStateSelectors,
  state => state.gettingSampleData
);

export const brandSampleBrandCodeSelector = createSelector(
  brandSamplesStateSelectors,
  state => state.brandCode
);

export const brandSampleSubscriptionIdSelector = createSelector(
  brandSamplesStateSelectors,
  state => state.subscriptionId
);

export const brandSampleLicensesSelector = createSelector(
  brandSamplesStateSelectors,
  state => state.licenses
);

export const brandSampleSideDrawersIdsSelector = createSelector(
  brandSamplesStateSelectors,
  state => state.sideDrawersIds
);

export const brandSamplePlanIdsSelector = createSelector(
  brandSamplesStateSelectors,
  state => state.planIds
);

export const brandSampleAssignPlansOperationsSelector = createSelector(
  brandSamplesStateSelectors,
  state => state.assignPlansOperations
);

export const brandSampleStepListSelector = createSelector(
  brandSamplesStateSelectors,
  selectAll
);

export const brandSampleTotalStepSelector = createSelector(
  brandSamplesStateSelectors,
  selectTotal
);

export const brandSampleCompletedStepsSelector = createSelector(
  brandSampleStepListSelector,
  steps => steps.filter(step => step.status === ProcessStepStatus.success)
);

export const brandSampleProgressSelector = createSelector(
  brandSampleTotalStepSelector,
  brandSampleCompletedStepsSelector,
  (total, completed) => Math.round((completed?.length / total) * 100)
);

export const brandSampleCompleteSuccessfullySelector = createSelector(
  brandSampleStepListSelector,
  steps => !steps.some(step => step.status !== ProcessStepStatus.success)
);

export const brandSampleSampleDataConfigurationSelector = createSelector(
  brandSamplesStateSelectors,
  state => state.sampleDataConfiguration
);
