<div class="dashboard-dialog">
  <div class="dashboard-dialog-header">
    <div class="dashboard-dialog-header-title">
      {{ (dictionary$ | async)?.sdactioncollaborate_alerttitle }}
    </div>

    <button
      (click)="onClose()"
      class="dashboard-dialog-header-button"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dashboard-dialog-body">
    <div class="dashboard-dialog-body-description">
      {{ (dictionary$ | async)?.sdactioncollaborate_alertdescription }}
    </div>
  </div>

  <div class="dashboard-dialog-footer">
    <app-sd-flat-button (buttonClicked)="onClose()" [primary]="false">
      {{ (dictionary$ | async)?.globalparams_cancel }}
    </app-sd-flat-button>

    <app-sd-flat-button
      (buttonClicked)="onConfirm()"
      [loading]="spinner"
      [primary]="true">
      {{ (dictionary$ | async)?.globalparams_confirm }}
    </app-sd-flat-button>
  </div>
</div>
