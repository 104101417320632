import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, featureName } from './auth.reducer';
import { AuthUser } from '../models/user.model';

export const authStateSelector = createFeatureSelector<AuthState>(featureName);

export const tokenSelector = createSelector(authStateSelector, state =>
  !!state.token && state.token.length > 0 ? state.token : null
);

export const authUserSelector = createSelector(authStateSelector, state =>
  state.user ? state.user : null
);

export const authRolesSelector = createSelector(
  authUserSelector,
  (user: AuthUser) => user?.roles ?? []
);

export const authUserDataBaseRegionSelector = createSelector(
  authUserSelector,
  user => user?.region
);

export const tenantRolesSelector = createSelector(
  authStateSelector,
  state => state.tenantRoles
);

export const tenantRoleSelectorByRoleLabel = (payload: { label: string }) =>
  createSelector(tenantRolesSelector, tenantRoles =>
    tenantRoles.find(tenantRole => tenantRole.label === payload.label)
  );
