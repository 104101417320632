import { InitialFlowErrorType } from '../models/initial-flow-error-type.model';
import { CoreActions, CoreActionsTypes } from './core.actions';
import { GenericError } from '../models/generic-error.model';

export const featureName = 'core';

export interface CoreState {
  initialFlowError: InitialFlowErrorType;
  securityCompleted: boolean;
  genericError: GenericError;
}

export const initialCoreState: CoreState = {
  initialFlowError: null,
  securityCompleted: false,
  genericError: null,
};

export function coreReducer(
  state: CoreState = initialCoreState,
  action: CoreActions
): CoreState {
  switch (action.type) {
    case CoreActionsTypes.InitialFlowErrorChange:
      return {
        ...state,
        initialFlowError: action.payload.error,
      };
    case CoreActionsTypes.SecurityCompleted:
      return {
        ...state,
        securityCompleted: true,
      };
    case CoreActionsTypes.GenericErrorLoad:
      return {
        ...state,
        genericError: action.payload.error,
      };
    case CoreActionsTypes.GenericErrorClear:
      return {
        ...state,
        genericError: null,
      };
    default:
      return state;
  }
}
