<mat-radio-group
  *ngIf="!wrap"
  [(ngModel)]="currentOption"
  class="sd-radio-group">
  <div *ngFor="let option of options" class="sd-radio-button">
    <ng-container *ngIf="isVisible(option)">
      <mat-radio-button
        (change)="onSelect($event.value)"
        *ngIf="option.title"
        [checked]="verifyCheck(option.key)"
        [value]="option.key"
        class="radio-button">
      </mat-radio-button>
      <div
        *ngIf="option.title"
        [ngClass]="inlineDescription ? 'inline' : null"
        class="label">
        <div
          (click)="onSelect(option.key)"
          *ngIf="!!option.title"
          class="title">
          {{ option.title }}
        </div>
        <div
          (click)="onSelect(option.key)"
          *ngIf="!!option.description"
          class="description">
          ({{ option.description }})
        </div>
      </div>
    </ng-container>
  </div>
</mat-radio-group>

<mat-radio-group
  *ngIf="wrap"
  [(ngModel)]="currentOption"
  [ngClass]="options?.length > 7 ? 'sd-radio-group-wrap-grid' : null"
  class="sd-radio-group-wrap">
  <div
    (click)="onSelect(option.key)"
    *ngFor="let option of options"
    class="sd-radio-button-wrap">
    <mat-radio-button
      (change)="onSelect($event.value)"
      [checked]="verifyCheck(option.key)"
      [value]="option.key"
      class="sd-radio-button-wrap-radio-button">
    </mat-radio-button>
    <div class="sd-radio-button-wrap-label">
      {{ option.title }}
    </div>

    <div *ngIf="option.allowAnswer" class="sd-radio-button-wrap-allowAnswer">
      <app-sd-radio-group-custom-value-input>
      </app-sd-radio-group-custom-value-input>
    </div>
  </div>
</mat-radio-group>
