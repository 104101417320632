import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-sd-password',
  templateUrl: './sd-password.component.html',
  styleUrls: ['./sd-password.component.scss'],
})
export class SdPasswordComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() error: string;
  @Input() controller: UntypedFormControl;
  @Input() placeholder: string;
  @Input() suffixText: string;
  @Input() suffix: string;
  @Input() suffixIsClickable: boolean;
  @Input() suffixIconHasPrimaryColor: boolean;
  @Input() focused: boolean;
  @Input() tooltip: string;
  @Input() readonly = false;
  @Input() displayStrengthBox = false;
  @Output() suffixClicked = new EventEmitter<string>();
  displaySuffix = false;
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;
  type = 'password';
  suffixPassword = 'visibility';
  strengthBox;

  constructor() {}

  ngOnInit(): void {
    this.displaySuffix = !!this.suffix && this.suffix.length > 0;
    if (this.focused) {
      this.inputElement.nativeElement.focus();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.focused && changes.focused.currentValue) {
      this.inputElement.nativeElement.focus();
    }
  }

  ngAfterViewInit(): void {
    this.strengthBox = document.querySelector('.strengthBox');
  }

  onSuffix(): void {
    if (this.suffixIsClickable) {
      this.suffixClicked.emit(this.controller?.value ?? '');
    }
  }

  onTogglePassword() {
    if (this.readonly) {
      return;
    }
    if (this.type === 'password') {
      this.type = 'text';
      this.suffixPassword = 'visibility_off';
    } else {
      this.type = 'password';
      this.suffixPassword = 'visibility';
    }
  }
}
