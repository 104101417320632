import {
  brandSamplesReducer,
  BrandSamplesState,
} from './brand-samples.reducer';
import { brandListReducer, BrandListState } from './brand-list.reducer';
import { ActionReducerMap } from '@ngrx/store';

export const featureName = 'brands';

export interface BrandState {
  brandList: BrandListState;
  brandSample: BrandSamplesState;
}

export const brandReducer: ActionReducerMap<BrandState> = {
  brandList: brandListReducer,
  brandSample: brandSamplesReducer,
};
