import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable()
export class DragDropOverlaySnackBarContainer extends OverlayContainer {
  appOverlayContainerClass = 'drag-drop-overlay-container-snackbar';

  // Append the OverlayContainer to the custom HTML element
  public appendToCustomWrapper(wrapperElement: HTMLElement): void {
    if (wrapperElement === null) {
      return;
    }

    if (!this._containerElement) {
      super._createContainer();
    }

    // add a custom css class to the 'cdk-overlay-container' for styling purposes
    this._containerElement.classList.add(this.appOverlayContainerClass);

    
    // attach the overlay-container to our custom wrapper
    const parent = this._containerElement;
    if (!parent.contains(wrapperElement)) {
      wrapperElement.appendChild(this._containerElement);
    } else {
      console.log('Cannot append child');
    }
  }

  public removeFromCustomWrapper(): void {
    if (!this._containerElement) {
      return;
    }
    this._containerElement.classList.remove(this.appOverlayContainerClass);
    this._document.body.appendChild(this._containerElement);
  }
}
