import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayMultipleRowsSubHeader',
})
export class DisplayMultipleRowsSubHeaderPipe implements PipeTransform {
  transform(value: unknown): string {
    return value?.toString()?.split('$/$')?.[1];
  }
}
