import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NetworksService } from '../services/networks.service';
import {
  GettingSidedrawersNetworksChange,
  SidedrawerNetworkLoaded,
  SidedrawerNetworkRequested,
  SidedrawersNetworkActionsTypes,
  SidedrawersNetworkLoaded,
} from './sidedrawers-network.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { forkJoin, of } from 'rxjs';
import {
  currentRoleSelector,
  currentTenantSelector,
  goToAppURLSelector,
  tenantCustomDomainAvailableSelector,
  userHasBrandRoleSelector,
} from '../../tenants/store/tenant.selectors';
import { accountSelector } from '../../account/store/account.selector';
import { Network } from '../models/network.model';
import { SidedrawerRoles } from '../../core/roles/sidedrawer.roles';
import {
  SideDrawerCheckNetworkAndCallback,
  SideDrawerCollaborateDialog,
  SideDrawerForceNetworkAndCallback,
  SideDrawerListActionsTypes,
  SideDrawerRedirect,
  SideDrawerTransferDialog,
  SideDrawerTransferDialogResult,
} from '../../sidedrawers/store/sidedrawer-list.actions';
import { environment } from '../../../environments/environment';
import { NetworkWarningDialogComponent } from '../shared/network-warning-dialog/components/network-warning-dialog/network-warning-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SideDrawerNetworksDialogComponent } from '../shared/side-drawer-networks-dialog/components/side-drawer-networks-dialog/side-drawer-networks-dialog.component';
import { GenericErrorLoad } from '../../core/store/core.actions';

@Injectable()
export class NetworkEffects {
  sidedrawerNetworkRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SidedrawerNetworkRequested>(
        SidedrawersNetworkActionsTypes.SidedrawerNetworkRequested
      ),
      tap(() =>
        this.store.dispatch(
          new GettingSidedrawersNetworksChange({ state: true })
        )
      ),
      mergeMap(action =>
        this.networksService
          .getSideDrawerNetworks(action.payload.sidedrawerId)
          .pipe(
            map(networks =>
              networks.find(
                network =>
                  network?.sidedrawerRole === SidedrawerRoles.owner ||
                  network?.sidedrawerRole === SidedrawerRoles.editor
              )
            ),
            map(network => new SidedrawerNetworkLoaded({ network })),
            tap(() =>
              this.store.dispatch(
                new GettingSidedrawersNetworksChange({ state: false })
              )
            )
          )
      )
    )
  );

  sideDrawerRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SideDrawerRedirect>(
          SideDrawerListActionsTypes.SideDrawerRedirect
        ),
        tap(() =>
          this.store.dispatch(
            new GettingSidedrawersNetworksChange({ state: true })
          )
        ),
        mergeMap(action =>
          this.networksService
            .getSideDrawerNetworks(action.payload.sidedrawerId)
            .pipe(
              catchError(() => of([])),
              tap(networks =>
                networks?.length > 0
                  ? this.store.dispatch(
                      new SidedrawersNetworkLoaded({ networks })
                    )
                  : null
              ),
              map(networks =>
                networks.find(
                  network =>
                    network?.sidedrawerRole === SidedrawerRoles.owner ||
                    network?.sidedrawerRole === SidedrawerRoles.editor
                )
              ),
              tap(network => {
                const callback = () => {
                  forkJoin([
                    this.store.pipe(
                      select(tenantCustomDomainAvailableSelector),
                      take(1)
                    ),
                    this.store.pipe(select(goToAppURLSelector), take(1)),
                  ])
                    .pipe(
                      tap(([available, goToAppURL]) => {
                        const url = `${
                          available ? goToAppURL : environment.myUrl
                        }/core/home/${action.payload.sidedrawerId}/${
                          action.payload.url
                        }`;
                        window.open(url, '_blank');
                      })
                    )
                    .subscribe();
                };
                if (network) {
                  callback();
                  this.store.dispatch(
                    new GettingSidedrawersNetworksChange({ state: false })
                  );
                  return;
                }
                this.dialog
                  .open(NetworkWarningDialogComponent, {
                    data: {
                      hasNetwork: false,
                      sidedrawerId: action.payload.sidedrawerId,
                      callback,
                    },
                    autoFocus: false,
                    panelClass: 'no-padding-dialog',
                  })
                  .afterClosed()
                  .subscribe(() =>
                    this.store.dispatch(
                      new GettingSidedrawersNetworksChange({ state: false })
                    )
                  );
              })
            )
        )
      ),
    { dispatch: false }
  );

  sideDrawerCheckNetworkAndCallback$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SideDrawerCheckNetworkAndCallback>(
          SideDrawerListActionsTypes.SideDrawerCheckNetworkAndCallback
        ),
        tap(() =>
          this.store.dispatch(
            new GettingSidedrawersNetworksChange({ state: true })
          )
        ),
        mergeMap(action =>
          this.networksService
            .getSideDrawerNetworks(action.payload.sidedrawerId)
            .pipe(
              catchError(() => of([])),
              tap(networks =>
                networks?.length > 0
                  ? this.store.dispatch(
                      new SidedrawersNetworkLoaded({ networks })
                    )
                  : null
              ),
              map(networks =>
                networks.find(
                  network =>
                    network?.sidedrawerRole === SidedrawerRoles.owner ||
                    network?.sidedrawerRole === SidedrawerRoles.editor
                )
              ),
              tap(network => {
                if (network) {
                  action.payload.callback();
                  this.store.dispatch(
                    new GettingSidedrawersNetworksChange({ state: false })
                  );
                  return;
                }
                this.dialog
                  .open(NetworkWarningDialogComponent, {
                    data: {
                      hasNetwork: false,
                      sidedrawerId: action.payload.sidedrawerId,
                      callback: action.payload.callback,
                    },
                    autoFocus: false,
                    panelClass: 'no-padding-dialog',
                  })
                  .afterClosed()
                  .subscribe(() =>
                    this.store.dispatch(
                      new GettingSidedrawersNetworksChange({ state: false })
                    )
                  );
              })
            )
        )
      ),
    { dispatch: false }
  );

  sideDrawerForceNetworkAndCallback$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SideDrawerForceNetworkAndCallback>(
          SideDrawerListActionsTypes.SideDrawerForceNetworkAndCallback
        ),
        tap(() =>
          this.store.dispatch(
            new GettingSidedrawersNetworksChange({ state: true })
          )
        ),
        mergeMap(action =>
          forkJoin([
            this.store.pipe(select(currentTenantSelector), take(1)),
            this.store.pipe(select(accountSelector), take(1)),
            this.store.pipe(select(currentRoleSelector), take(1)),
            this.store.pipe(select(userHasBrandRoleSelector), take(1)),
          ]).pipe(
            mergeMap(([tenant, account, role, isBrandRole]) => {
              const body: Network = {
                sidedrawerRole: SidedrawerRoles.editor,
                contributor: {
                  email: account.username,
                  firstName: account.firstName,
                  lastName: account.lastName,
                  openId: account.openId,
                },
                relation: {
                  profession: 'sponsor',
                },
              };
              if (isBrandRole) {
                body[`contributor.brandCode`] = role.brandCode;
              }
              return this.networksService
                .createSideDrawerNetworkV2FromTenantApi(
                  tenant.id,
                  action.payload.sidedrawerId,
                  SidedrawerRoles.editor,
                  { openId: account.openId },
                  {
                    profession: 'sponsor',
                  },
                  tenant.region
                )
                .pipe(
                  tap({
                    next: response => {
                      if (!!response?.id || !!response?._id) {
                        const newNetwork: Network = {
                          id: response?.id ? response?.id : response?._id,
                          sidedrawer: action.payload.sidedrawerId,
                          contributor: {
                            ...body.contributor,
                            openId: account.openId,
                          },
                          relation: body.relation,
                          sidedrawerRole: SidedrawerRoles.editor,
                        };
                        this.store.dispatch(
                          new SidedrawerNetworkLoaded({ network: newNetwork })
                        );
                      }
                      action.payload.callback();
                      this.store.dispatch(
                        new GettingSidedrawersNetworksChange({ state: false })
                      );
                    },
                    error: error => {
                      this.store.dispatch(
                        new GettingSidedrawersNetworksChange({ state: false })
                      );
                      this.store.dispatch(
                        new GenericErrorLoad({
                          error: {
                            httpError: error,
                            display404: true,
                            display403: true,
                          },
                        })
                      );
                    },
                  })
                );
            })
          )
        )
      ),
    { dispatch: false }
  );

  sideDrawerCollaborateDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SideDrawerCollaborateDialog>(
          SideDrawerListActionsTypes.SideDrawerCollaborateDialog
        ),
        tap(() =>
          this.store.dispatch(
            new GettingSidedrawersNetworksChange({ state: true })
          )
        ),
        mergeMap(action =>
          this.networksService
            .getSideDrawerNetworks(action.payload.sidedrawerId)
            .pipe(
              catchError(() => of([])),
              tap(networks =>
                networks?.length > 0
                  ? this.store.dispatch(
                      new SidedrawersNetworkLoaded({ networks })
                    )
                  : null
              ),
              map(networks =>
                networks.find(
                  network =>
                    network?.sidedrawerRole === SidedrawerRoles.owner ||
                    network?.sidedrawerRole === SidedrawerRoles.editor
                )
              ),
              tap(network => {
                this.store.dispatch(
                  new GettingSidedrawersNetworksChange({ state: false })
                );
                if (network) {
                  this.dialog
                    .open(NetworkWarningDialogComponent, {
                      data: { hasNetwork: true },
                      autoFocus: false,
                      panelClass: 'no-padding-dialog',
                    })
                    .afterClosed()
                    .subscribe();
                  return;
                }
                this.dialog
                  .open(SideDrawerNetworksDialogComponent, {
                    data: { sidedrawerId: action.payload.sidedrawerId },
                    autoFocus: false,
                    panelClass: 'no-padding-dialog',
                  })
                  .afterClosed()
                  .subscribe();
              })
            )
        )
      ),
    { dispatch: false }
  );

  sideDrawerTransferDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SideDrawerTransferDialog>(
          SideDrawerListActionsTypes.SideDrawerTransferDialog
        ),
        tap(() =>
          this.store.dispatch(
            new GettingSidedrawersNetworksChange({ state: true })
          )
        ),
        switchMap(action =>
          this.networksService.transferSideDrawerOwnership(
            action.payload.sidedrawerId,
            action.payload.dto
          )
        ),
        tap({
          next: () => {
            this.store.dispatch(
              new GettingSidedrawersNetworksChange({ state: false })
            );
            this.store.dispatch(
              new SideDrawerTransferDialogResult({ success: true })
            );
          },
          error: () => {
            this.store.dispatch(
              new GettingSidedrawersNetworksChange({ state: false })
            );
            this.store.dispatch(
              new SideDrawerTransferDialogResult({ success: false })
            );
          },
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly networksService: NetworksService,
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog
  ) {}
}
