import { createSelector } from '@ngrx/store';
import { networkStateSelector } from './network.selectors';
import { selectAll } from './sidedrawers-network.reducer';
import { SidedrawerRoles } from '../../core/roles/sidedrawer.roles';

export const sidedrawersNetworkStateSelector = createSelector(
  networkStateSelector,
  state => state.sidedrawersNetwork
);

export const sidedrawersNetworkListSelector = createSelector(
  sidedrawersNetworkStateSelector,
  selectAll
);

export const sidedrawersNetworksBySideDrawerIdSelector = (payload: {
  sidedrawerId: string;
}) =>
  createSelector(sidedrawersNetworkListSelector, networks =>
    networks.filter(network => network.sidedrawer === payload.sidedrawerId)
  );

export const userHasPermissionsOnSideDrawerBySideDrawerIdSelector = (payload: {
  sidedrawerId: string;
}) =>
  createSelector(
    sidedrawersNetworksBySideDrawerIdSelector({
      sidedrawerId: payload.sidedrawerId,
    }),
    networks => {
      if (!networks || networks.length === 0) {
        return false;
      }
      return networks.some(
        network =>
          network.sidedrawerRole === SidedrawerRoles.owner ||
          network.sidedrawerRole === SidedrawerRoles.editor ||
          network.sidedrawerRole === SidedrawerRoles.viewer
      );
    }
  );

export const userHasPermissionsToEditSideDrawerBySideDrawerIdSelector =
  (payload: { sidedrawerId: string }) =>
    createSelector(
      sidedrawersNetworksBySideDrawerIdSelector({
        sidedrawerId: payload.sidedrawerId,
      }),
      networks => {
        if (!networks || networks.length === 0) {
          return false;
        }
        return networks.some(
          network =>
            network.sidedrawerRole === SidedrawerRoles.owner ||
            network.sidedrawerRole === SidedrawerRoles.editor
        );
      }
    );

export const sidedrawersNetworkBySideDrawerIdSelectorWithOwnerOrEditorRole =
  (payload: { sidedrawerId: string }) =>
    createSelector(
      sidedrawersNetworksBySideDrawerIdSelector({
        sidedrawerId: payload.sidedrawerId,
      }),
      networks => {
        return networks.find(
          network =>
            network.sidedrawerRole === SidedrawerRoles.owner ||
            network.sidedrawerRole === SidedrawerRoles.editor
        );
      }
    );

export const gettingSideDrawersNetworksSelector = createSelector(
  sidedrawersNetworkStateSelector,
  state => state.gettingSidedrawersNetworks
);
