import { BulkUploadStatus } from '../models/bulk-upload-status.model';
import { BulkUploadQueue } from '../models/bulk-upload-queue.model';
import {
  BulkUploadActions,
  BulkUploadActionsTypes,
} from './bulk-upload.actions';
import { BulkUploadItem } from '../models/bulk-upload-item.model';
import { RecordType } from '../../record-types/models/record-type.model';
import { SubscriptionFeatures } from '../../subscriptions/models/subscription-features.model';

export const featureName = 'bulk-upload';

export interface BulkUploadState {
  status: BulkUploadStatus;
  queue: BulkUploadQueue[];
  errors: string[];
  items: BulkUploadItem[];
  recordTypes: RecordType[];
  subscriptionFeatures: SubscriptionFeatures;
}

export const initialBulkUploadState: BulkUploadState = {
  status: BulkUploadStatus.waitingForConfirmation,
  queue: [],
  errors: [],
  items: [],
  recordTypes: [],
  subscriptionFeatures: null,
};

export function bulkUploadReducer(
  state: BulkUploadState = initialBulkUploadState,
  action: BulkUploadActions
): BulkUploadState {
  switch (action.type) {
    case BulkUploadActionsTypes.BulkUploadStatusChange:
      return {
        ...state,
        status: action.payload.status,
      };
    case BulkUploadActionsTypes.BulkUploadQueueAddItem:
      return {
        ...state,
        queue: [...state.queue, action.payload.item],
      };
    case BulkUploadActionsTypes.BulkUploadQueueDeleteItem:
      return {
        ...state,
        queue: [...state.queue].filter(qItem => qItem.id !== action.payload.id),
      };
    case BulkUploadActionsTypes.BulkUploadQueueUpdateItem:
      const newQueue = [...state.queue];
      const itemIndex = newQueue.findIndex(
        item => item.id === action.payload.item.id
      );
      newQueue[itemIndex] = action.payload.item;
      return {
        ...state,
        queue: [...newQueue],
      };
    case BulkUploadActionsTypes.BulkUploadErrorAddItem:
      return {
        ...state,
        errors: [...state.errors, action.payload.error],
      };
    case BulkUploadActionsTypes.BulkUploadClearInformation:
      return initialBulkUploadState;
    case BulkUploadActionsTypes.BulkUploadRecordTypesLoaded:
      return {
        ...state,
        recordTypes: action.payload.recordTypes,
      };
    case BulkUploadActionsTypes.BulkUploadItemAdd:
      return {
        ...state,
        items: [...state.items, action.payload.item],
      };
    case BulkUploadActionsTypes.BulkUploadItemDelete:
      return {
        ...state,
        items: [...state.items].filter(item => item.id !== action.payload.id),
      };
    case BulkUploadActionsTypes.BulkUploadSubscriptionFeaturesLoaded:
      return {
        ...state,
        subscriptionFeatures: action.payload.features,
      };
    default:
      return state;
  }
}
