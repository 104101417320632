import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { HttpClient } from '@angular/common/http';
import { Record } from '../models/record.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RecordsService {
  private recordsApi = environment.recordsApi;

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store<AppState>,
    private readonly http: HttpClient
  ) {}

  getRecordsList(
    sidedrawerId: string,
    localeDefault: string
  ): Observable<Record[]> {
    return this.http.get<Record[]>(
      this.recordsApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/records?locale=${localeDefault}`,
      { headers: this.authService.getHeaders() }
    );
  }

  deleteRecord(sidedrawerId: string, recordId: string): Observable<boolean> {
    return this.http
      .delete(
        this.recordsApi +
          `sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}`,
        { headers: this.authService.getHeaders() }
      )
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  createRecord(sidedrawerId: string, record: Record): Observable<string> {
    return this.http
      .post<{ id: string }>(
        this.recordsApi + `sidedrawer/sidedrawer-id/${sidedrawerId}/records`,
        { ...record },
        { headers: this.authService.getHeaders() }
      )
      .pipe(
        map(response => {
          return response?.id;
        })
      );
  }

  updateRecord(
    sidedrawerId: string,
    recordId: string,
    record: Record
  ): Observable<boolean> {
    return this.http
      .put<{ id: string }>(
        this.recordsApi +
          `sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}`,
        {
          description: '',
          ...record,
        },
        { headers: this.authService.getHeaders() }
      )
      .pipe(
        map(() => {
          return true;
        })
      );
  }
}
