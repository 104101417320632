import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { RecordType } from 'src/app/record-types/models/record-type.model';

export enum TilesActionsTypes {
  TilesRequested = '[Tiles Effect] List of Tiles Requested',
  TilesLoaded = '[Record Types Service] List of Tiles Loaded',
  TilesChangeSpinner = '[Tiles Effect] Change Tiles Spinner',
  TileAdded = '[Record Types Service] Tile Added',
  TileUpdated = '[Record Types Service] Tile Updated',
  TileClearInformation = '[Tiles List View Component]Tile Clear Information',
}

export class TilesRequested implements Action {
  readonly type = TilesActionsTypes.TilesRequested;
}

export class TilesLoaded implements Action {
  readonly type = TilesActionsTypes.TilesLoaded;

  constructor(public payload: { tiles: RecordType[] }) {}
}

export class TilesChangeSpinner implements Action {
  readonly type = TilesActionsTypes.TilesChangeSpinner;

  constructor(public payload: { state: boolean }) {}
}

export class TileAdded implements Action {
  readonly type = TilesActionsTypes.TileAdded;

  constructor(public payload: { tile: RecordType }) {}
}

export class TileUpdated implements Action {
  readonly type = TilesActionsTypes.TileUpdated;

  constructor(public payload: { tile: Update<RecordType> }) {}
}

export class TileClearInformation implements Action {
  readonly type = TilesActionsTypes.TileClearInformation;
}

export type TilesActions =
  | TilesLoaded
  | TilesLoaded
  | TilesChangeSpinner
  | TileAdded
  | TileUpdated
  | TileClearInformation;
