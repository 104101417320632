import { Action } from '@ngrx/store';
import { SdDialogConfirmData } from '../models/sd-dialog-confirm-data.model';

export enum SdDialogConfirmActionsTypes {
  SdDialogConfirmOpen = '[] Sd Dialog Confirm Open requested',
}

export class SdDialogConfirmOpen implements Action {
  readonly type = SdDialogConfirmActionsTypes.SdDialogConfirmOpen;

  constructor(public payload: SdDialogConfirmData) {}
}

export type SdDialogConfirmActions = SdDialogConfirmOpen;
