import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, takeWhile } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { AppState } from 'src/app/reducers';
import { environment } from 'src/environments/environment';
import { FileHistory } from '../models/file-history.model';
import { FileItem } from '../models/file-item.model';
import { FileType } from '../models/file-type.model';
import { map, startWith } from 'rxjs/operators';
import { FileUpload } from '../models/file-upload.model';
import * as SdSdk from '@sidedrawer/sdk';
import { FileUploadOptions, FileUploadParams } from '@sidedrawer/sdk';
import { tokenSelector } from '../../auth/store/auth.selectors';
import { KeyValue } from '@angular/common';
import { FileProgressResponse } from '../models/file-progress-response.model';
import { UtilsHelper } from 'src/app/core/helpers/utils.helper';

@Injectable()
export class FilesService {
  private gatewayApi = environment.gatewayApi;
  private integrationApi = environment.integrationApi;

  constructor(
    private readonly store: Store<AppState>,
    private readonly http: HttpClient,
    private readonly authService: AuthService
  ) {}

  static blobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName);
  }

  static generateEnvelopeId(): string {
    const envelopeId = [];
    const randomArray = window.crypto.getRandomValues(new Uint32Array(3));
    randomArray.forEach(randomNumber => {
      envelopeId.push(randomNumber.toString(16));
    });
    return envelopeId.join('-');
  }

  static extensionMatch(extension: string, extensions: string[]): boolean {
    let match = false;
    const extensionToCompare = extension.replace('.', '').toLowerCase().trim();
    extensions.forEach(ext => {
      const formattedExtension = ext.replace('.', '').toLowerCase().trim();
      if (formattedExtension === extensionToCompare) {
        match = true;
      }
    });
    return match;
  }

  public static generateFileName(name: string, hasExtension = true): string {
    let formattedName = '';
    if (hasExtension) {
      const nameAux = name?.split('.');
      nameAux?.splice(-1, 1);
      formattedName = nameAux.join('.');
    } else {
      formattedName = name;
    }
    formattedName = formattedName.split(' ').join('_');
    formattedName = formattedName.replace(/[^a-zA-Z0-9._%-]/g, '_');
    return formattedName.replace(/%/g, '_');
  }

  static sortFiles(list: FileHistory[]): FileHistory[] {
    try {
      if (!!list && list.length > 0) {
        const sortedList = [...list];
        return sortedList.sort((a, b) =>
          DateHelper.compareDates(b.files[0].updatedAt, a.files[0].updatedAt)
        );
      } else {
        return list;
      }
    } catch (e) {
      console.error(e);
      return list;
    }
  }

  static generateFileHistoryList(list: FileHistory[]): {
    fileHistoryList: FileHistory[];
    cloudFilesList: FileHistory[];
    cloudFolderList: FileHistory[];
  } {
    const fileHistoryList = [];
    const cloudFilesList = [];
    const cloudFolderList = [];
    list.forEach(item => {
      if (!!item.cloudStorageFolder && !!item.cloudStorageFolder.driveId) {
        cloudFolderList.push(item);
      }
      if (!!item.files && item.files.length > 0) {
        let filesIndex = 0;
        item.files.forEach(file => {
          if (!!file.cloudStorageFile && !!file.cloudStorageFile.driveId) {
            cloudFilesList.push(item);
          }
          const newFileHistory = { ...item };
          newFileHistory.files = [file];
          newFileHistory.correlationId = item.correlationId + '-' + filesIndex;
          fileHistoryList.push(newFileHistory);
          filesIndex = filesIndex + 1;
        });
      }
    });
    return {
      fileHistoryList: FilesService.sortFiles(fileHistoryList),
      cloudFilesList: FilesService.sortFiles(cloudFilesList),
      cloudFolderList: FilesService.sortFiles(cloudFolderList),
    };
  }

  static calculateProgress(loaded: number): number {
    const emulatedFileSize = 200 * 1024 * 1024;
    const progress = (loaded / emulatedFileSize) * 100;
    return progress >= 90 ? 90 : progress;
  }

  static generateFileNameWithExtension(fileHistory: FileHistory): string {
    if (fileHistory.fileType === FileType.cloud) {
      return fileHistory.files[0].fileName;
    }
    const extension =
      fileHistory.files[0].fileUrl.split('.')[
        fileHistory.files[0].fileUrl.split('.').length - 1
      ];
    return fileHistory.uploadTitle
      .toLowerCase()
      .includes(extension.toLowerCase())
      ? fileHistory.uploadTitle.substring(0, 120)
      : `${fileHistory.uploadTitle.substring(
          0,
          120
        )}.${extension.toLowerCase()}`;
  }

  static getFileItemExtension(fileItem: FileItem): string {
    if (fileItem.fileExtension) {
      return fileItem.fileExtension;
    }
    const aux = fileItem?.fileUrl?.split('.');
    return aux.length > 0 ? '.' + aux[aux.length - 1] : '';
  }

  static getExtensionFromUrl(url: string): string {
    const aux = url.split('.');
    return aux.length > 0 ? '.' + aux[aux.length - 1] : '';
  }

  getRecordFilesRequest(
    sidedrawerId: string,
    recordId: string
  ): Observable<FileHistory[]> {
    return this.http.get<FileHistory[]>(
      this.gatewayApi +
        `record-files/sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}/record-files`,
      { headers: this.authService.getHeaders() }
    );
  }

  deleteFile(fileItem: FileItem): Observable<boolean> {
    const fileURL = fileItem?.fileUrl?.includes('/api/v1/record-files')
    ? fileItem?.fileUrl?.replace('/api/v1/', this.gatewayApi)
    : fileItem?.fileUrl?.replace('/api/v1/', this.gatewayApi + 'record-files/');
    if (fileItem?.fileToken) {
      return this.http
        .delete(
          `${UtilsHelper.apiVersion(
            this.gatewayApi,
            2
          )}record-files/sidedrawer/sidedrawer-id/${
            fileItem?.sideDrawerId
          }/records/record-id/${fileItem?.recordId}/record-files/${
            fileItem?.fileToken
          }`,
          { headers: this.authService.getHeaders() }
        )
        .pipe(
          map(() => {
            return true;
          })
        );
    }
    return this.http
      .delete(fileURL, { headers: this.authService.getHeaders() })
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  downloadFile(
    url: string
  ): Observable<string | { status: string; loaded?: number; file?: Blob }> {
    return this.http
      .get<Blob>(url, {
        headers: this.authService.getHeaders(),
        responseType: 'blob' as 'json',
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.DownloadProgress:
              return { status: 'progress', loaded: event.loaded };
            case HttpEventType.Response:
              return { status: 'complete', file: event.body };
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }

  uploadFile(
    fileUpload: FileUpload,
    type: FileType,
    sideDrawerId: string,
    recordId: string,
    externalKeys?: KeyValue<string, string>[]
  ): Observable<FileProgressResponse> {
    const progressSubscriber$ = new BehaviorSubject<number>(0);
    const sdk = this.generateSdSdk();
    const params: FileUploadParams & Partial<FileUploadOptions> = {
      sidedrawerId: sideDrawerId,
      recordId: recordId,
      file: fileUpload.file,
      fileName: FilesService.generateFileName(fileUpload.uploadTitle, false),
      fileExtension: encodeURIComponent(
        fileUpload.file.name.split('.')[
          fileUpload.file.name.split('.').length - 1
        ]
      ),
      uploadTitle: fileUpload.uploadTitle,
      fileType: type as 'image' | 'document' | 'cloud',
      progressSubscriber$,
      maxRetries: 3,
      maxConcurrency: 3,
    };
    if (externalKeys) {
      params.externalKeys = externalKeys;
    }
    if (fileUpload.envelopeId) {
      params.envelopeId = fileUpload.envelopeId;
    }
    if (fileUpload.correlationId) {
      params.correlationId = fileUpload.correlationId;
    }
    if (fileUpload.displayType) {
      params.displayType = fileUpload.displayType;
    }
    return combineLatest([
      sdk.files.upload(params).pipe(startWith(null)),
      progressSubscriber$,
    ]).pipe(
      takeWhile(([response]) => response === null, true),
      map(([response, progress]) => {
        if (!response) {
          return new FileProgressResponse('progress', Math.round(progress));
        }
        // @ts-ignore
        return new FileProgressResponse('complete', 100, {
          ...response,
          id: response['_id'] || response['id'],
          sideDrawerId,
          recordId,
          fileType: response.fileType as FileType,
        });
      })
    );
  }

  private generateSdSdk() {
    return new SdSdk.SideDrawer({
      accessToken: this.store.selectSignal(tokenSelector)(),
      baseUrl: environment.gatewayApi.replace('/api/v1/', ''),
    });
  }
}
