import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '../services/auth.service';
import {
  catchError,
  debounceTime,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  combineLatest,
  EMPTY,
  from,
  fromEvent,
  of,
  retry,
  Subscription,
  timer,
} from 'rxjs';
import {
  AuthActionsTypes,
  AuthorizeUser,
  AuthUserUpdate,
  DisplayInactiveTimeWarning,
  LogOut,
  StartInactivityTime,
  StartRefreshTokenTime,
  TokenUpdate,
} from './auth.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { AuthUser } from '../models/user.model';
import { environment } from '../../../environments/environment';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InactivityTimeDialogComponent } from '../shared/inactivity-time-dialog/components/inactivity-time-dialog/inactivity-time-dialog.component';
import { CoreRoutes } from '../../core/routes/core.routes';
import { RoutesHelper } from '../../core/helpers/routes.helper';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {
  protected readonly actions$ = inject(Actions);
  protected readonly authService = inject(AuthService);
  protected readonly store = inject(Store<AppState>);
  protected readonly router = inject(Router);
  protected readonly dialog = inject(MatDialog);

  authorizeUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AuthorizeUser>(AuthActionsTypes.AuthorizeUser),
        switchMap(() => from(this.authService.initCredentials())),
        catchError(() => {
          const queryParams = RoutesHelper.getParams(false);
          this.router.navigate(
            [`${CoreRoutes.root}/${CoreRoutes.error}/true`],
            { queryParams }
          );
          return of(EMPTY);
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<LogOut>(AuthActionsTypes.Logout),
        tap(() => {
          this.authService.logOut();
        })
      );
    },
    { dispatch: false }
  );
  tokenUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TokenUpdate>(AuthActionsTypes.TokenUpdate),
      switchMap(() => from(this.authService.getAuth0User())),
      map(user => new AuthUserUpdate({ user: AuthUser.fromAuth0(user) }))
    )
  );
  displayInactiveTimeWarning = createEffect(() =>
    this.actions$.pipe(
      ofType<DisplayInactiveTimeWarning>(
        AuthActionsTypes.DisplayInactiveTimeWarning
      ),
      switchMap(() =>
        this.dialog
          .open(InactivityTimeDialogComponent, {
            autoFocus: false,
            closeOnNavigation: false,
            disableClose: true,
            panelClass: 'no-padding-dialog',
          })
          .afterClosed()
      ),
      map(() => new StartInactivityTime())
    )
  );
  startRefreshTokenTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType<StartRefreshTokenTime>(AuthActionsTypes.StartRefreshTokenTime),
      debounceTime(1000 * environment.refreshTokenTime),
      switchMap(() =>
        from(this.authService.getTokenSilently()).pipe(
          retry({
            count: 10,
            delay: (_, retryCount) => timer(retryCount * 1000),
          })
        )
      ),
      map(token => {
        this.store.dispatch(new TokenUpdate({ token }));
        return new StartRefreshTokenTime();
      }),
      catchError(error => {
        console.error({
          error,
          message: 'getTokenSilently fails on auth effect',
        });
        return of(new LogOut());
      })
    )
  );
  private inactivitySubscription: Subscription;
  private inactivityTime$ = combineLatest([
    fromEvent(document, 'click').pipe(startWith(null)),
    fromEvent(document, 'wheel').pipe(startWith(null)),
    fromEvent(document, 'scroll').pipe(startWith(null)),
    fromEvent(document, 'keyup').pipe(startWith(null)),
    fromEvent(document, 'touchend').pipe(startWith(null)),
    fromEvent(document, 'touchmove').pipe(startWith(null)),
  ]).pipe(
    debounceTime(1000 * environment.inactivityTime),
    map(() => {
      this.store.dispatch(new DisplayInactiveTimeWarning());
      this.inactivitySubscription.unsubscribe();
    })
  );
  startInactivityTime$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<StartInactivityTime>(AuthActionsTypes.StartInactivityTime),
        tap(() => {
          if (this.inactivitySubscription) {
            this.inactivitySubscription.unsubscribe();
          }
          this.inactivitySubscription = new Subscription();
          this.inactivitySubscription.add(this.inactivityTime$.subscribe());
        })
      ),
    { dispatch: false }
  );
}
