import { Action } from '@ngrx/store';
import { Dictionary } from '../models/dictionary.model';
import { DictionaryPrice } from '../models/dictionary-price.model';
import { SubscriptionStatus } from '../models/subscription-status.model';
import { BlockedFileFormat } from '../models/blocked-file-format.model';
import { DicSidedrawerType } from '../models/sidedrawer-type.model';
import { RelationshipType } from '../models/relationship-type';
import { Relationship } from '../models/relationship.model';
import { Locale } from '../models/locale.model';
import { DictionaryResponseDto } from '../models/dictionary-response.dto';
import { ImageFileFormat } from '../models/image-file-format.model';
import { VideoFileFormat } from '../models/video-file-format.model';
import { Currency } from '../models/currency.model';
import { PlanItemFormType } from '../models/plan-item-form-type.model';
import { TenantRoleDictionary } from '../models/tenant-role-dictionary.model';
import { VCardLink } from '../models/vcard-link.model';
import { SdTypeByTenant } from '../models/sd-type-by-tenant.model';
import { EtlField } from '../models/etl-field.model';
import { JumpOffButton } from '../models/jump-off-button.model';
import { DataBaseRegion } from '../models/data-base-region.models';
import { SocialIdentityProvider } from '../models/social-identity-provider.model';
import { HelpSections } from '../models/help-sections.model';
import { HelpVideos } from '../models/help-videos.model';
import { Tos } from '../models/tos.model';
import { PrivacyPolicy } from '../models/privacy-policy.model';
import { DicSdRole } from '../models/dic-sd-role.model';
import { CloudDrive } from '../models/cloud-integration.model';
import { DicCustomMessage } from '../models/custom-message.model';
import { DicIntegrationProviders } from '../models/dic-integration-providers.model';

export enum DictionaryActionsTypes {
  DictionaryRequested = '[App Component] Dictionary Requested',
  LocalesLoaded = '[Dictionary Service] Locales Loaded',
  LocaleDefaultChange = 'Locales Requested',
  DictionaryLoaded = '[Dictionary Service] Dictionary Loaded',
  DictionaryPricesLoaded = '[Dictionary Service] Dictionary Prices Loaded',
  SubscriptionsStatusLoaded = '[Dictionary Service] Subscriptions Status Loaded',
  BlockedFileFormatsLoaded = '[Dictionary Service] Blocked File Formats Loaded',
  ImageFileFormatsLoaded = '[Dictionary Service] Image File Formats Loaded',
  VideoFileFormatsLoaded = '[Dictionary Service] Video File Formats Loaded',
  DictionarySideDrawerTypesLoaded = '[Dictionary Service] Side Drawer Types Loaded',
  RelationshipTypesLoaded = '[Dictionary Service] Relationship Types Loaded',
  RelationshipsLoaded = '[Dictionary Service] Relationships Loaded',
  CurrenciesLoaded = '[Dictionary Service] Currencies Loaded',
  PlanItemFormTypesLoaded = '[Dictionary Service] PlanItemFormTypes Loaded',
  TenantRolesLoaded = '[Dictionary Service] Tenant Roles Loaded',
  VCardLinksLoaded = '[Dictionary Service] VCard Links Loaded',
  SdTypeByTenantLoaded = '[Dictionary Service] Sd Type By Tenant Loaded',
  EtlFieldsLoaded = '[Dictionary Service] Etl Fields Loaded',
  JumpOffButtonsLoaded = '[Dictionary Service] Jump Off Buttons Loaded',
  DataBaseRegionsLoaded = '[Dictionary Service] Data Base Regions Loaded',
  SocialIdentityProvidersLoaded = '[Dictionary Service] Social Identity Providers Loaded',
  GettingDictionaryChange = '[Dictionary Effect] Getting Dictionary Change',
  GettingDictionaryFails = '[Dictionary Effect] Getting Dictionary Fails',
  LocalesAndDictionaryRequested = '[] Locales And Dictionary Requested',
  HelpSectionsLoaded = '[Dictionary Service] Help Sections Loaded',
  HelpVideosLoaded = '[Dictionary Service] Help Videos Loaded',
  TosLoaded = '[Dictionary Service] Tos Loaded',
  PrivacyPoliciesLoaded = '[Dictionary Service] Privacy Policies Loaded',
  DictionarySdRolesLoaded = '[Dictionary Service] Dictionary SideDrawer Roles Loaded',
  CloudIntegrationsLoaded = '[Integrations] Cloud integrations Loaded',
  CustomMessagesLoaded = '[Dictionary Service] Custom messages Loaded',
  IntegrationProvidersLoaded = '[Dictionary Service] Integration Providers Loaded',
}

export class DictionaryRequested implements Action {
  readonly type = DictionaryActionsTypes.DictionaryRequested;

  constructor(public payload: { locale?: Locale; localeId?: string }) {}
}

export class LocalesLoaded implements Action {
  readonly type = DictionaryActionsTypes.LocalesLoaded;

  constructor(public payload: { data: Locale[] }) {}
}

export class LocaleDefaultChange implements Action {
  readonly type = DictionaryActionsTypes.LocaleDefaultChange;

  constructor(public payload: { data: Locale }) {}
}

export class DictionaryLoaded implements Action {
  readonly type = DictionaryActionsTypes.DictionaryLoaded;

  constructor(
    public payload: {
      data: Dictionary;
      completeDictionary: DictionaryResponseDto;
    }
  ) {}
}

export class DictionaryPricesLoaded implements Action {
  readonly type = DictionaryActionsTypes.DictionaryPricesLoaded;

  constructor(public payload: { data: DictionaryPrice[] }) {}
}

export class SubscriptionsStatusLoaded implements Action {
  readonly type = DictionaryActionsTypes.SubscriptionsStatusLoaded;

  constructor(public payload: { data: SubscriptionStatus[] }) {}
}

export class BlockedFileFormatsLoaded implements Action {
  readonly type = DictionaryActionsTypes.BlockedFileFormatsLoaded;

  constructor(public payload: { data: BlockedFileFormat[] }) {}
}

export class ImageFileFormatsLoaded implements Action {
  readonly type = DictionaryActionsTypes.ImageFileFormatsLoaded;

  constructor(public payload: { data: ImageFileFormat[] }) {}
}

export class VideoFileFormatsLoaded implements Action {
  readonly type = DictionaryActionsTypes.VideoFileFormatsLoaded;

  constructor(public payload: { data: VideoFileFormat[] }) {}
}

export class DictionarySideDrawerTypesLoaded implements Action {
  readonly type = DictionaryActionsTypes.DictionarySideDrawerTypesLoaded;

  constructor(public payload: { data: DicSidedrawerType[] }) {}
}

export class RelationshipTypesLoaded implements Action {
  readonly type = DictionaryActionsTypes.RelationshipTypesLoaded;

  constructor(public payload: { data: RelationshipType[] }) {}
}

export class RelationshipsLoaded implements Action {
  readonly type = DictionaryActionsTypes.RelationshipsLoaded;

  constructor(public payload: { data: Relationship[] }) {}
}

export class CurrenciesLoaded implements Action {
  readonly type = DictionaryActionsTypes.CurrenciesLoaded;

  constructor(public payload: { data: Currency[] }) {}
}

export class PlanItemFormTypesLoaded implements Action {
  readonly type = DictionaryActionsTypes.PlanItemFormTypesLoaded;

  constructor(public payload: { data: PlanItemFormType[] }) {}
}

export class TenantRolesLoaded implements Action {
  readonly type = DictionaryActionsTypes.TenantRolesLoaded;

  constructor(public payload: { data: TenantRoleDictionary[] }) {}
}

export class VCardLinksLoaded implements Action {
  readonly type = DictionaryActionsTypes.VCardLinksLoaded;

  constructor(public payload: { data: VCardLink[] }) {}
}

export class SdTypeByTenantLoaded implements Action {
  readonly type = DictionaryActionsTypes.SdTypeByTenantLoaded;

  constructor(public payload: { data: SdTypeByTenant[] }) {}
}

export class EtlFieldsLoaded implements Action {
  readonly type = DictionaryActionsTypes.EtlFieldsLoaded;

  constructor(public payload: { data: EtlField[] }) {}
}

export class JumpOffButtonsLoaded implements Action {
  readonly type = DictionaryActionsTypes.JumpOffButtonsLoaded;

  constructor(public payload: { data: JumpOffButton[] }) {}
}

export class DataBaseRegionsLoaded implements Action {
  readonly type = DictionaryActionsTypes.DataBaseRegionsLoaded;

  constructor(public payload: { data: DataBaseRegion[] }) {}
}

export class SocialIdentityProvidersLoaded implements Action {
  readonly type = DictionaryActionsTypes.SocialIdentityProvidersLoaded;

  constructor(public payload: { data: SocialIdentityProvider[] }) {}
}

export class LocalesAndDictionaryRequested implements Action {
  readonly type = DictionaryActionsTypes.LocalesAndDictionaryRequested;
}

export class GettingDictionaryFails implements Action {
  readonly type = DictionaryActionsTypes.GettingDictionaryFails;
}

export class GettingDictionaryChange implements Action {
  readonly type = DictionaryActionsTypes.GettingDictionaryChange;

  constructor(public payload: { state: boolean }) {}
}

export class HelpSectionsLoaded implements Action {
  readonly type = DictionaryActionsTypes.HelpSectionsLoaded;

  constructor(public payload: { data: HelpSections[] }) {}
}

export class HelpVideosLoaded implements Action {
  readonly type = DictionaryActionsTypes.HelpVideosLoaded;

  constructor(public payload: { data: HelpVideos[] }) {}
}

export class TosLoaded implements Action {
  readonly type = DictionaryActionsTypes.TosLoaded;

  constructor(public payload: { data: Tos[] }) {}
}

export class PrivacyPoliciesLoaded implements Action {
  readonly type = DictionaryActionsTypes.PrivacyPoliciesLoaded;

  constructor(public payload: { data: PrivacyPolicy[] }) {}
}

export class DictionarySdRolesLoaded implements Action {
  readonly type = DictionaryActionsTypes.DictionarySdRolesLoaded;

  constructor(public payload: { data: DicSdRole[] }) {}
}

export class CloudIntegrationsLoaded implements Action {
  readonly type = DictionaryActionsTypes.CloudIntegrationsLoaded;

  constructor(public payload: { data: CloudDrive[] }) {}
}

export class CustomMessagesLoaded implements Action {
  readonly type = DictionaryActionsTypes.CustomMessagesLoaded;

  constructor(public payload: { data: DicCustomMessage[] }) {}
}

export class IntegrationProvidersLoaded implements Action {
  readonly type = DictionaryActionsTypes.IntegrationProvidersLoaded;

  constructor(public payload: { data: DicIntegrationProviders[] }) {}
}

export type DictionaryActions =
  | DictionaryLoaded
  | DictionaryPricesLoaded
  | SubscriptionsStatusLoaded
  | BlockedFileFormatsLoaded
  | ImageFileFormatsLoaded
  | VideoFileFormatsLoaded
  | DictionarySideDrawerTypesLoaded
  | RelationshipTypesLoaded
  | RelationshipsLoaded
  | CurrenciesLoaded
  | PlanItemFormTypesLoaded
  | LocalesLoaded
  | TenantRolesLoaded
  | LocaleDefaultChange
  | VCardLinksLoaded
  | SdTypeByTenantLoaded
  | JumpOffButtonsLoaded
  | DataBaseRegionsLoaded
  | SocialIdentityProvidersLoaded
  | EtlFieldsLoaded
  | LocalesAndDictionaryRequested
  | GettingDictionaryFails
  | GettingDictionaryChange
  | HelpSectionsLoaded
  | HelpVideosLoaded
  | TosLoaded
  | PrivacyPoliciesLoaded
  | DictionarySdRolesLoaded
  | CloudIntegrationsLoaded
  | CustomMessagesLoaded
  | IntegrationProvidersLoaded;
