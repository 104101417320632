import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  DictionaryLoaded,
  LocaleDefaultChange,
  LocalesLoaded,
} from '../../../../dictionary/store/dictionary.actions';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import { DictionaryService } from '../../../../dictionary/services/dictionary.service';
import { SfAuthActions } from './sf-auth.store';
import { Locale } from '../../../../dictionary/models/locale.model';

export const requestDictionary$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store$ = inject(Store<AppState>);
    const service = inject(DictionaryService);
    return actions$.pipe(
      ofType(SfAuthActions.dictionaryRequested),
      exhaustMap(({ localeId }) =>
        forkJoin([
          service
            .getDictionary(null, localeId)
            .pipe(catchError(() => of(null))),
          service.getLocales().pipe(catchError(() => of(null))),
        ]).pipe(
          map(([dictionary, locales]) => {
            if (locales) {
              store$.dispatch(new LocalesLoaded({ data: locales }));
              store$.dispatch(
                new LocaleDefaultChange({
                  data: locales.find(
                    (locale: Locale) =>
                      locale.localeid?.toLowerCase() === localeId?.toLowerCase()
                  ),
                })
              );
            }
            if (dictionary) {
              return new DictionaryLoaded({
                data: dictionary.content,
                completeDictionary: dictionary,
              });
            }
            return SfAuthActions.generalErrorModeUpdated({
              generalErrorMode: 'dictionary',
            });
          })
        )
      )
    );
  },
  { functional: true }
);
