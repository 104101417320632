import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Option } from '../../models/option.model';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RecordSubType } from 'src/app/record-types/models/record-sub-type.model';
import { DisplayValue } from 'src/app/record-types/models/display-value.model';
import { Locale } from 'src/app/dictionary/models/locale.model';

@Component({
  selector: 'app-sd-autocomplete',
  templateUrl: './sd-autocomplete.component.html',
  styleUrls: ['./sd-autocomplete.component.scss'],
})
export class SdAutocompleteComponent implements OnInit, OnChanges {
  @Input() options: Option[] = [];
  @Input() controller: UntypedFormControl;
  @Input() locale: Locale;

  initialValue: RecordSubType;
  @Input() set setInitialValue(value: RecordSubType) {
    this.initialValue = value;
    const option = new Option(
      this.initialValue?.name,
      this.getDisplayValue(
        this.initialValue?.displayValue
      ).toLocaleLowerCase() === 'other'
        ? this.initialValue?.name
        : this.getDisplayValue(this.initialValue?.displayValue)
    );
    this.controller.setValue(option);
  }

  @Input() placeholder: string;
  @Input() error: string;
  @Input() readOnly = false;
  filteredOptions: Observable<Option[]>;
  @Output()
  selection? = new EventEmitter<Option>();

  ngOnInit(): void {
    this.filteredOptions = this.controller.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value?.value)),
      map(value => (value ? this._filter(value) : this.options?.slice()))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filteredOptions = this.controller.valueChanges.pipe(
      startWith(
        `${
          !!this.controller.value && this.controller.value.length > 0
            ? this.controller.value
            : ''
        }`
      ),
      map(value => (typeof value === 'string' ? value : value.value)),
      map(value => (value ? this._filter(value) : this.options?.slice()))
    );
  }

  displayFn(option): string {
    return option && option.value
      ? option.value
      : option?.length > 0
      ? option
      : '';
  }

  private _filter(value: string): Option[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(
      option => option.value.toLowerCase().indexOf(filterValue) === 0
    );
  }

  selectedValue(event$) {
    this.selection.emit(event$);
  }

  private getDisplayValue(values: DisplayValue[]): string {
    if (!values || values?.length === 0) {
      return '';
    }
    return values.find(
      value => value?.locale === Locale.getLocaleId(this.locale)
    )?.value
      ? values.find(value => value?.locale === Locale.getLocaleId(this.locale))
          ?.value
      : values[0].value;
  }
}
