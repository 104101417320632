import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { RecordType } from '../models/record-type.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../auth/services/auth.service';
import { Locale } from '../../dictionary/models/locale.model';
import { RecordSubType } from '../models/record-sub-type.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { brandsListSelector } from '../../branding/store/brand-list.selectors';
import { CreateRecordTypeDto } from '../models/create-record-type-dto.model';
import { TileAdded, TileUpdated } from 'src/app/tiles/store/tiles.actions';

@Injectable()
export class RecordTypesService {
  private recordsApi = environment.recordsApi;
  private tenantApi = environment.tenantApi;

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly store: Store<AppState>
  ) {}

  getRecordTypes(
    locale: Locale,
    sideDrawerType: string,
    cobrandId: string,
    sidedrawerTypeOtherName?: string
  ): Observable<RecordType[]> {
    return forkJoin([
      this.store.pipe(select(brandsListSelector), take(1)),
    ]).pipe(
      mergeMap(([brands]) => {
        const brandCode = brands?.find(brand => brand.id === cobrandId)
          ?.brandCode;
        let url =
          this.recordsApi +
          `record-types?locale=${Locale.getLocaleId(
            locale
          )}&sidedrawerType=${sideDrawerType}&brandCode=${brandCode}`;
        if (sidedrawerTypeOtherName?.length > 0) {
          url = url + `&sidedrawerTypeOtherName=${sidedrawerTypeOtherName}`;
        }
        return this.http.get<RecordType[]>(url, {
          headers: this.authService.getHeaders(),
        });
      })
    );
  }

  getRecordsSubType(
    recordTypeName: string,
    locale: Locale
  ): Observable<RecordSubType[]> {
    return this.http
      .get<RecordSubType[]>(
        this.recordsApi +
          `records-type/record-type-name/${recordTypeName}/record-subtype?locale=${Locale.getLocaleId(
            locale
          )}`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(catchError(() => of([])));
  }

  getOneRecordType(
    tenantId: string,
    options: { individual?: boolean; business?: boolean; other?: string }
  ): Observable<RecordType[]> {
    let url = this.tenantApi + `tenant/tenant-id/${tenantId}/records-type?`;
    if (options.other) {
      url += `sidedrawerType=other&sidedrawerTypeOtherName=${options.other}`;
    }
    if (options.business) {
      url += `sidedrawerType=business`;
    }
    if (options.individual) {
      url += `sidedrawerType=individual`;
    }
    return this.http
      .get<RecordType[]>(url, {
        headers: this.authService.getHeaders(),
      })
      .pipe(catchError(() => of([])));
  }

  getAllRecordsTypes(tenantId: string): Observable<RecordType[]> {
    return this.http
      .get<string[]>(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/records-type/sidedrawer-types`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        catchError(() => of([])),
        mergeMap((sdTypes: string[]) => {
          const operations = {};
          sdTypes.forEach((sdType, index) => {
            if (sdType.length > 0) {
              operations[`${index}`] = this.getOneRecordType(tenantId, {
                other: sdType,
              });
            }
          });
          operations[`${sdTypes.length}`] = this.getOneRecordType(tenantId, {
            individual: true,
          });
          operations[`${sdTypes.length + 1}`] = this.getOneRecordType(
            tenantId,
            { business: true }
          );
          return forkJoin(Object.values(operations)).pipe(
            map(data => {
              let recordsTypes: RecordType[] = [];
              Object.values(data).forEach((response: any[]) => {
                recordsTypes = [...recordsTypes, ...response];
              });
              return recordsTypes;
            })
          );
        })
      );
  }

  getDefaultTiles(
    sidedrawerType: 'individual' | 'business',
    locale: Locale
  ): Observable<RecordType[]> {
    return this.http.get<RecordType[]>(this.recordsApi + 'record-types', {
      params: { sidedrawerType, locale: Locale.getLocaleId(locale) },
      headers: this.authService.getHeaders(),
    });
  }

  updateRecordsType(
    recordName: string,
    body: CreateRecordTypeDto,
    tenantId?: string
  ): Observable<RecordType> {
    return this.http
      .put(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/records-type/record-type-name/${recordName}`,
        body,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        tap(() =>
          this.store.dispatch(
            new TileUpdated({ tile: { id: body.name, changes: body } })
          )
        )
      );
  }

  createRecordsType(
    body: CreateRecordTypeDto,
    tenantId?: string,
    brandId?: string
  ): Observable<RecordType> {
    return this.http
      .post(
        this.tenantApi + `tenant/tenant-id/${tenantId}/records-type`,
        body,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        map((tile: RecordType) => {
          // NOTE only the ID is returned from the API
          if (tile) {
            const recordTile = new RecordType(
              body?.name,
              body?.logo,
              body?.displayValue,
              tile.id,
              null,
              body?.sidedrawerTypes[0].sidedrawerType,
              body?.orderId,
              body?.sidedrawerTypes[0].sidedrawerTypeOtherName,
              brandId
            );
            this.store.dispatch(new TileAdded({ tile: recordTile }));
            return recordTile;
          }
        })
      );
  }

  getRecordsSubTypeByTenantAndRecordTypeName(
    tenantId: string,
    recordTypeName: string
  ): Observable<RecordSubType[]> {
    return this.http
      .get<RecordSubType[]>(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/records-type/record-type-name/${recordTypeName}/record-subtype`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(catchError(() => of([])));
  }

  getRecordsSubTypeByTenantAndRecordTypeNameAndRecordSubTypeName(
    tenantId: string,
    recordTypeName: string,
    recordSubtypeName: string
  ): Observable<RecordSubType> {
    return this.http
      .get<RecordSubType>(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/records-type/record-type-name/${recordTypeName}/record-subtype/record-subtype-name/${recordSubtypeName}`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(catchError(() => of()));
  }

  updateRecordsSubTypeByTenantAndRecordTypeNameAndSubTypeName(
    recordName: string,
    body: RecordSubType,
    tenantId: string,
    recordSubtypeName: string
  ): Observable<RecordSubType> {
    return this.http.put(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/records-type/record-type-name/${recordName}/record-subtype/record-subtype-name/${recordSubtypeName}`,
      body,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  createRecordsSubTypeByTenantAndRecordTypeNameAndSubTypeName(
    recordName: string,
    body: RecordSubType,
    tenantId: string
  ): Observable<RecordSubType> {
    return this.http.post(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/records-type/record-type-name/${recordName}/record-subtype`,
      body,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }
}
