import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { authRolesSelector } from '../store/auth.selectors';
import { map } from 'rxjs/operators';
import { CoreRoutes } from '../../core/routes/core.routes';
import { HomeRoutes } from '../../home/routes/home.routes';

@Injectable({
  providedIn: 'root',
})
export class NoRoleGuard  {
  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.pipe(
      select(authRolesSelector),
      map(roles => roles.length > 0),
      map(hasRole => {
        if (hasRole) {
          this.router.navigateByUrl(`/${CoreRoutes.root}/${HomeRoutes.root}`);
        }
        return !hasRole;
      })
    );
  }
}
