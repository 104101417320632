import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Tenant } from '../../../tenants/models/tenant.model';
import { TenantService } from '../../../tenants/services/tenant.service';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { SfAuthActions } from '../core/store/sf-auth.store';
import { of } from 'rxjs';
import {
  CurrentTenantChange,
  TenantsLoaded,
} from '../../../tenants/store/tenant.actions';

export const sfTenantResolver: ResolveFn<Tenant> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: TenantService = inject(TenantService),
  store: Store<AppState> = inject(Store<AppState>)
) => {
  const { tenantId } = route.params;
  return service.getTenants().pipe(
    catchError((error: HttpErrorResponse) => {
      store.dispatch(
        SfAuthActions.generalErrorModeUpdated({
          generalErrorMode:
            error.status === 404
              ? '404'
              : error.status === 403 || error.status === 401
              ? '403'
              : 'generic',
        })
      );
      return of(null);
    }),
    map(tenants => {
      if (!tenants) {
        return null;
      }
      store.dispatch(new TenantsLoaded({ tenants }));
      const activeTenant = tenants.find(tenant => tenant.id === tenantId);
      if (activeTenant) {
        store.dispatch(new CurrentTenantChange({ tenant: activeTenant }));
      }

      return activeTenant;
    })
  );
};
