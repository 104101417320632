<div
  [ngStyle]="{
    'align-items': alignItemsCenter
  }"
  class="content">
  <mat-checkbox
    (change)="onChange()"
    [checked]="value"
    [disabled]="disabled"
    class="sd-checkbox"
    color="primary">
  </mat-checkbox>
  <div class="text">
    <ng-content></ng-content>
  </div>
</div>
