import { Component, Input, OnInit } from '@angular/core';
import { Option } from '../../models/option.model';

@Component({
  selector: 'app-sd-selector-option',
  templateUrl: './sd-selector-option.component.html',
  styleUrls: ['./sd-selector-option.component.scss'],
})
export class SdSelectorOptionComponent implements OnInit {
  @Input() option: Option;
  @Input() displaySvg = false;
  @Input() roundedAvatar = true;

  constructor() {}

  ngOnInit(): void {}
}
