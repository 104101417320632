import { createSelector } from '@ngrx/store';
import { sdTableStateSelector } from './sd-table.selectors';

export const dialogTableStateSelector = createSelector(
  sdTableStateSelector,
  state => state.dialog
);

export const sdTableDialogColumnsSelector = createSelector(
  dialogTableStateSelector,
  state => state.columns
);

export const sdTableDialogPageSelector = createSelector(
  dialogTableStateSelector,
  state => state.page
);

export const sdTableDialogElementsPerPageSelector = createSelector(
  dialogTableStateSelector,
  state => state?.elementsPerPage
);

export const sdTableDialogFilteredDataSelector = createSelector(
  dialogTableStateSelector,
  state => state.filteredData
);

export const sdTableDialogElementsPerPageOptionsSelector = createSelector(
  dialogTableStateSelector,
  state => state.elementsPerPageOptions
);

export const sdTableDialogSortTableSelector = createSelector(
  dialogTableStateSelector,
  state => state.sortTableBy
);
