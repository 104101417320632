import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthGuard } from '../../../auth/guards/auth.guard';
import { KeycloakAuthService } from './services/keycloak-auth.service';
import { InactivityTimeDialogModule } from '../../../auth/shared/inactivity-time-dialog/inactivity-time-dialog.module';
import { StoreModule } from '@ngrx/store';
import { authReducer, featureName } from '../../../auth/store/auth.reducer';
import { AuthConfig, OAuthModule } from 'angular-oauth2-oidc';
import { keycloakAuthConfig } from './config/keycloak-auth.config';
import { EffectsModule } from '@ngrx/effects';
import { KeycloakAuthEffects } from './store/keycloak-auth.effects';
import { AuthService } from '../../../auth/services/auth.service';
import { KeycloakAuthRoutingModule } from './keycloak-auth-routing.module';
import { TenantService } from '../../../tenants/services/tenant.service';

@NgModule({
  imports: [
    OAuthModule.forRoot(),
    StoreModule.forFeature(featureName, authReducer),
    EffectsModule.forFeature([KeycloakAuthEffects]),
    InactivityTimeDialogModule,
    KeycloakAuthRoutingModule,
  ],
  providers: [KeycloakAuthService, AuthGuard, AuthService, TenantService],
})
export class KeycloakAuthModule {
  static forRoot(): ModuleWithProviders<KeycloakAuthModule> {
    return {
      ngModule: KeycloakAuthModule,
      providers: [{ provide: AuthConfig, useValue: keycloakAuthConfig }],
    };
  }
}
