import { Action } from '@ngrx/store';
import { SdSnackBarData } from '../models/sd-snack-bar-data.model';

export enum SdSnackBarActionsTypes {
  SdSnackBarDisplaySnackBar = '[] Sd SnackBar Display Snack Bar',
  SdSnackBarCloseSnackBar = '[] Sd SnackBar Close Snack Bar',
}

export class SdSnackBarDisplaySnackBar implements Action {
  readonly type = SdSnackBarActionsTypes.SdSnackBarDisplaySnackBar;

  constructor(public payload: SdSnackBarData) {}
}

export class SdSnackBarCloseSnackBar implements Action {
  readonly type = SdSnackBarActionsTypes.SdSnackBarCloseSnackBar;
}

export type SdSnackBarActions =
  | SdSnackBarDisplaySnackBar
  | SdSnackBarCloseSnackBar;
