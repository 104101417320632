<mat-form-field [ngClass]="{ readOnly: readOnly }">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    [readonly]="readOnly"
    [formControl]="controller"
    [matAutocomplete]="auto"
    matInput
    type="text" />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="selectedValue($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      <div *ngIf="!option.description" class="sd-autocomplete-option-text">
        {{ option.value }}
      </div>

      <div
        *ngIf="option.description"
        class="sd-autocomplete-option-text-container">
        <div
          [attr.data-description]="option.description"
          class="sd-autocomplete-option-text">
          {{ option.value }}
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="controller.invalid">
    <span>{{ error }}</span>
  </mat-error>
</mat-form-field>
