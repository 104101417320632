import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthRoutes } from './auth/routes/auth.routes';
import { CoreRoutes } from './core/routes/core.routes';
import { AuthGuard } from './auth/guards/auth.guard';
import { TenantRoutes } from './tenants/routes/tenant.routes';
import { NoAuthGuard } from './auth/guards/no-auth.guard';
import { NoRoleGuard } from './auth/guards/no-role.guard';
import { tenantRolesResolver } from './core/resolvers/tenant-roles.resolver';

const routes: Routes = [
  {
    path: AuthRoutes.root,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    resolve: {
      tenantRoles: tenantRolesResolver,
    },
  },
  {
    path: CoreRoutes.root,
    loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
    canActivate: [AuthGuard],
  },
  {
    path: TenantRoutes.signup,
    loadChildren: () =>
      import(
        './tenants/views/tenant-creation-form/tenant-creation-form.module'
      ).then(m => m.TenantCreationFormModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: TenantRoutes.processing,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './tenants/views/tenant-creation-processing/tenant-creation-processing.module'
      ).then(m => m.TenantCreationProcessingModule),
  },
  {
    path: TenantRoutes.create,
    canActivate: [AuthGuard, NoRoleGuard],
    loadChildren: () =>
      import(
        './tenants/views/tenant-creation-form-with-credentials/tenant-creation-form-with-credentials.module'
      ).then(m => m.TenantCreationFormWithCredentialsModule),
  },
  {
    path: '**',
    redirectTo: 'auth/authorize',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
