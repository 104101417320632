import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { KeycloakAuthService } from '../services/keycloak-auth.service';
import {
  AuthActionsTypes,
  AuthorizeUser,
  AuthUserUpdate,
  LogIn,
  LogOut,
  RefreshTokenRequired,
  StartRefreshTokenTime,
  TokenUpdate,
} from '../../../../auth/store/auth.actions';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, from, of, retry, timer } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthEffects } from '../../../../auth/store/auth.effects';
import { RoutesHelper } from '../../../../core/helpers/routes.helper';
import { CoreRoutes } from '../../../../core/routes/core.routes';
import { HomeRoutes } from '../../../../home/routes/home.routes';
import { AuthUser } from 'src/app/auth/models/user.model';

@Injectable()
export class KeycloakAuthEffects extends AuthEffects {
  protected readonly keycloakAuthService = inject(KeycloakAuthService);

  authorizeUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AuthorizeUser>(AuthActionsTypes.AuthorizeUser),
        switchMap(() => from(this.keycloakAuthService.initCredentials())),
        tap(isAuthenticated => {
          if (isAuthenticated) {
            this.store.dispatch(
              new LogIn({
                token: this.keycloakAuthService.accessToken,
                user: AuthUser.fromKeycloakUser(
                  this.keycloakAuthService.identityForKeycloakFromJWT
                ),
              })
            );
            const urlParams = new URLSearchParams(window.location.search);
            const origin = urlParams.get('origin');
            const queryParams = RoutesHelper.getParams(false);
            const url =
              !!origin &&
              origin !== '/' &&
              origin !== '/auth/authorize' &&
              !origin.includes('/core/error') &&
              !origin.includes('/core/forbidden')
                ? decodeURIComponent(origin.split('?')[0])
                : `/${CoreRoutes.root}/${HomeRoutes.root}`;
            this.router.navigate([url], { queryParams }).then();
            return;
          }
          this.keycloakAuthService.login();
        }),
        switchMap(() => of(null)),
        catchError(() => {
          const queryParams = RoutesHelper.getParams(false);
          this.router.navigate(
            [`${CoreRoutes.root}/${CoreRoutes.error}/true`],
            { queryParams }
          );
          return of(EMPTY);
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<LogOut>(AuthActionsTypes.Logout),
        tap(() => {
          this.keycloakAuthService.logout();
        })
      );
    },
    { dispatch: false }
  );
  tokenUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TokenUpdate>(AuthActionsTypes.TokenUpdate),
      map(() => this.keycloakAuthService.identityForKeycloakFromJWT),
      map(user => new AuthUserUpdate({ user: AuthUser.fromKeycloakUser(user) }))
    )
  );

  startRefreshTokenTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType<StartRefreshTokenTime>(AuthActionsTypes.StartRefreshTokenTime),
      debounceTime(1000 * environment.refreshTokenTime),
      switchMap(() =>
        from(this.keycloakAuthService.refresh()).pipe(
          retry({
            count: 10,
            delay: (_, retryCount) => timer(retryCount * 1000),
          })
        )
      ),
      map(() => {
        this.store.dispatch(
          new TokenUpdate({ token: this.keycloakAuthService.accessToken })
        );
        return new StartRefreshTokenTime();
      }),
      catchError(error => {
        console.error({
          error,
          message: 'getTokenSilently fails on auth effect',
        });
        return of(new LogOut());
      })
    )
  );
  refreshTokenRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RefreshTokenRequired>(AuthActionsTypes.RefreshTokenRequired),
      switchMap(() =>
        from(this.keycloakAuthService.refresh()).pipe(
          retry({
            count: 10,
            delay: (_, retryCount) => timer(retryCount * 1000),
          }),
          map(
            () =>
              new TokenUpdate({ token: this.keycloakAuthService.accessToken })
          )
        )
      )
    )
  );
}
