import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Dictionary } from '../../../../../dictionary/models/dictionary.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../reducers';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { NetworksService } from '../../../../services/networks.service';
import { dictionarySelector } from '../../../../../dictionary/store/dictionary.selectors';
import { tap } from 'rxjs/operators';
import { GenericErrorLoad } from '../../../../../core/store/core.actions';

@Component({
  selector: 'app-side-drawer-networks-dialog',
  templateUrl: './side-drawer-networks-dialog.component.html',
  styleUrls: ['./side-drawer-networks-dialog.component.scss'],
})
export class SideDrawerNetworksDialogComponent implements OnInit {
  dictionary$: Observable<Dictionary>;
  spinner = false;

  constructor(
    private readonly dialogRef: MatDialogRef<SideDrawerNetworksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { sidedrawerId: string },
    private readonly store: Store<AppState>,
    private readonly networksService: NetworksService
  ) {}

  ngOnInit(): void {
    this.dictionary$ = this.store.pipe(select(dictionarySelector));
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.spinner = true;
    this.networksService
      .forceUser(this.data.sidedrawerId)
      .pipe(
        tap({
          next: () => {
            this.dialogRef.close();
          },
          error: error => {
            this.dialogRef.close();
            this.store.dispatch(
              new GenericErrorLoad({
                error: {
                  httpError: error,
                  display404: true,
                  display403: true,
                },
              })
            );
          },
        })
      )
      .subscribe();
  }
}
