export enum RecordsRoles {
  info = 'rec_info',
  viewer = 'rec_viewer',
  editor = 'rec_editor',
  owner = 'rec_owner',
}

export const getRecordRole = (roles: RecordsRoles[]): RecordsRoles => {
  if (!roles || roles?.length === 0) {
    return RecordsRoles.info;
  }
  if (roles.some(role => role === RecordsRoles.owner)) {
    return RecordsRoles.owner;
  }
  if (roles.some(role => role === RecordsRoles.editor)) {
    return RecordsRoles.editor;
  }
  if (roles.some(role => role === RecordsRoles.viewer)) {
    return RecordsRoles.viewer;
  }
  return RecordsRoles.info;
};

export const compareRecordsRoles = (
  role1: RecordsRoles,
  role2: RecordsRoles
): RecordsRoles => {
  if (role1 === RecordsRoles.owner || role2 === RecordsRoles.owner) {
    return RecordsRoles.owner;
  }
  if (role1 === RecordsRoles.editor || role2 === RecordsRoles.editor) {
    return RecordsRoles.editor;
  }
  if (role1 === RecordsRoles.viewer || role2 === RecordsRoles.viewer) {
    return RecordsRoles.viewer;
  }
  if (role1 === RecordsRoles.info || role2 === RecordsRoles.info) {
    return RecordsRoles.info;
  }
  return RecordsRoles.info;
};
