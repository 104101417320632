import { compareRecordsRoles, RecordsRoles } from './records.roles';

export enum SidedrawerRoles {
  info = 'sd_info',
  viewer = 'sd_viewer',
  editor = 'sd_editor',
  owner = 'sd_owner',
}

export const getSideDrawerRole = (
  roles: SidedrawerRoles[]
): SidedrawerRoles => {
  if (!roles || roles?.length === 0) {
    return SidedrawerRoles.info;
  }
  if (roles.some(role => role === SidedrawerRoles.owner)) {
    return SidedrawerRoles.owner;
  }
  if (roles.some(role => role === SidedrawerRoles.editor)) {
    return SidedrawerRoles.editor;
  }
  if (roles.some(role => role === SidedrawerRoles.viewer)) {
    return SidedrawerRoles.viewer;
  }
  return SidedrawerRoles.info;
};

export const compareSideDrawerRoles = (
  role1: SidedrawerRoles,
  role2: SidedrawerRoles
): SidedrawerRoles | RecordsRoles => {
  if (role1 === SidedrawerRoles.owner || role2 === SidedrawerRoles.owner) {
    return SidedrawerRoles.owner;
  }
  if (role1 === SidedrawerRoles.editor || role2 === SidedrawerRoles.editor) {
    return SidedrawerRoles.editor;
  }
  if (role1 === SidedrawerRoles.viewer || role2 === SidedrawerRoles.viewer) {
    return SidedrawerRoles.viewer;
  }
  if (role1 === SidedrawerRoles.info || role2 === SidedrawerRoles.info) {
    return SidedrawerRoles.info;
  }
  return compareRecordsRoles(role1, role2);
};
