import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { StoreModule } from '@ngrx/store';
import { authReducer, featureName } from './store/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/auth.effects';
import { InactivityTimeDialogModule } from './shared/inactivity-time-dialog/inactivity-time-dialog.module';
import { AuthService } from './services/auth.service';
import { TenantService } from '../tenants/services/tenant.service';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    StoreModule.forFeature(featureName, authReducer),
    EffectsModule.forFeature([AuthEffects]),
    InactivityTimeDialogModule,
  ],
  providers: [AuthService, TenantService],
})
export class AuthModule {}
