import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Branding } from '../models/branding.model';
import { BrandListActions, BrandListActionsTypes } from './brand-list.actions';

export type BrandListState = EntityState<Branding>;

export const brandListAdapter: EntityAdapter<Branding> =
  createEntityAdapter<Branding>({
    selectId: branding => branding.id,
  });

export const initialBrandingState: BrandListState =
  brandListAdapter.getInitialState();

export function brandListReducer(
  state = initialBrandingState,
  action: BrandListActions
): BrandListState {
  switch (action.type) {
    case BrandListActionsTypes.BrandsLoaded:
      return brandListAdapter.setAll(action.payload.brands, state);
    case BrandListActionsTypes.BrandAdded:
      return brandListAdapter.addOne(action.payload.branding, state);
    case BrandListActionsTypes.BrandUpdated:
      return brandListAdapter.updateOne(action.payload.brand, state);
    case BrandListActionsTypes.BrandDeleted:
      return brandListAdapter.removeOne(action.payload.id, state);
    case BrandListActionsTypes.BrandsClearInformation:
      return brandListAdapter.removeAll(state);
    default:
      return state;
  }
}

export const { selectAll } = brandListAdapter.getSelectors();
