import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/htmlmixed/htmlmixed';
import { ClientNames } from './app/clients/client-names.enum';
import { SfAppModule } from './app/clients/sf/sf-app.module';
import { TdAppModule } from './app/clients/td/td-app.module';

if (environment.production) {
  enableProdMode();
}

if (environment.appName === ClientNames.console) {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

if (environment.appName === ClientNames.consoleSf) {
  platformBrowserDynamic()
    .bootstrapModule(SfAppModule)
    .catch(err => console.error(err));
}

if (environment.appName === ClientNames.consoleTdSf) {
  platformBrowserDynamic()
    .bootstrapModule(SfAppModule)
    .catch(err => console.error(err));
}

if (environment.appName === ClientNames.consoleTd) {
  platformBrowserDynamic()
    .bootstrapModule(TdAppModule)
    .catch(err => console.error(err));
}
