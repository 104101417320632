<button
  [ngClass]="inline ? 'sd-upload-button-inline' : null"
  class="sd-upload-button">
  <input
    (change)="onFileChange($event)"
    [attr.multiple]="multiple"
    [formControl]="controller"
    type="file" />
  <div class="sd-upload-button-image">
    <app-sd-svg
      [color]="'var(--primaryColor)'"
      [height]="23"
      [src]="image"
      [width]="33"></app-sd-svg>
  </div>

  <div class="sd-upload-button-text">
    {{ title }}
  </div>
</button>
