import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../reducers';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable, Subscription } from 'rxjs';
import { Dictionary } from '../../../../../dictionary/models/dictionary.model';
import { dictionarySelector } from '../../../../../dictionary/store/dictionary.selectors';
import { SideDrawerForceNetworkAndCallback } from '../../../../../sidedrawers/store/sidedrawer-list.actions';
import { gettingSideDrawersNetworksSelector } from '../../../../store/sidedrawers-network.selectors';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-network-warning-dialog',
  templateUrl: './network-warning-dialog.component.html',
  styleUrls: ['./network-warning-dialog.component.scss'],
})
export class NetworkWarningDialogComponent implements OnInit, OnDestroy {
  dictionary$: Observable<Dictionary>;
  spinner = false;
  subscription = new Subscription();

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<NetworkWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      hasNetwork: boolean;
      callback: () => void;
      sidedrawerId: string;
    }
  ) {}

  ngOnInit(): void {
    this.dictionary$ = this.store.pipe(select(dictionarySelector));
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onCollaborate(): void {
    this.store.dispatch(
      new SideDrawerForceNetworkAndCallback({
        callback: this.data.callback,
        sidedrawerId: this.data.sidedrawerId,
      })
    );
    const loading$ = this.store.pipe(
      select(gettingSideDrawersNetworksSelector),
      tap(loading => {
        this.spinner = loading;
        if (!loading) {
          this.dialogRef.close();
        }
      })
    );
    this.subscription.add(loading$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
