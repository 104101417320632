import { Pipe, PipeTransform } from '@angular/core';
import { SdTableCellType } from '../models/sd-table-cell-type.model';

@Pipe({
  name: 'sdTableTooltip',
})
export class SdTableTooltipPipe implements PipeTransform {
  transform(value: unknown, cellType: SdTableCellType): string {
    if (cellType !== SdTableCellType.string) {
      return '';
    }
    return value?.toString()?.replace('$/$', ' / ') ?? '';
  }
}
