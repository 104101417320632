import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../reducers';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LogOut } from '../../../../store/auth.actions';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-inactivity-time-dialog',
  templateUrl: './inactivity-time-dialog.component.html',
  styleUrls: ['./inactivity-time-dialog.component.scss'],
})
export class InactivityTimeDialogComponent implements OnInit, OnDestroy {
  timer$ = timer(0, 1000).pipe(
    tap(time => {
      if (time === environment.inactivityDialogTime) {
        this.onClose();
      }
    })
  );
  subscription = new Subscription();

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<InactivityTimeDialogComponent>
  ) {}

  onClose(): void {
    this.store.dispatch(new LogOut());
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.subscription.add(this.timer$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
