<mat-form-field>
  <app-password-strength
    [passwordControl]="controller"
    class="strengthBox hidden"></app-password-strength>

  <input
    #inputElement
    [formControl]="controller"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [type]="type"
    matInput
    (focusout)="
      displayStrengthBox ? strengthBox?.classList?.add('hidden') : null
    "
    (focusin)="
      displayStrengthBox ? strengthBox?.classList?.remove('hidden') : null
    " />

  <div class="suffix-container" matSuffix>
    <div *ngIf="!!suffixText && suffixText.length > 0">
      <span>{{ suffixText }}</span>
    </div>

    <app-sd-icon-button
      (click)="onTogglePassword()"
      [icon]="suffixPassword"
      [primary]="suffixIconHasPrimaryColor"
      class="mr-0"></app-sd-icon-button>

    <app-sd-icon-button
      (click)="onSuffix()"
      *ngIf="!!suffix && suffix.length > 0"
      [disabled]="!suffixIsClickable"
      [icon]="suffix"
      [primary]="suffixIconHasPrimaryColor"></app-sd-icon-button>

    <app-sd-tooltip-button
      *ngIf="!!tooltip && tooltip.length > 0"
      [tooltip]="tooltip"></app-sd-tooltip-button>
  </div>
  <mat-error *ngIf="controller.invalid">
    <span>{{ error }}</span>
  </mat-error>
</mat-form-field>
