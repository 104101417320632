<div class="dashboard-dialog">
  <div class="dashboard-dialog-header">
    <div class="dashboard-dialog-header-title">
      {{ 'sessiontimeoutalert_errorexpiretitle' | dictionary | async }}
    </div>

    <button
      (click)="onClose()"
      class="dashboard-dialog-header-button"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dashboard-dialog-body">
    <div
      [innerHTML]="'sessiontimeoutalert_errorexpirebody' | dictionary | async"
      class="dashboard-dialog-body-description"></div>
  </div>

  <div class="dashboard-dialog-footer">
    <app-sd-flat-button (buttonClicked)="onClose()" [primary]="false">
      {{ 'sessiontimeoutalert_errorexpirebuttonlogout' | dictionary | async }}
    </app-sd-flat-button>

    <app-sd-flat-button
      (buttonClicked)="onSubmit()"
      [primary]="true">
      {{ 'sessiontimeoutalert_errorexpirebuttonrenew' | dictionary | async }}
    </app-sd-flat-button>
  </div>
</div>

