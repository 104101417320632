import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import { blockedFilesFormatSelector } from '../../../../dictionary/store/dictionary.selectors';
import { map, take, tap } from 'rxjs/operators';
import { FilesService } from '../../../../files/services/files.service';
import { SdValidators } from '../../../sd-forms/models/sd.validators';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-sd-upload-button',
  templateUrl: './sd-upload-button.component.html',
  styleUrls: ['./sd-upload-button.component.scss'],
})
export class SdUploadButtonComponent implements OnInit {
  @Input() title: string;
  @Input() image: string;
  @Input() multiple: boolean;
  @Input() inline = false;
  @Input() maxUploadMB: number;
  @Output() files = new EventEmitter<File[]>();
  controller = new UntypedFormControl();

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {}

  onFileChange(event): void {
    const fileList: FileList = event?.target?.files;
    this.emitFiles(Object.values(fileList));
  }

  emitFiles(files: File[] = []): void {
    this.store
      .pipe(
        select(blockedFilesFormatSelector),
        take(1),
        map(blockedFileFormats => {
          const formats = [];
          blockedFileFormats.forEach(format => {
            formats.push(format.fileformat_enumid);
          });
          return formats;
        }),
        tap(blockedFileFormats => {
          const filesToEmit = [];
          files.forEach(file => {
            if (
              !!this.maxUploadMB &&
              SdValidators.checkFileSize(file, this.maxUploadMB)
            ) {
              return;
            }
            const hasExtension =
              !!file.name &&
              file.name.length > 0 &&
              file.name.split('.').length > 1;
            if (hasExtension) {
              const extension =
                file.name.split('.')[file.name.split('.').length - 1];
              if (!FilesService.extensionMatch(extension, blockedFileFormats)) {
                filesToEmit.push(file);
              }
            }
          });
          this.files.emit(
            this.multiple ? filesToEmit : filesToEmit[0] ? [filesToEmit[0]] : []
          );
          this.controller.reset();
        })
      )
      .subscribe();
  }
}
