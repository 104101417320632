import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SideDrawer } from '../../sidedrawers/models/side-drawer.model';
import { TemplatesActions, TemplatesActionsTypes } from './templates.actions';

export const featureName = 'templates';

export interface TemplatesState extends EntityState<SideDrawer> {
  gettingTemplates: boolean;
}

export const templatesAdapter: EntityAdapter<SideDrawer> =
  createEntityAdapter<SideDrawer>({
    selectId: sidedrawer => sidedrawer.id,
  });

export const initialTemplatesState: TemplatesState =
  templatesAdapter.getInitialState({
    gettingTemplates: false,
  });

export function templatesReducer(
  state = initialTemplatesState,
  action: TemplatesActions
): TemplatesState {
  switch (action.type) {
    case TemplatesActionsTypes.TemplatesLoaded:
      return templatesAdapter.upsertMany(action.payload.templates, state);
    case TemplatesActionsTypes.TemplateAdded:
      return templatesAdapter.addOne(action.payload.template, state);
    case TemplatesActionsTypes.TemplateUpdated:
      return templatesAdapter.updateOne(action.payload.template, state);
    case TemplatesActionsTypes.TemplateDeleted:
      return templatesAdapter.removeOne(action.payload.id, state);
    case TemplatesActionsTypes.TemplatesClearInformation:
      return templatesAdapter.removeAll(state);
    case TemplatesActionsTypes.GettingTemplatesChange:
      return {
        ...state,
        gettingTemplates: action.payload.state,
      };
    default:
      return state;
  }
}

export const { selectAll } = templatesAdapter.getSelectors();
