import { DisplayValue } from './display-value.model';

export class RecordType {
  constructor(
    public name?: string,
    public logo?: string,
    public displayValue?: DisplayValue[],
    public id?: string,
    public count?: number,
    public sidedrawerType?: string,
    public orderId?: number,
    public sidedrawerTypeOtherName?: string,
    public branding?: string
  ) {}
}
