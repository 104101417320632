<div class="sd-selector-option">
  <img
    *ngIf="!!option.avatar && option.avatar.length > 0 && !displaySvg"
    [alt]="option.value"
    [ngClass]="{ 'rounded-avatar': roundedAvatar }"
    [src]="option.avatar"
    class="sd-selector-image" />
  <app-sd-svg
    *ngIf="!!option.avatar && option.avatar.length > 0 && displaySvg"
    [color]="'var(--secondaryAccentColor)'"
    [height]="20"
    [src]="option.avatar"
    [width]="20"
    class="sd-selector-svg"></app-sd-svg>

  <div *ngIf="!option.description" class="sd-selector-text">
    {{ option.value }}
  </div>

  <div *ngIf="option.description" class="sd-selector-text-container">
    <div [attr.data-description]="option.description" class="sd-selector-text">
      {{ option.value }}
    </div>
  </div>
</div>
