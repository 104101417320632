import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { inject } from '@angular/core';
import { SfAuthActions } from './sf-auth.store';
import { Router } from '@angular/router';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { SfRoutesEnum } from '../../routes/sf-routes.enum';

export const generalErrorModeUpdated$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const router = inject(Router);
    return actions$.pipe(
      ofType(SfAuthActions.generalErrorModeUpdated),
      switchMap(({ generalErrorMode }) => {
        if (
          generalErrorMode === 'dictionary' ||
          generalErrorMode === 'generic'
        ) {
          return fromPromise(router.navigateByUrl(`/${SfRoutesEnum.error}`));
        }
        if (generalErrorMode === '404') {
          return fromPromise(router.navigateByUrl(`/${SfRoutesEnum.notFound}`));
        }
        if (generalErrorMode === '403') {
          return fromPromise(
            router.navigateByUrl(`/${SfRoutesEnum.forbidden}`)
          );
        }
        return EMPTY;
      })
    );
  },
  { functional: true, dispatch: false }
);
