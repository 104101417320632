import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sd-flat-button',
  templateUrl: './sd-flat-button.component.html',
  styleUrls: ['./sd-flat-button.component.scss'],
})
export class SdFlatButtonComponent implements OnInit {
  @Input() primary: boolean;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() icon: string;
  @Input() iconAlt: string;
  @Input() tooltip: string;
  @Input() bgColor: string;
  @Output() buttonClicked = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    if (!this.disabled) {
      this.buttonClicked.emit(true);
    }
  }
}
