<div
  [ngClass]="
    checked ? 'sd-check-button-container checked' : 'sd-check-button-container'
  ">
  <div
    [ngClass]="checked ? 'sd-check-button checked' : 'sd-check-button'"
    class="sd-check-button">
    <div class="sd-check-button-value">
      <app-sd-svg
        *ngIf="!!svg"
        [color]="color"
        [height]="20"
        [src]="svg"
        [width]="20"></app-sd-svg>
      <img *ngIf="!!img" [alt]="value" [src]="img" />
      {{ value }}
    </div>
    <div [ngClass]="checked ? 'sd-check-button-check' : 'invisible'">
      <mat-icon>check</mat-icon>
    </div>
  </div>

  <p
    *ngIf="description"
    [ngClass]="checked ? 'checked' : ''"
    class="sd-check-button-description">
    {{ description }}
  </p>
</div>
