import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { SdSnackBarData } from '../../models/sd-snack-bar-data.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import { SdSnackBarCloseSnackBar } from '../../store/sd-snack-bar.actions';

@Component({
  selector: 'app-sd-snack-bar',
  templateUrl: './sd-snack-bar.component.html',
  styleUrls: ['./sd-snack-bar.component.scss'],
})
export class SdSnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: SdSnackBarData,
    private readonly store: Store<AppState>
  ) {}

  onAction(): void {
    this.data.action.callback();
    this.store.dispatch(new SdSnackBarCloseSnackBar());
  }
}
