import { createSelector } from '@ngrx/store';
import { sdTableStateSelector } from './sd-table.selectors';

export const pageTableStateSelector = createSelector(
  sdTableStateSelector,
  state => state?.page
);

export const sdTableColumnsSelector = createSelector(
  pageTableStateSelector,
  state => state.columns
);

export const sdTablePageSelector = createSelector(
  pageTableStateSelector,
  state => state?.page
);

export const sdTableElementsPerPageSelector = createSelector(
  pageTableStateSelector,
  state => state?.elementsPerPage
);

export const sdTableDataSelector = createSelector(
  pageTableStateSelector,
  state => state.data
);

export const sdTableFilteredDataSelector = createSelector(
  pageTableStateSelector,
  state => state.filteredData
);

export const sdTableFilteredDataLengthSelector = createSelector(
  sdTableFilteredDataSelector,
  filteredData => filteredData?.length ?? 0
);

export const sdTableElementsPerPageOptionsSelector = createSelector(
  pageTableStateSelector,
  state => state.elementsPerPageOptions
);

export const sdTableSortTableSelector = createSelector(
  pageTableStateSelector,
  state => state.sortTableBy
);

export const sdTableFilterSelector = createSelector(
  pageTableStateSelector,
  state => state.filter
);
