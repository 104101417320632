import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PlanActions, PlanActionsTypes } from './plan-list.actions';
import { Plan } from '../models/plan.model';

export interface PlanListState extends EntityState<Plan> {
  gettingPlans: boolean;
}

export const planListAdapter: EntityAdapter<Plan> = createEntityAdapter<Plan>({
  selectId: plan => plan.id,
});

export const initialPlanListState: PlanListState =
  planListAdapter.getInitialState({
    gettingPlans: false,
  });

export function planListReducer(
  state = initialPlanListState,
  action: PlanActions
): PlanListState {
  switch (action.type) {
    case PlanActionsTypes.PlansLoaded:
      return planListAdapter.upsertMany(action.payload.plans, state);
    case PlanActionsTypes.PlanAdded:
      return planListAdapter.addOne(action.payload.plan, state);
    case PlanActionsTypes.PlanUpdated:
      return planListAdapter.updateOne(action.payload.plan, state);
    case PlanActionsTypes.PlanDeleted:
      return planListAdapter.removeOne(action.payload.id, state);
    case PlanActionsTypes.PlansClearInformation:
      return planListAdapter.removeAll(state);
    case PlanActionsTypes.GettingPlansChange:
      return {
        ...state,
        gettingPlans: action.payload.state,
      };
    default:
      return state;
  }
}

export const { selectAll } = planListAdapter.getSelectors();
