import { planListReducer, PlanListState } from './plan-list.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { planItemsReducer, PlanItemState } from './plan-items.reducer';

export const featureName = 'plans';

export interface PlanState {
  list: PlanListState;
  items: PlanItemState;
}

export const planReducer: ActionReducerMap<PlanState> = {
  list: planListReducer,
  items: planItemsReducer,
};
