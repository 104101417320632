import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreRoutes } from '../../core/routes/core.routes';
import { HomeRoutes } from '../../home/routes/home.routes';
import { RoutesHelper } from '../../core/helpers/routes.helper';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard  {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.initialAuthServiceConfiguration();
  }

  async initialAuthServiceConfiguration(): Promise<boolean> {
    await this.authService.initAuth0();
    const authenticated = await this.authService.userIsAuthenticated();
    if (authenticated) {
      const queryParams = RoutesHelper.getParams(false);
      await this.router.navigate([`/${CoreRoutes.root}/${HomeRoutes.root}`], {
        queryParams,
      });
    }
    return !authenticated;
  }
}
