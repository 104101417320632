import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayMultipleRows',
})
export class DisplayMultipleRowsPipe implements PipeTransform {
  transform(value: unknown): boolean {
    return value?.toString()?.includes('$/$');
  }
}
