<div class="dashboard-dialog">
  <div class="dashboard-dialog-header">
    <div class="dashboard-dialog-header-title">{{ data.title | dictionary | async }}</div>

    <button
      (click)="onClose(false)"
      class="dashboard-dialog-header-button"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dashboard-dialog-body">
    <div class="dashboard-dialog-body-description">
      {{ data.description  | dictionary | async}}
    </div>
  </div>

  <div class="dashboard-dialog-footer">
    <app-sd-flat-button (buttonClicked)="onClose(false)" [primary]="false">
      {{ data.buttons.cancel | dictionary | async}}
    </app-sd-flat-button>

    <app-sd-flat-button (buttonClicked)="onClose(true)" [primary]="true">
      {{ data.buttons.confirm | dictionary | async }}
    </app-sd-flat-button>
  </div>
</div>
