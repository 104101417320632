import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdLocaleSelectorA11yComponent } from './components/sd-locale-selector-a11y/sd-locale-selector-a11y.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatIconModule } from '@angular/material/icon';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';

@NgModule({
  declarations: [SdLocaleSelectorA11yComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    DictionaryPipeModule,
  ],
  exports: [SdLocaleSelectorA11yComponent],
})
export class SdLocaleSelectorA11yModule {}
