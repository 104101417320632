import { MenuItemTag } from '../models/menu-item-tag.model';
import { MenuItem } from '../models/menu-item.model';
import { UsersRoutes } from '../../users/routes/users.routes';
import { TeamsRoutes } from '../../teams/routes/teams.routes';
import { PlansRoutes } from '../../plans/routes/plans.routes';
import { SubscriptionsRoutes } from '../../subscriptions/routes/subscriptions.routes';
import { FilesRoutes } from '../../files/routes/files.routes';
import { TemplatesRoutes } from '../../templates/routes/templates.routes';
import { environment } from '../../../environments/environment';
import { SecondaryMenuItemsIds } from '../models/menu-secondary-items-ids.model';
import { SideDrawerRoutes } from '../../sidedrawers/routes/side-drawer.routes';
import { FilesBulkDeliveryRoutes } from 'src/app/files-bulk-delivery/routes/files-bulk-delivery.routes';
import { PaymentMethodsRoutes } from '../../payment-methods/routes/payment-methods.routes';
import { BrandingRoutes } from 'src/app/branding/routes/branding.routes';
import { MessagesRoutes } from 'src/app/messages/routes/messages.routes';
import { TilesRoutes } from 'src/app/tiles/routes/tiles.routes';
import { InvitationsRoutes } from 'src/app/invitations/routes/invitations.routes';
import { IntegrationsRoutes } from '../../integrations/routes/integrations-routes';
import { AggregatorsRoutesEnum } from '../../aggregators/routes/aggregators-routes.enum';

export enum HomeRoutes {
  root = 'home',
  main = '',
  dashboard = 'dashboard',
}

export const primaryMenuRoutes: MenuItem[] = [
  {
    id: 0,
    itemTag: MenuItemTag.a,
    title: 'mainmenu_homelabel',
    imgSrc: 'mainmenu_homeicon',
    activeImgSrc: 'mainmenu_homeiconactive',
    tag: 'mainmenu_hometag',
    href: `/${HomeRoutes.dashboard}`,
  },
  {
    id: 1,
    itemTag: MenuItemTag.div,
    title: 'mainmenu_accountslabel',
    imgSrc: 'mainmenu_accountsicon',
    activeImgSrc: 'mainmenu_accountsiconactive',
    tag: 'mainmenu_accountstag',
    subItems: [
      {
        title: 'mainmenu_accountsclientslabel',
        href: `/${UsersRoutes.root}/${UsersRoutes.clients}`,
        tag: 'mainmenu_accountsclientstag',
      },
      {
        title: 'mainmenu_accountssdslabel',
        href: `/${SideDrawerRoutes.root}`,
        tag: 'mainmenu_accountssdstag',
      },
      {
        title: 'mainmenu_accountsrequestslabel',
        href: `/${PlansRoutes.root}/${PlansRoutes.requested}`,
        tag: 'mainmenu_accountsrequeststag',
      },
      {
        title: 'mainmenu_accountsadminslabel',
        href: `/${UsersRoutes.root}/${UsersRoutes.users}`,
        tag: 'mainmenu_accountsadminstag',
      },
      {
        title: 'mainmenu_accountsteamslabel',
        href: `/${TeamsRoutes.root}`,
        tag: 'mainmenu_accountsteamstag',
      },
      // {
      //   title: 'mainmenu_accountssponsorshipslabel',
      //   href: `/${SubscriptionsRoutes.root}/${SubscriptionsRoutes.pending}`,
      //   tag: 'mainmenu_accountssponsorshipstag',
      // },
      // {
      //   title: 'Invitations',
      //   href: `/${InvitationsRoutes.root}`,
      //   tag: 'mainmenu_accountssponsorshipstag',
      // },
    ],
  },
  {
    id: 2,
    itemTag: MenuItemTag.div,
    title: 'mainmenu_configslabel',
    imgSrc: 'mainmenu_configsicon',
    activeImgSrc: 'mainmenu_configsiconactive',
    tag: 'mainmenu_configstag',
    subItems: [
      {
        title: 'mainmenu_configsplanslabel',
        href: `/${PlansRoutes.root}/${PlansRoutes.list}`,
        tag: 'mainmenu_configsplanstag',
      },
      {
        title: 'mainmenu_configsetllabel',
        href: `/${FilesBulkDeliveryRoutes.root}`,
        tag: 'mainmenu_configsetllabel',
      },
      {
        title: 'mainmenu_configsaggregatorslabel',
        href: `/${AggregatorsRoutesEnum.root}`,
        tag: 'mainmenu_configsaggregatorslabel',
      },
      {
        title: 'mainmenu_configstemplateslabel',
        href: `/${TemplatesRoutes.root}`,
        tag: 'mainmenu_configstemplatestag',
      },
      {
        title: 'mainmenu_configsblastdeliverylabel',
        href: `/${UsersRoutes.root}/${UsersRoutes.clients}`,
        tag: 'mainmenu_configsblastdeliverystag',
        query: { key: 'launch', value: 'blast-delivery' },
        button: true,
      },
    ],
  },
  {
    id: 3,
    itemTag: MenuItemTag.div,
    title: 'mainmenu_subscriptionslabel',
    imgSrc: 'mainmenu_subscriptionsicon',
    activeImgSrc: 'mainmenu_subscriptionsiconactive',
    tag: 'mainmenu_subscriptionstag',
    subItems: [
      {
        title: 'mainmenu_subscriptionstenantlabel',
        href: `/${SubscriptionsRoutes.root}/${SubscriptionsRoutes.tenant}`,
        tag: 'mainmenu_subscriptionstenanttag',
      },
      {
        title: 'mainmenu_subscriptionspaymentlabel',
        href: `/${PaymentMethodsRoutes.root}`,
        tag: 'mainmenu_subscriptionspaymenttag',
      },
      // {
      //   title: 'mainmenu_subscriptionspersonallabel',
      //   href: `/${PaymentMethodsRoutes.root}`,
      //   tag: 'mainmenu_subscriptionspersonaltag',
      // },
    ],
  },
  {
    id: 4,
    itemTag: MenuItemTag.div,
    title: 'mainmenu_brandinglabel',
    imgSrc: 'mainmenu_brandingicon',
    activeImgSrc: 'mainmenu_brandingiconactive',
    tag: 'mainmenu_brandingtag',
    subItems: [
      {
        // TODO: ADD Dictionary (Affiliates)
        title: 'Affiliates',
        href: `/${BrandingRoutes.root}`,
        tag: 'mainmenu_brandingsetuptag',
      },
      {
        title: 'mainmenu_brandingmessageslabel',
        href: `/${MessagesRoutes.root}`,
        tag: 'mainmenu_brandingmessagestag',
      },
      {
        title: 'mainmenu_configstileslabel',
        href: `/${TilesRoutes.root}`,
        tag: 'mainmenu_configstilestag',
      },
      {
        title: 'mainmenu_brandingassetslabel',
        href: `/${FilesRoutes.root}`,
        tag: 'mainmenu_brandingassetstag',
      },
    ],
  },
  {
    id: 5,
    itemTag: MenuItemTag.div,
    title: 'mainmenu_developerslabel',
    imgSrc: 'mainmenu_developersicon',
    activeImgSrc: 'mainmenu_developersiconactive',
    tag: 'mainmenu_developerstag',
    subItems: [
      // TODO add dictionary SDD-4547
      {
        title: 'mainmenu_providerslistviewlabel',
        href: `/${IntegrationsRoutes.root}/${IntegrationsRoutes.providers}`,
        tag: 'mainmenu_developersproviderstag',
      },
    ],
  },
];

export const secondaryMenuRoutes: MenuItem[] = [
  {
    id: SecondaryMenuItemsIds.goToPage,
    itemTag: MenuItemTag.aBlank,
    title: 'mainmenu_gotoapplabel',
    href: environment.myUrl,
    imgSrc: 'mainmenu_gotoappicon',
    activeImgSrc: 'mainmenu_gotoappiconactive',
    tag: 'mainmenu_gotoapptag',
  },
  {
    id: SecondaryMenuItemsIds.myAccount,
    itemTag: MenuItemTag.div,
    title: 'mainmenu_myaccountlabel',
    imgSrc: 'mainmenu_myaccounticon',
    activeImgSrc: 'mainmenu_myaccounticonactive',
    tag: 'mainmenu_myaccounttag',
    href: 'my-account',
  },
  {
    id: SecondaryMenuItemsIds.helpSupport,
    itemTag: MenuItemTag.div,
    title: 'mainmenu_helplabel',
    imgSrc: 'mainmenu_helpicon',
    activeImgSrc: 'mainmenu_helpiconactive',
    tag: 'mainmenu_helptag',
    href: 'helpsupportvideodialog',
  },
  {
    id: SecondaryMenuItemsIds.logOut,
    itemTag: MenuItemTag.div,
    title: 'mainmenu_logoutlabel',
    imgSrc: 'mainmenu_logouticon',
    activeImgSrc: 'mainmenu_logouticonactive',
    tag: 'mainmenu_logouttag',
    href: 'logout',
  },
];
