export enum AuthRoutes {
  root = 'auth',
  main = '',
  authorize = 'authorize',
}

export function getAuthRoute(route: AuthRoutes): string {
  return (
    '/' + (route.length > 0 ? AuthRoutes.root + '/' + route : AuthRoutes.root)
  );
}
