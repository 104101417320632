import { Action } from '@ngrx/store';
import { Tenant } from '../models/tenant.model';
import { Role } from '../../users/models/role.model';

export enum TenantActionsTypes {
  TenantsLoaded = '[Tenant Service] List of Tenants Loaded',
  CurrentTenantChange = '[Home Template / Tenant Selector] Current Tenant Change',
  CurrentTenantUpdated = '[Tenant Update Dialog] Current Tenant Updated',
  CurrentRoleChange = '[Home Template / Tenant Selector] Current Role Change',
  TenantCustomDomainAvailableChange = '[Main Menu] Tenant Custom Domain Available Change',
}

export class TenantsLoaded implements Action {
  readonly type = TenantActionsTypes.TenantsLoaded;

  constructor(public payload: { tenants: Tenant[] }) {}
}

export class CurrentTenantChange implements Action {
  readonly type = TenantActionsTypes.CurrentTenantChange;

  constructor(public payload: { tenant: Tenant }) {}
}

export class CurrentTenantUpdated implements Action {
  readonly type = TenantActionsTypes.CurrentTenantUpdated;

  constructor(
    public payload: {
      tenantId: string;
      name: string;
      logoUrl: string;
      subscriptionType?: string;
    }
  ) {}
}

export class CurrentRoleChange implements Action {
  readonly type = TenantActionsTypes.CurrentRoleChange;

  constructor(public payload: { role: Role }) {}
}

export class TenantCustomDomainAvailableChange implements Action {
  readonly type = TenantActionsTypes.TenantCustomDomainAvailableChange;

  constructor(public payload: { state: boolean }) {}
}

export type TenantActions =
  | TenantsLoaded
  | CurrentTenantUpdated
  | CurrentTenantChange
  | CurrentRoleChange
  | TenantCustomDomainAvailableChange;
