import { NgModule } from '@angular/core';
import { accountReducer, featureName } from './store/account.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AccountEffects } from './store/account.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(featureName, accountReducer),
    EffectsModule.forFeature([AccountEffects]),
  ],
})
export class AccountModule {}
