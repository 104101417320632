import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatesRoutingModule } from './templates-routing.module';
import { StoreModule } from '@ngrx/store';
import { featureName, templatesReducer } from './store/templates.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TemplatesEffects } from './store/templates.effects';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    TemplatesRoutingModule,
    StoreModule.forFeature(featureName, templatesReducer),
    EffectsModule.forFeature([TemplatesEffects]),
  ],
})
export class TemplatesModule {}
