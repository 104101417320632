<div *ngIf="loading" class="spinner">
  <mat-progress-spinner
    diameter="20"
    mode="indeterminate"></mat-progress-spinner>
</div>

<button
  (click)="onClick()"
  *ngIf="!loading"
  [disabled]="disabled"
  [matTooltip]="!!tooltip ? tooltip : ''"
  class="alert-button"
  mat-flat-button
  #matTooltip="matTooltip"
  (mouseleave)="!!tooltip ? matTooltip.hide() : ''">
  <div
    *ngIf="!!icon"
    [ngStyle]="{
      '-webkit-mask': 'url(' + icon + ') no-repeat 50% 50%',
      mask: 'url(' + icon + ') no-repeat 50% 50%'
    }"
    class="icon"></div>
  <span class="text">
    <ng-content></ng-content>
  </span>
</button>
