import { Action } from '@ngrx/store';
import { Settings } from '../models/settings.model';
import { Account } from '../models/account.model';

export enum AccountActionsTypes {
  AccountLoaded = '[Account Service] Account Loaded',
  AccountUpdated = '[Account Service] Account Updated',
  CustomerIdCreated = '[Account Service] Customer Id Created',
  SettingsLoaded = '[Account Service] Settings Loaded',
  AccountAndSettingsRequested = '[] Account and User Settings Requested',
  GettingAccountChange = '[] Getting Account Change',
  GettingSettingsChange = '[] Getting Settings Change',
  GettingAccountOrSettingsFail = '[] Getting Account or User Settings Fail',
}

export class AccountLoaded implements Action {
  readonly type = AccountActionsTypes.AccountLoaded;

  constructor(public payload: { data: Account }) {}
}

export class AccountUpdated implements Action {
  readonly type = AccountActionsTypes.AccountUpdated;

  constructor(public payload: { data: Account }) {}
}

export class CustomerIdCreated implements Action {
  readonly type = AccountActionsTypes.CustomerIdCreated;

  constructor(public payload: { customerId: string }) {}
}

export class SettingsLoaded implements Action {
  readonly type = AccountActionsTypes.SettingsLoaded;

  constructor(public payload: { data: Settings }) {}
}

export class AccountAndSettingsRequested implements Action {
  readonly type = AccountActionsTypes.AccountAndSettingsRequested;
}

export class GettingAccountOrSettingsFail implements Action {
  readonly type = AccountActionsTypes.GettingAccountOrSettingsFail;
}

export class GettingAccountChange implements Action {
  readonly type = AccountActionsTypes.GettingAccountChange;

  constructor(public payload: { state: boolean }) {}
}

export class GettingSettingsChange implements Action {
  readonly type = AccountActionsTypes.GettingSettingsChange;

  constructor(public payload: { state: boolean }) {}
}

export type AccountActions =
  | AccountLoaded
  | CustomerIdCreated
  | AccountUpdated
  | SettingsLoaded
  | AccountAndSettingsRequested
  | GettingAccountOrSettingsFail
  | GettingAccountChange
  | GettingSettingsChange;
