import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'app-sd-text-area',
  templateUrl: './sd-text-area.component.html',
  styleUrls: ['./sd-text-area.component.scss'],
})
export class SdTextAreaComponent implements OnInit {
  @Input() error: string;
  @Input() controller: UntypedFormControl;
  @Input() placeholder: string;
  @Input() tooltip: string;
  @Input() fieldInputSelected = '';

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(private readonly ngZone: NgZone) {}
  ngOnInit(): void {}

  triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }
}
