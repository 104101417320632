import { SdTableColumn } from '../models/sd-table-column.model';
import { SdTableSortEvent } from '../models/sd-table-sort-event.model';
import {
  TableDialogActions,
  TableDialogActionsTypes,
} from './table-dialog.actions';
import { SdTableHelper } from '../helper/sd-table.helper';

export interface SdTableDialogState {
  columns: SdTableColumn[];
  sortTableBy: SdTableSortEvent;
  data: any;
  filter: string;
  filteredData: any;
  page: number;
  elementsPerPage: number;
  elementsPerPageOptions: number[];
}

export const initialSdTableDialogState: SdTableDialogState = {
  columns: null,
  sortTableBy: null,
  data: null,
  filter: null,
  filteredData: null,
  page: 0,
  elementsPerPage: 25,
  elementsPerPageOptions: [10, 25, 50],
};

export function sdTableDialogReducer(
  state: SdTableDialogState = initialSdTableDialogState,
  action: TableDialogActions
): SdTableDialogState {
  switch (action.type) {
    case TableDialogActionsTypes.ClearTableDialogData:
      return initialSdTableDialogState;
    case TableDialogActionsTypes.LoadTableDialogData:
      return {
        ...state,
        data: action.payload.data,
        filteredData: SdTableHelper.sortFilterAndSlice(
          action.payload.data,
          state.sortTableBy,
          state.filter,
          state.page,
          state.elementsPerPage
        ),
      };
    case TableDialogActionsTypes.TableDialogColumnsChange:
      return {
        ...state,
        columns: action.payload.columns,
      };
    case TableDialogActionsTypes.TableDialogSortByDefault:
      return {
        ...state,
        sortTableBy: action.payload.sort,
      };
    case TableDialogActionsTypes.TableDialogElementsPerPagesChange:
      return {
        ...state,
        elementsPerPage: action.payload.elementsPerPage,
        filteredData: SdTableHelper.sortFilterAndSlice(
          state.data,
          state.sortTableBy,
          state.filter,
          state.page,
          action.payload.elementsPerPage
        ),
      };
    case TableDialogActionsTypes.TableDialogFilterChange:
      return {
        ...state,
        filter: action.payload.filter,
        filteredData: SdTableHelper.sortFilterAndSlice(
          state.data,
          state.sortTableBy,
          action.payload.filter,
          state.page,
          state.elementsPerPage
        ),
      };
    case TableDialogActionsTypes.TableDialogPageChange:
      return {
        ...state,
        page: action.payload.page,
        filteredData: SdTableHelper.sortFilterAndSlice(
          state.data,
          state.sortTableBy,
          state.filter,
          action.payload.page,
          state.elementsPerPage
        ),
      };
    case TableDialogActionsTypes.TableDialogSortChange:
      return {
        ...state,
        sortTableBy: action.payload.event,
      };
    case TableDialogActionsTypes.TableDialogElementsPerPagesOptionsChange:
      return {
        ...state,
        elementsPerPageOptions: action.payload.defaultAmount
          ? initialSdTableDialogState.elementsPerPageOptions
          : [10, 25, 50, 100, 150, 300],
      };
    default:
      return state;
  }
}
