<mat-form-field (click)="picker.open()">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    (dateChange)="onDateChange($event.value)"
    [formControl]="controller"
    [matDatepicker]="picker"
    [max]="maxDate"
    [min]="minDate"
    matInput
    readonly />
  <div class="suffix-container" matSuffix>
    <div *ngIf="!!suffixText && suffixText.length > 0">
      <span>{{ suffixText }}</span>
    </div>
    <app-sd-svg
      *ngIf="suffixIsImage"
      [color]="primaryAccentColor"
      [height]="20"
      [src]="cdn + 'svgs/calendar-day-regular.svg'"
      [width]="20"></app-sd-svg>

    <app-sd-icon-button
      (click)="onSuffix()"
      *ngIf="!!suffix && suffix.length > 0 && !suffixIsImage"
      [disabled]="!suffixIsClickable"
      [icon]="suffix"
      [primary]="suffixIconHasPrimaryColor"></app-sd-icon-button>
  </div>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error *ngIf="controller.invalid">
    <span>{{ error }}</span>
  </mat-error>
</mat-form-field>
