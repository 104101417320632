import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Auth0Client,
  createAuth0Client,
  RedirectLoginResult,
} from '@auth0/auth0-spa-js';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { tokenSelector } from '../store/auth.selectors';
import { RoutesHelper } from '../../core/helpers/routes.helper';
import { CreateAuthUserDto } from '../models/create-auth-user.dto';
import { CreateAuthUserResponseDto } from '../models/create-auth-user-response.dto';
import { TenantRoutes } from '../../tenants/routes/tenant.routes';
import * as auth0 from 'auth0-js';
import { AuthorizeUser, LogIn, LogOut } from '../store/auth.actions';
import { AuthUser } from '../models/user.model';
import { CoreRoutes } from '../../core/routes/core.routes';
import { HomeRoutes } from '../../home/routes/home.routes';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  private auth0: Auth0Client;
  private token: string;
  private token$: Observable<string> = this.store.pipe(
    select(tokenSelector),
    tap(token => {
      this.token = token;
    })
  );
  private webAuth;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
    private readonly router: Router
  ) {
    this.token$.subscribe();
  }

  static getRedirectURL(withQuery = true): string {
    return (
      window.location.origin +
      environment.redirectUri +
      (withQuery ? RoutesHelper.getQuery() : '')
    );
  }

  getHeaders(): {
    Authorization: string;
    Accept: string;
    'Content-Type': string;
  } {
    return {
      Authorization: 'Bearer ' + this.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  async initAuth0(): Promise<void> {
    this.auth0 = await createAuth0Client({
      domain: environment.domain,
      clientId: environment.clientID,
      useRefreshTokens: false,
      authorizationParams: {
        audience: environment.audience,
        scope: environment.scope,
        redirect_uri: AuthService.getRedirectURL(),
      },
    });
  }

  handleRedirect(): Promise<RedirectLoginResult> {
    return this.auth0.handleRedirectCallback();
  }

  logInWithRedirect(): Promise<void> {
    return this.auth0.loginWithRedirect();
  }

  async userIsAuthenticated(): Promise<boolean> {
    if (!this.auth0) {
      await this.initAuth0();
    }
    return this.auth0.isAuthenticated();
  }

  async getAuth0User(): Promise<any> {
    return this.auth0.getUser();
  }

  async getTokenSilently(): Promise<any> {
    return this.auth0.getTokenSilently({ cacheMode: 'off' });
  }

  async initAuth0AndGetTokenSilently(): Promise<any> {
    await this.initAuth0();
    return this.auth0.getTokenSilently({ cacheMode: 'off' });
  }

  resetPassword(email: string): Observable<boolean> {
    return this.http
      .post(
        `https://${environment.domain}/dbconnections/change_password`,
        {
          client_id: environment.clientID,
          email,
          connection: environment.connection,
        },
        {
          responseType: 'text',
        }
      )
      .pipe(
        map(() => {
          return true;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  logOut(): void {
    Intercom('shutdown');
    this.auth0
      .logout({
        clientId: environment.clientID,
        logoutParams: {
          returnTo: AuthService.getRedirectURL(false),
        },
      })
      .then();
  }

  async initCredentials(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const origin = urlParams.get('origin');
    const authenticated = await this.userIsAuthenticated();
    if (authenticated) {
      const user = await this.getAuth0User();
      const token = await this.getTokenSilently().catch(() => {
        this.store.dispatch(new LogOut());
        return null;
      });
      this.store.dispatch(new LogIn({ token, user: AuthUser.fromAuth0(user) }));
      const queryParams = RoutesHelper.getParams(false);
      const url =
        !!origin &&
        origin !== '/' &&
        origin !== '/auth/authorize' &&
        !origin.includes('/core/error') &&
        !origin.includes('/core/forbidden')
          ? decodeURIComponent(origin.split('?')[0])
          : `/${CoreRoutes.root}/${HomeRoutes.root}`;
      await this.router.navigate([url], { queryParams });
      return;
    }
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    if (!!code && code.length > 0 && !!state && state.length > 0) {
      await this.handleRedirect();
      this.store.dispatch(new AuthorizeUser());
      return;
    }
    await this.logInWithRedirect();
  }

  checkIfEmailIsAvailable(email): Observable<boolean> {
    return this.http
      .get<string>(
        environment.userApi +
          `accounts/username/${encodeURIComponent(email)}/check`
      )
      .pipe(
        map(() => false),
        catchError((error: HttpErrorResponse) => of(error.status === 404))
      );
  }

  createAuthUser(
    newUser: CreateAuthUserDto
  ): Observable<CreateAuthUserResponseDto> {
    return this.http.post<CreateAuthUserResponseDto>(
      `https://${environment.domain}/dbconnections/signup`,
      newUser,
      { headers: { 'Content-Type': 'application/json' } }
    );
  }

  loginFromConsole(
    email: string,
    password: string
  ): Promise<{ accessToken: string; idToken: string }> {
    return new Promise<{ accessToken: string; idToken: string }>(
      (resolve, reject) => {
        this.webAuth = new auth0.WebAuth({
          domain: environment.domain,
          clientID: environment.clientID,
          scope: environment.scope,
          audience: environment.audience,
          redirectUri: `${window.location.origin}/${TenantRoutes.processing}`,
        });
        this.webAuth.login(
          {
            realm: environment.connection,
            username: email,
            audience: environment.audience,
            password,
            responseType: 'code',
          },
          (err, authResult) => {
            console.log(authResult);
            if (err) {
              console.error(err);
              reject(err);
              return;
            }
            resolve({
              idToken: authResult?.idToken,
              accessToken: authResult?.accessToken,
            });
          }
        );
      }
    );
  }
}
