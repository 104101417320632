import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { tokenSelector } from '../store/auth.selectors';
import { map } from 'rxjs/operators';
import { AuthRoutes, getAuthRoute } from '../routes/auth.routes';
import { RoutesHelper } from '../../core/helpers/routes.helper';
import { CoreRoutes } from '../../core/routes/core.routes';
import { HomeRoutes } from '../../home/routes/home.routes';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router
  ) {}

  canActivate(
    route: AngularActivatedRoute,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.pipe(
      select(tokenSelector),
      map(token => {
        if (!token) {
          const url =
            route._routerState.url ?? `/${CoreRoutes.root}/${HomeRoutes.root}`;
          const queryParams = RoutesHelper.getParams(false);
          this.router.navigate([getAuthRoute(AuthRoutes.authorize)], {
            queryParams: { ...queryParams, origin: url },
          });
        }
        return !!token;
      })
    );
  }
}

interface AngularActivatedRoute extends ActivatedRouteSnapshot {
  _routerState: {
    url: string;
  };
}
