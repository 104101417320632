import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/services/auth.service';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Settings } from '../models/settings.model';
import { Mfa } from '../models/mfa.model';
import { AccountLoaded, SettingsLoaded } from '../store/account.actions';
import { Account } from '../models/account.model';

@Injectable()
export class AccountService {
  private userApi = environment.userApi;

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store<AppState>,
    private readonly http: HttpClient
  ) {}

  getAccountByOpenId(openId: string): Observable<Account> {
    return this.http
      .get<Account>(this.userApi + `accounts/open-id/${openId}`, {
        headers: this.authService.getHeaders(),
      })
      .pipe(
        tap(account => {
          if (account) {
            this.store.dispatch(new AccountLoaded({ data: account }));
          }
        })
      );
  }

  getAccountSettings(accountId: string): Observable<Settings> {
    return this.http
      .get<Settings>(
        this.userApi + `accounts/account-id/${accountId}/settings`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(
        tap(settings => {
          if (settings) {
            this.store.dispatch(new SettingsLoaded({ data: settings }));
          }
        })
      );
  }

  createMfaCode(
    accountId: string,
    mfa: Mfa,
    brandCode: string
  ): Observable<boolean> {
    return this.http
      .post(
        this.userApi +
          `accounts/account-id/${accountId}/mfa?brandCode=${brandCode}`,
        mfa,
        {
          headers: this.authService.getHeaders(),
          observe: 'response',
        }
      )
      .pipe(
        map(response => {
          return response.status === 201;
        })
      );
  }

  checkMfaCode(accountId: string, code: string): Observable<boolean> {
    return this.http
      .get(
        this.userApi + `accounts/account-id/${accountId}/mfa/mfa-code/${code}`,
        {
          headers: this.authService.getHeaders(),
          observe: 'response',
        }
      )
      .pipe(
        map(response => {
          return response.status === 200;
        })
      );
  }

  updateAccountSettings(
    account: Account,
    settings: Settings
  ): Observable<Settings> {
    return this.http
      .put<Settings>(
        this.userApi + `accounts/account-id/${account.id}/settings`,
        { ...settings },
        {
          headers: this.authService.getHeaders(),
          observe: 'response',
        }
      )
      .pipe(
        map(response => {
          this.store.dispatch(new SettingsLoaded({ data: settings }));
          return response.body;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  createAccount(
    newAccount: Account,
    brandCode: string
  ): Observable<{ id: string }> {
    let body = {};
    body = { ...body, ...newAccount };
    return this.http.post<{ id: string }>(
      this.userApi + `accounts?brandCode=${brandCode}`,
      body,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getIntercomHash(email: string): Observable<{ hash: string }> {
    return this.http.post<{ hash: string }>(
      environment.intercomHashUrl,
      { email },
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  checkUserName(userName: string): Observable<string> {
    return this.http.get<string>(
      this.userApi + `accounts/username/${userName}/check`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }
}
