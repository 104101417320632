<button
  (click)="!disabled ? buttonClicked.emit(true) : null"
  [disableRipple]="true"
  [disabled]="disabled"
  [matTooltip]="tooltip"
  [ngStyle]="{ 'background-color': backgroundColor }"
  [tabIndex]="tabIndex"
  class="sd-svg-button"
  mat-icon-button
  #matTooltip="matTooltip"
  (mouseleave)="!!tooltip ? matTooltip.hide() : ''">
  <app-sd-svg
    [color]="color"
    [height]="height"
    [src]="src"
    [width]="width"></app-sd-svg>
</button>
