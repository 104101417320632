import { Action } from '@ngrx/store';
import { Plan } from '../models/plan.model';
import { Update } from '@ngrx/entity';

export enum PlanActionsTypes {
  PlansRequested = '[] Plans Requested',
  PlansLoaded = '[Plan Effects] Plans Loaded',
  PlanAdded = '[New Plan Dialog] New Plan Added',
  PlanUpdated = '[Plan Table / Plan details view] Plan Updated',
  PlanDeleted = '[Plan Table / Plan details view] Plan Deleted',
  PlansClearInformation = '[Switch Tenant] Clear all Plan information',
  GettingPlansChange = '[Plan Effects] Getting Plans Change',
}

export class PlansRequested implements Action {
  readonly type = PlanActionsTypes.PlansRequested;
}

export class PlansLoaded implements Action {
  readonly type = PlanActionsTypes.PlansLoaded;

  constructor(public payload: { plans: Plan[] }) {}
}

export class PlanAdded implements Action {
  readonly type = PlanActionsTypes.PlanAdded;

  constructor(public payload: { plan: Plan }) {}
}

export class PlanUpdated implements Action {
  readonly type = PlanActionsTypes.PlanUpdated;

  constructor(public payload: { plan: Update<Plan> }) {}
}

export class PlanDeleted implements Action {
  readonly type = PlanActionsTypes.PlanDeleted;

  constructor(public payload: { id: string }) {}
}

export class PlansClearInformation implements Action {
  readonly type = PlanActionsTypes.PlansClearInformation;
}

export class GettingPlansChange implements Action {
  readonly type = PlanActionsTypes.GettingPlansChange;

  constructor(public payload: { state: boolean }) {}
}

export type PlanActions =
  | PlansRequested
  | PlansLoaded
  | PlanAdded
  | PlanUpdated
  | PlanDeleted
  | PlansClearInformation
  | GettingPlansChange;
