<mat-form-field>
  <textarea
    *ngIf="fieldInputSelected === 'string'"
    #autosize="cdkTextareaAutosize"
    [formControl]="controller"
    [placeholder]="placeholder"
    cdkAutosizeMaxRows="200"
    cdkAutosizeMinRows="1"
    cdkTextareaAutosize
    maxlength="255"
    matInput></textarea>
  <textarea
    *ngIf="fieldInputSelected !== 'string'"
    #autosize="cdkTextareaAutosize"
    [formControl]="controller"
    [placeholder]="placeholder"
    cdkAutosizeMaxRows="200"
    cdkAutosizeMinRows="1"
    cdkTextareaAutosize
    matInput></textarea>
  <div class="suffix-container" matSuffix>
    <app-sd-tooltip-button
      *ngIf="!!tooltip && tooltip.length > 0"
      [tooltip]="tooltip"></app-sd-tooltip-button>
  </div>
  <mat-error *ngIf="controller.invalid">
    <span>{{ error }}</span>
  </mat-error>
</mat-form-field>
