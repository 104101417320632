<div class="sd-table">
  <div class="sd-table-header">
    <app-sd-search
      (filter)="onFilterChange($event)"
      [placeholder]="filterPlaceholder">
    </app-sd-search>

    <div *ngIf="optionFilter === true" class="sd-table-filter">
      <app-sd-selector
        [placeholder]="optionFilterPlaceholder"
        [controller]="optionFilterController"
        [options]="optionsForFilter"></app-sd-selector>
    </div>

    <div *ngIf="localeFilter === true" class="sd-table-actions">
      <app-sd-locale-selector-a11y
        [template]="sdLocaleSelectorA11yTemplates.menu"
        [icon]="languageIcon"
        (optionSelected)="onLocaleFilterSelected($event)"
        [controller]="languageController"
        [options]="localesFromSignal()"
        [tooltip]="'tableparams_tablelocalefilterlabel' | dictionary | async" />
    </div>

    <div class="sd-table-actions">
      <button
        [matMenuTriggerFor]="menuAllColumns"
        [matTooltip]="'tableparams_tablefieldsconfiglabel' | dictionary | async"
        class="sd-icon-button header-manage-sidedrawer-button"
        mat-button>
        <div
          [ngStyle]="{
            '-webkit-mask': 'url(' + tableIcon + ') no-repeat 50% 50%',
            mask: 'url(' + tableIcon + ') no-repeat 50% 50%',
            'background-color': 'var(--tertiaryAccentColor)'
          }"
          class="sd-icon-button-icon"></div>
      </button>
    </div>
    <div class="sd-table-actions">
      <button
        (click)="exportAllDataToExcel()"
        [matTooltip]="'tableparams_tabledataexportlabel' | dictionary | async"
        class="sd-icon-button header-manage-sidedrawer-button"
        mat-button>
        <div
          [ngStyle]="{
            '-webkit-mask': 'url(' + excelIcon + ') no-repeat 50% 50%',
            mask: 'url(' + excelIcon + ') no-repeat 50% 50%',
            'background-color': 'var(--tertiaryAccentColor)'
          }"
          class="sd-icon-button-icon"></div>
      </button>
    </div>
    <div class="spacer"></div>
    <mat-menu #menuAllColumns="matMenu" class="sd-table-menu-all-columns">
      <div
        (cdkDropListDropped)="dropItemColumn($event)"
        [cdkDropListData]="columnsForEdit"
        cdkDropList>
        <div
          (click)="$event.stopPropagation()"
          *ngFor="
            let itemColumn of columnsForEdit;
            let i = index;
            trackBy: sdTableHeaderTrackByFn
          "
          [cdkDragData]="itemColumn"
          [ngStyle]="{
            padding: '15px',
            display: 'flex',
            'flex-direction': 'column',
            gup: '5',
            cursor: 'grab'
          }"
          cdkDrag>
          <div class="sd-table-items-columns-container">
            <app-sd-svg
              [color]="'var(--tertiaryAccentColor)'"
              [height]="20"
              [src]="dragIcon"
              [width]="20"></app-sd-svg>
            <div>
              {{ itemColumn.title }}
            </div>
            <app-sd-checkbox
              (valueChange)="onValueColumnChange($event, itemColumn, i)"
              [value]="!!itemColumn.visible ? itemColumn.visible : null">
            </app-sd-checkbox>
          </div>
        </div>
      </div>
    </mat-menu>
    <app-sd-flat-button
      (buttonClicked)="actionButtonsClicked.emit(actionButton.key)"
      *ngFor="let actionButton of actionButtons"
      [disabled]="!actionButton.enable"
      [primary]="actionButton.primary"
      class="sd-table-header-btn"
      >{{ actionButton.title }}
    </app-sd-flat-button>
  </div>
  <div class="sd-table-table">
    <div *ngIf="spinner" class="sd-table-progress-spinner">
      <mat-progress-bar
        class="primary-progress-bar"
        mode="indeterminate"></mat-progress-bar>
    </div>

    <table
      mat-table
      class="responsive-table"
      [trackBy]="sdTableTrackByFn"
      (cdkDropListDropped)="onDropRowCheck() ? onDropRow($event) : null"
      (matSortChange)="onSortData($event)"
      [cdkDropListData]="filteredData$ | async"
      [dataSource]="filteredData$ | async"
      cdkDropList
      matSort>
      <ng-container
        *ngFor="let column of columns$ | async"
        [matColumnDef]="column.definition">
        <th mat-header-cell *matHeaderCellDef>
          <div class="sd-table-container-for-order-column">
            <div
              (click)="orderByColumnValue(column.key, 'asc')"
              class="sd-table-container-for-order-column-clickable">
              <span>
                {{ column.title }}
              </span>
            </div>
            <app-sd-icon-button
              (btnClicked)="orderByColumnValue(column.key, 'asc')"
              *ngIf="
                column.key === (sortTable$ | async)?.active &&
                (sortTable$ | async)?.direction === 'desc'
              "
              [icon]="'keyboard_arrow_up'"
              [primary]="false">
            </app-sd-icon-button>
            <app-sd-icon-button
              (btnClicked)="orderByColumnValue(column.key, 'desc')"
              *ngIf="
                column.key === (sortTable$ | async)?.active &&
                (sortTable$ | async)?.direction === 'asc'
              "
              [icon]="'keyboard_arrow_down'"
              [primary]="false">
            </app-sd-icon-button>
          </div>
        </th>
        <td
          mat-cell
          #tooltip="matTooltip"
          (contextmenu)="
            column.cellType !== SdTableCellType.svg &&
            column.cellType !== SdTableCellType.img
              ? onRightClick($event, element[column.key])
              : null
          "
          (mouseleave)="tooltip.hide()"
          *matCellDef="let element"
          [attr.colspan]="
            !!element[column.key + '-colspan']
              ? element[column.key + '-colspan']
              : 1
          "
          [attr.label]="!!column.title ? column.title : ''"
          [matTooltipShowDelay]="800"
          [matTooltip]="element[column.key] | sdTableTooltip: column.cellType"
          [ngClass]="element[column.key + '-colspan'] === 0 ? 'hidden' : null"
          [style.font-weight]="
            element[column?.keyToCheckActiveBoldStyle] ? 'bold' : null
          ">
          <div
            *ngIf="column.cellType === SdTableCellType.string"
            class="string-cell">
            <span *ngIf="!(element[column.key] | displayMultipleRows)">{{
              element[column.key]
            }}</span>
            <div
              *ngIf="element[column.key] | displayMultipleRows"
              class="multiple-row-cell">
              <div class="multiple-row-cell-header">
                {{ element[column.key] | displayMultipleRowsHeader }}
              </div>
              <div class="multiple-row-cell-sub-header">
                {{ element[column.key] | displayMultipleRowsSubHeader }}
              </div>
            </div>
          </div>

          <div *ngIf="column.cellType === SdTableCellType.svg" class="img-cell">
            <app-sd-svg
              [color]="
                !!element[column.key]?.color
                  ? element[column.key]?.color
                  : 'var(--tertiaryAccentColor)'
              "
              [height]="20"
              [src]="
                !!element[column.key]?.url
                  ? element[column.key]?.url
                  : element[column.key]
              "
              [width]="20"></app-sd-svg>
          </div>

          <div *ngIf="column.cellType === SdTableCellType.img" class="img-cell">
            <img
              *ngIf="!!element[column.key] && element[column.key]?.length > 0"
              [alt]="column.title"
              [src]="element[column.key]" />
          </div>

          <div *ngIf="column.cellType === SdTableCellType.toggle">
            <mat-slide-toggle
              (change)="onToggleChange($event?.checked, element, column.key)"
              [checked]="element[column.key]"
              color="primary"></mat-slide-toggle>
          </div>

          <div
            *ngIf="column.cellType === SdTableCellType.html"
            [innerHTML]="element[column.key] | safeHtml"
            class="html-cell ck-content"></div>

          <div *ngIf="column.cellType === SdTableCellType.date">
            <span>{{ element[column.key] | sdDate }}</span>
          </div>

          <div *ngIf="column.cellType === SdTableCellType.currency">
            <span
              >{{ element[column.key]?.symbol }}
              {{ element[column.key]?.value | number: '1.2-2' }}</span
            >
          </div>
        </td>
      </ng-container>

      <!-- Reorder Column -->
      <ng-container matColumnDef="reorder">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell>
          <div class="sd-table-actions-draggable-cell">
            <app-sd-svg
              [color]="'var(--tertiaryAccentColor)'"
              [height]="20"
              [src]="dragIcon"
              [width]="20"></app-sd-svg>
          </div>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>
          <div class="sd-table-action-header">{{ actionsPlaceholder }}</div>
        </th>
        <td *matCellDef="let element" mat-cell>
          <div class="sd-table-actions-cell">
            <ng-container *ngFor="let iconAction of rowIconActions">
              <app-sd-svg-button
                *ngIf="
                  !!iconAction.disabled &&
                  (iconAction.disabled(element, store) | async)
                "
                [color]="'var(--tertiaryAccentColor)'"
                [height]="20"
                (buttonClicked)="
                  rowButtonsClicked.emit({
                    item: element,
                    key: iconAction.disabledKey
                  })
                "
                [src]="iconAction.src"
                [tooltip]="iconAction.tooltip"
                [width]="20" />
              <app-sd-svg-button
                *ngIf="
                  !(
                    !!iconAction.disabled &&
                    (iconAction.disabled(element, store) | async)
                  )
                "
                [color]="'var(--tertiaryAccentColor)'"
                [height]="20"
                (buttonClicked)="
                  rowButtonsClicked.emit({
                    item: element,
                    key: iconAction.key
                  })
                "
                [src]="iconAction.src"
                [tooltip]="iconAction.tooltip"
                [width]="20" />
            </ng-container>

            <app-sd-svg-button
              *ngIf="
                rowActions.multipleActions?.length > 0 ||
                rowActions.secondaryMultipleActions?.length > 0
              "
              [color]="'var(--tertiaryAccentColor)'"
              [height]="20"
              [matMenuTriggerData]="{ element: element }"
              [matMenuTriggerFor]="contextMenu"
              [src]="ellipsisIcon"
              [width]="20"
              class="sd-table-ellipsis-button" />
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns$ | async"></tr>
      <ng-template [ngIf]="sortableTableWithDraggingRows">
        <tr
          mat-row
          *matRowDef="let row; columns: displayColumns$ | async"
          [cdkDragData]="row"
          cdkDrag></tr>
      </ng-template>
      <ng-template [ngIf]="!sortableTableWithDraggingRows">
        <tr *matRowDef="let row; columns: displayColumns$ | async" mat-row></tr>
      </ng-template>
    </table>
  </div>
  <mat-paginator
    (page)="onChangePage($event)"
    [length]="totalItems ?? data?.length"
    [pageIndex]="page$ | async"
    [pageSizeOptions]="elementsPerPageOptions$ | async"
    [pageSize]="elementsPerPage$ | async" />
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template let-element="element" matMenuContent>
    <ng-container
      *ngFor="
        let action of rowActions.multipleActions;
        index as i;
        trackBy: rowActionsTrackByFn
      ">
      <button
        (click)="
          rowButtonsClicked.emit({ item: element, key: action.disabledKey })
        "
        *ngIf="!!action.disabled && (action.disabled(element, store) | async)"
        class="sd-table-mat-menu-button sd-table-mat-menu-button-disabled"
        mat-menu-item>
        {{ action.title }}
      </button>

      <button
        (click)="rowButtonsClicked.emit({ item: element, key: action.key })"
        *ngIf="
          !(!!action.disabled && (action.disabled(element, store) | async))
        "
        [ngClass]="action.primary ? 'sd-table-mat-menu-button-primary' : null"
        class="sd-table-mat-menu-button"
        mat-menu-item>
        {{ action.title }}
      </button>
    </ng-container>

    <div
      *ngIf="
        rowActions?.multipleActions.length > 0 &&
        rowActions?.secondaryMultipleActions.length > 0
      "
      class="border"></div>

    <ng-container
      *ngFor="
        let action of rowActions.secondaryMultipleActions;
        index as i;
        trackBy: rowActionsTrackByFn
      ">
      <button
        (click)="
          rowButtonsClicked.emit({ item: element, key: action.disabledKey })
        "
        *ngIf="!!action.disabled && (action.disabled(element, store) | async)"
        class="sd-table-mat-menu-button sd-table-mat-menu-button-disabled"
        mat-menu-item>
        {{ action.title }}
      </button>

      <button
        (click)="rowButtonsClicked.emit({ item: element, key: action.key })"
        *ngIf="
          !(!!action.disabled && (action.disabled(element, store) | async))
        "
        [ngClass]="action.primary ? 'sd-table-mat-menu-button-primary' : null"
        class="sd-table-mat-menu-button"
        mat-menu-item>
        {{ action.title }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<!-- NOTE hidden div for right menu -->
<div
  #selectorForTriggerRightMenu="matMenuTrigger"
  [matMenuTriggerFor]="rightMenu"
  [style.left]="rightMenuTopLeftPosition.x"
  [style.top]="rightMenuTopLeftPosition.y"
  style="visibility: hidden; position: fixed"></div>

<mat-menu #rightMenu="matMenu">
  <ng-template let-item="item" matMenuContent>
    <button
      (click)="copyCellValueToClipboard(item)"
      class="sd-table-mat-menu-button"
      mat-menu-item>
      {{ 'globalparams_copytoclipboard' | dictionary | async }}
    </button>
  </ng-template>
</mat-menu>
