import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../auth/services/auth.service';
import { environment } from '../../../environments/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { SideDrawer } from '../../sidedrawers/models/side-drawer.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { CreateTemplateDto } from '../models/create-template.dto';
import {
  currentRoleSelector,
  currentTenantSelector,
  userHasBrandRoleSelector,
} from '../../tenants/store/tenant.selectors';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { SubscriptionFeatures } from '../../subscriptions/models/subscription-features.model';
import { accountSelector } from '../../account/store/account.selector';
import { SubscriptionsService } from '../../subscriptions/services/subscriptions.service';

@Injectable()
export class TemplatesService {
  private tenantApi = environment.tenantApi;

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly store: Store<AppState>,
    private readonly subscriptionsService: SubscriptionsService
  ) {}

  getTemplatesByBrandCode(): Observable<SideDrawer[]> {
    return forkJoin([
      this.store.pipe(select(currentTenantSelector), take(1)),
      this.store.pipe(select(currentRoleSelector), take(1)),
      this.store.pipe(select(userHasBrandRoleSelector), take(1)),
    ]).pipe(
      mergeMap(([tenant, role, userHasBrandRole]) => {
        const operation = userHasBrandRole
          ? this.getTemplates(tenant?.id, role.brandCode)
          : this.getTemplates(tenant?.id);
        return operation.pipe(
          catchError(() => of([])),
          map(templates => {
            try {
              return templates.sort((a, b) =>
                UtilsHelper.compareStrings(a.name, b.name)
              );
            } catch (e) {
              console.warn(e);
              return templates;
            }
          })
        );
      })
    );
  }

  getTemplates(tenantId: string, brandCode?: string): Observable<SideDrawer[]> {
    return this.http.get<SideDrawer[]>(
      this.tenantApi +
        `tenant/tenant-id/${tenantId}/templates${
          brandCode ? '?brandCode=' + brandCode : ''
        }`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  createTemplate(
    tenantId: string,
    body: CreateTemplateDto
  ): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      this.tenantApi + `tenant/tenant-id/${tenantId}/templates`,
      body,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  deleteTemplate(tenantId: string, templateId: string): Observable<boolean> {
    return this.http
      .delete(
        this.tenantApi +
          `tenant/tenant-id/${tenantId}/templates/template-id/${templateId}`,
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(map(() => true));
  }

  getSubscriptionsFeaturesWithoutATemplate(): Observable<SubscriptionFeatures> {
    return forkJoin([
      this.store.pipe(select(currentTenantSelector), take(1)),
      this.store.pipe(select(accountSelector), take(1)),
      this.store.pipe(select(currentRoleSelector), take(1)),
    ]).pipe(
      mergeMap(([tenant, account, role]) => {
        const template: CreateTemplateDto = {
          name: 'toDelete',
          brandCode: role.brandCode ? role.brandCode : tenant.defaultBrandCode,
          type: 'individual',
          selfOwned: false,
          owner: {
            firstName: account.firstName,
            lastName: account.lastName,
            email: account.username,
            brandCode: tenant.defaultBrandCode,
          },
        };
        return this.createTemplate(tenant.id, template).pipe(
          mergeMap(response =>
            this.subscriptionsService
              .getSubscriptionFeaturesBySidedrawerId(response.id)
              .pipe(
                mergeMap(subscriptionFeatures =>
                  this.deleteTemplate(tenant.id, response.id).pipe(
                    map(() => subscriptionFeatures),
                    catchError(() => of(subscriptionFeatures))
                  )
                )
              )
          )
        );
      })
    );
  }
}
