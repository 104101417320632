import { Price } from '../../prices/models/price.model';

export class SubscriptionType {
  public static sideDrawers = 'sponsoredsds';
  public static users = 'sponsoredusers';
  public static branding = 'brandcode';

  public static getSubscriptionTypeFromPrice(price: Price): string {
    switch (price.metadata['product.audience']) {
      case 'users':
        return SubscriptionType.users;
      default:
        return SubscriptionType.sideDrawers;
    }
  }
}
