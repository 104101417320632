import { computed, inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { localeDefaultSelector } from '../../dictionary/store/dictionary.selectors';
import { DateHelper } from '../../core/helpers/date.helper';

@Pipe({
  name: 'sdDate',
  standalone: true,
})
export class SdDatePipe implements PipeTransform {
  private readonly store = inject(Store<AppState>);
  transform(value: string | Date): string {
    if (!value) {
      return '';
    }
    return computed(() => {
      return DateHelper.formatColumnDate(
        value.toString(),
        this.store.selectSignal(localeDefaultSelector)()
      );
    })();
  }
}
