import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { MyRow } from '../sd-tables/models/my-row.model';
import { saveAs as importedSaveAs } from 'file-saver';

@Injectable()
export class XlsxService {
  exportTableToExcel(data: MyRow[], fileName: string, sheet?: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheet);
    if (data.length === 0) {
      return;
    }
    worksheet.addRow(Object.keys(data[0]));
    data.forEach(rowData => {
      worksheet.addRow(Object.values(rowData));
    });
    workbook.csv
      .writeBuffer()
      .then(buffer => importedSaveAs.saveAs(new Blob([buffer]), fileName));
  }
}
