import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sd-tooltip-button',
  templateUrl: './sd-tooltip-button.component.html',
  styleUrls: ['./sd-tooltip-button.component.scss'],
})
export class SdTooltipButtonComponent implements OnInit {
  @Input() tooltip: string;

  constructor() {}

  ngOnInit(): void {}
}
