import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styles: [],
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    window.intercomSettings = {
      app_id: environment.intercomAppId,
      alignment: 'right',
      horizontal_padding: 20,
      vertical_padding: 20,
    };
    window.Intercom('boot', {
      app_id: environment.intercomAppId,
    });
  }
}
