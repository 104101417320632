export enum SdTableCellType {
  string = 'string',
  svg = 'svg',
  date = 'date',
  html = 'html',
  img = 'img',
  currency = 'currency',
  toggle = 'toggle',
  boolean = 'boolean',
}
