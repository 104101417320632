import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SdDialogConfirmData } from '../models/sd-dialog-confirm-data.model';

@Component({
  selector: 'app-sd-dialog-confirm',
  templateUrl: './sd-dialog-confirm.component.html',
  styleUrls: ['./sd-dialog-confirm.component.scss'],
})
export class SdDialogConfirmComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<SdDialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: SdDialogConfirmData
  ) {}

  onClose(successOrCancel: boolean): void {
    this.dialogRef.close(successOrCancel);
  }
}
