import { Tenant } from '../models/tenant.model';
import { TenantActions, TenantActionsTypes } from './tenant.actions';
import { Role } from '../../users/models/role.model';

export const featureName = 'tenant';

export interface TenantState {
  tenants: Tenant[];
  currentTenant: Tenant;
  currentRole: Role;
  customDomainAvailable: boolean;
}

export const initTenantState: TenantState = {
  tenants: null,
  currentTenant: null,
  currentRole: null,
  customDomainAvailable: false,
};

export function tenantReducer(
  state: TenantState = initTenantState,
  action: TenantActions
): TenantState {
  switch (action.type) {
    case TenantActionsTypes.TenantsLoaded:
      return {
        ...state,
        tenants: action.payload.tenants,
      };
    case TenantActionsTypes.CurrentTenantChange:
      return {
        ...state,
        currentTenant: action.payload.tenant,
      };
    case TenantActionsTypes.CurrentRoleChange:
      return {
        ...state,
        currentRole: action.payload.role,
      };
    case TenantActionsTypes.CurrentTenantUpdated: {
      const tenantsUpdated = [...state.tenants];
      const index = tenantsUpdated.findIndex(
        tenant => tenant.id === action.payload.tenantId
      );
      tenantsUpdated[index] = {
        ...tenantsUpdated[index],
        name: action.payload.name,
        logoUrl: action.payload.logoUrl,
        subscriptionType: action.payload.subscriptionType,
      };
      return {
        ...state,
        currentTenant: {
          ...state.currentTenant,
          name: action.payload.name,
          logoUrl: action.payload.logoUrl,
          subscriptionType: action.payload.subscriptionType,
        },
        tenants: tenantsUpdated,
      };
    }
    case TenantActionsTypes.TenantCustomDomainAvailableChange:
      return {
        ...state,
        customDomainAvailable: action.payload.state,
      };
    default:
      return state;
  }
}
