import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SubscriptionsService } from '../../subscriptions/services/subscriptions.service';
import {
  BulkUploadActionsTypes,
  BulkUploadCancelUpload,
  BulkUploadCancelUploadSuccess,
  BulkUploadInitBulkUpload,
  BulkUploadSubscriptionFeaturesLoaded,
  BulkUploadSubscriptionFeaturesRequested,
} from './bulk-upload.actions';
import { map, mergeMap } from 'rxjs/operators';
import { BulkUploadService } from '../services/bulk-upload.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class BulkUploadEffects {
  bulkUploadSubscriptionFeaturesRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<BulkUploadSubscriptionFeaturesRequested>(
        BulkUploadActionsTypes.BulkUploadSubscriptionFeaturesRequested
      ),
      mergeMap(action =>
        this.subscriptionsService
          .getSubscriptionFeaturesBySidedrawerId(action.payload.sidedrawerId)
          .pipe(
            map(
              features => new BulkUploadSubscriptionFeaturesLoaded({ features })
            )
          )
      )
    );
  });

  bulkUploadCancelUpload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<BulkUploadCancelUpload>(
        BulkUploadActionsTypes.BulkUploadCancelUpload
      ),
      map(() => {
        this.bulkUploadService.cancelBulkUpload();
        return new BulkUploadCancelUploadSuccess();
      })
    );
  });

  bulkUploadInitBulkUpload$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<BulkUploadInitBulkUpload>(
          BulkUploadActionsTypes.BulkUploadInitBulkUpload
        ),
        mergeMap(action => {
          this.bulkUploadService.initBulkUpload(action.payload.sidedrawerId);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly subscriptionsService: SubscriptionsService,
    private readonly bulkUploadService: BulkUploadService
  ) {}
}
