import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { PlansService } from '../services/plans.service';
import {
  GettingPlansChange,
  PlanActionsTypes,
  PlansLoaded,
  PlansRequested,
} from './plan-list.actions';
import { map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class PlanEffects {
  plansRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PlansRequested>(PlanActionsTypes.PlansRequested),
      tap(() => this.store.dispatch(new GettingPlansChange({ state: true }))),
      mergeMap(() =>
        this.plansService.getPlansByBrandCode().pipe(
          map(plans => new PlansLoaded({ plans })),
          tap(() =>
            this.store.dispatch(new GettingPlansChange({ state: false }))
          )
        )
      )
    )
  );

  plansLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PlansLoaded>(PlanActionsTypes.PlansLoaded),
      tap(() => this.store.dispatch(new GettingPlansChange({ state: true }))),
      mergeMap(action =>
        this.plansService
          .updatePlanListOrderIds(action.payload.plans)
          .pipe(map(() => new GettingPlansChange({ state: false })))
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly plansService: PlansService,
    private readonly store: Store<AppState>
  ) {}
}
