import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { DictionaryService } from '../services/dictionary.service';
import {
  DictionaryActionsTypes,
  DictionaryRequested,
  GettingDictionaryChange,
  GettingDictionaryFails,
  LocaleDefaultChange,
  LocalesAndDictionaryRequested,
} from './dictionary.actions';
import { mergeMap, of, switchMap, tap } from 'rxjs';
import { Locale } from '../models/locale.model';
import { DictionaryLoaded } from './dictionary.actions';
import { SampleDataJsonRequested } from '../../branding/store/brand-samples.actions';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class DictionaryEffects {
  localesAndDictionaryRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LocalesAndDictionaryRequested>(
          DictionaryActionsTypes.LocalesAndDictionaryRequested
        ),
        tap(() =>
          this.store.dispatch(new GettingDictionaryChange({ state: true }))
        ),
        mergeMap(() =>
          this.dictionaryService.getLocales().pipe(
            tap({
              next: locales => {
                const defaultLocale = Locale.getBrowserLocale(locales);
                this.store.dispatch(
                  new LocaleDefaultChange({ data: defaultLocale })
                );
                this.dictionaryService
                  .getDictionary(defaultLocale)
                  .pipe(
                    tap({
                      next: () =>
                        this.store.dispatch(
                          new GettingDictionaryChange({ state: false })
                        ),
                      error: () => {
                        this.store.dispatch(
                          new GettingDictionaryChange({ state: false })
                        );
                        this.store.dispatch(new GettingDictionaryFails());
                      },
                    })
                  )
                  .subscribe();
              },
              error: () => {
                this.store.dispatch(
                  new GettingDictionaryChange({ state: false })
                );
                this.store.dispatch(new GettingDictionaryFails());
              },
            })
          )
        )
      ),
    { dispatch: false }
  );

  dictionaryRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DictionaryRequested>(DictionaryActionsTypes.DictionaryRequested),
      tap(() =>
        this.store.dispatch(new GettingDictionaryChange({ state: true }))
      ),
      switchMap(action =>
        this.dictionaryService
          .getDictionary(action.payload.locale, action.payload.localeId)
          .pipe(
            map(() => new GettingDictionaryChange({ state: false })),
            catchError(() => of(new GettingDictionaryFails()))
          )
      )
    )
  );

  onDictionaryLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DictionaryLoaded>(DictionaryActionsTypes.DictionaryLoaded),
        tap(() => this.store.dispatch(new SampleDataJsonRequested()))
      ),
    { dispatch: false }
  );
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly dictionaryService: DictionaryService
  ) {}
}
