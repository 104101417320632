<!-- // TODO Crash by de controller input, review -->
<!-- <app-sd-input
  [type]="'text'"
  [controller]="controller"
  [error]="'Field required'"
></app-sd-input> -->

<mat-form-field>
  <input matInput />
</mat-form-field>
