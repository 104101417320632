<div class="sd-search-container">
  <app-sd-svg
    [color]="'var(--secondaryAccentColor)'"
    [height]="20"
    [src]="searchIcon"
    [width]="20"
    class="sd-search-container-icon"></app-sd-svg>

  <input
    [formControl]="searchController"
    [placeholder]="placeholder"
    [type]="'text'"
    class="sd-search-input" />
</div>
