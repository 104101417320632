import { ActionReducerMap } from '@ngrx/store';
import { sdTableReducer, SdTableState } from './table.reducer';
import {
  sdTableDialogReducer,
  SdTableDialogState,
} from './table-dialog.reducer';

export const featureName = 'sd-table';

export interface TableState {
  page: SdTableState;
  dialog: SdTableDialogState;
}

export const tableReducer: ActionReducerMap<TableState> = {
  page: sdTableReducer,
  dialog: sdTableDialogReducer,
};
