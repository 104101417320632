import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { SubscriptionsService } from '../../subscriptions/services/subscriptions.service';
import { NetworksService } from '../../networks/services/networks.service';
import { PlansService } from '../../plans/services/plans.service';
import {
  BrandSamplesActionsTypes,
  GettingSampleDataChange,
  SampleDataAssignCollaboratorsRequested,
  SampleDataAssignPlansRequested,
  SampleDataCheckSideDrawersPermissionsRequested,
  SampleDataCreateItemsRequested,
  SampleDataCreatePlansRequested,
  SampleDataCreateSideDrawersRequested,
  SampleDataCreateVcardRequested,
  SampleDataInitProcess,
  SampleDataJsonLoaded,
  SampleDataJsonRequested,
  SampleDataLicensesLoaded,
  SampleDataLicensesRequested,
  SampleDataPlanIdLoaded,
  SampleDataPlanIdRemoved,
  SampleDataPlansOperationsLoaded,
  SampleDataProcessStepUpdated,
  SampleDataSideDrawerIdLoaded,
} from './brand-samples.actions';
import {
  catchError,
  delay,
  map,
  mergeMap,
  retry,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { forkJoin, of, timer } from 'rxjs';
import {
  brandSampleAssignPlansOperationsSelector,
  brandSampleBrandCodeSelector,
  brandSampleDataInfoCollaboratorsSelector,
  brandSampleDataInfoPlansSelector,
  brandSampleDataInfoSelector,
  brandSampleDataInfoSideDrawersSelector,
  brandSampleDataInfoVcardSelector,
  brandSampleLicensesSelector,
  brandSamplePlanIdsSelector,
  brandSampleSampleDataConfigurationSelector,
  brandSampleSideDrawersIdsSelector,
  brandSampleSubscriptionIdSelector,
} from './brand-samples.selectors';
import { ProcessStepStatus } from '../../tenants/models/process-step-status.enum';
import { ProcessStep } from '../../tenants/models/process-step.model';
import { currentTenantSelector } from '../../tenants/store/tenant.selectors';
import { NewPlanItemDto } from '../../plans/views/plan-items/models/new-plan-item.dto';
import { SidedrawerRoles } from '../../core/roles/sidedrawer.roles';
import { SubscriptionType } from '../../subscriptions/models/subscription-type.model';
import { SideDrawersService } from '../../sidedrawers/services/side-drawers.service';
import { SubscriptionPlan } from '../../subscriptions/models/subscription-plan.model';
import { LicenseStatus } from '../../subscriptions/models/license-status.model';
import { BrandingService } from '../services/branding.service';
import { BrandConfig } from '../models/brand-config.model';
import { brandByBrandCodeSelector } from './brand-list.selectors';

@Injectable()
export class BrandSamplesEffects {
  SampleDataJsonRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SampleDataJsonRequested>(
        BrandSamplesActionsTypes.SampleDataJsonRequested
      ),
      tap(() =>
        this.store.dispatch(new GettingSampleDataChange({ state: true }))
      ),
      switchMap(() => this.brandService.getSampleData()),
      catchError(() =>
        of({ sidedrawers: [], collaborators: [], plans: [], vcard: [] })
      ),
      tap(() =>
        this.store.dispatch(new GettingSampleDataChange({ state: false }))
      ),
      map(data => new SampleDataJsonLoaded({ data }))
    )
  );

  SampleDataInitProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SampleDataInitProcess>(
        BrandSamplesActionsTypes.SampleDataInitProcess
      ),
      tap(() =>
        this.stepUpdateDispatcher({
          key: 'increaseLicenses',
          status: ProcessStepStatus.processing,
        })
      ),
      switchMap(action =>
        forkJoin([
          this.store.pipe(select(currentTenantSelector), take(1)),
          this.store.pipe(
            select(brandSampleDataInfoSideDrawersSelector),
            take(1)
          ),
        ]).pipe(
          map(([tenant, sideDrawersToCreate]) => ({
            brandCode: action.payload.brandCode,
            subscriptionId: action.payload.subscriptionId,
            tenant,
            sideDrawersToCreate,
          }))
        )
      ),
      mergeMap(({ brandCode, subscriptionId, tenant, sideDrawersToCreate }) =>
        this.subscriptionService.getTenantSubscriptions(tenant.id).pipe(
          catchError(() => of([])),
          mergeMap((subscriptions: SubscriptionPlan[]) => {
            const currentSubscription = subscriptions.find(
              subs => subs.subscriptionId === subscriptionId
            );
            if (currentSubscription.type === SubscriptionType.users) {
              this.stepUpdateDispatcher({
                key: 'increaseLicenses',
                status: ProcessStepStatus.success,
              });
              this.stepUpdateDispatcher({
                key: 'getLicenses',
                status: ProcessStepStatus.success,
              });
              return of(new SampleDataCreateSideDrawersRequested());
            }
            if (
              currentSubscription?.availableLicenses >=
              sideDrawersToCreate.length
            ) {
              this.stepUpdateDispatcher({
                key: 'increaseLicenses',
                status: ProcessStepStatus.success,
              });
              return of(new SampleDataLicensesRequested());
            }
            return this.subscriptionService
              .updateSubscriptionQuantity(
                tenant.id,
                subscriptionId,
                sideDrawersToCreate.length -
                  currentSubscription.availableLicenses,
                true
              )
              .pipe(
                catchError(() => of(null)),
                map(() => new SampleDataLicensesRequested()),
                tap(() =>
                  this.stepUpdateDispatcher({
                    key: 'increaseLicenses',
                    status: ProcessStepStatus.success,
                  })
                )
              );
          })
        )
      )
    )
  );

  SampleDataLicensesRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SampleDataLicensesRequested>(
        BrandSamplesActionsTypes.SampleDataLicensesRequested
      ),
      tap(() =>
        this.stepUpdateDispatcher({
          key: 'getLicenses',
          status: ProcessStepStatus.processing,
        })
      ),
      switchMap(() =>
        this.store.pipe(select(brandSampleSubscriptionIdSelector), take(1))
      ),
      switchMap(subscriptionId =>
        this.subscriptionService.getLicenses(subscriptionId)
      ),
      catchError(() => of([])),
      tap(() =>
        this.stepUpdateDispatcher({
          key: 'getLicenses',
          status: ProcessStepStatus.success,
        })
      ),
      map(licenses => new SampleDataLicensesLoaded({ licenses }))
    )
  );

  SampleDataLicensesLoaded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SampleDataLicensesLoaded>(
        BrandSamplesActionsTypes.SampleDataLicensesLoaded
      ),
      switchMap(() =>
        forkJoin([
          this.store.pipe(select(brandSampleLicensesSelector), take(1)),
          this.store.pipe(select(brandSampleDataInfoSelector), take(1)),
        ])
      ),
      map(([licenses, sampleData]) => {
        const sdsWithLicenses = [];
        let availableLicenses = [
          ...licenses.filter(
            license => license.status === LicenseStatus.available
          ),
        ];
        sampleData.sidedrawers.forEach(sd => {
          const availableLicense = availableLicenses[0];
          if (!!availableLicense) {
            availableLicenses = [
              ...availableLicenses.filter(
                lic => lic.key !== availableLicense.key
              ),
            ];
            sdsWithLicenses.push({
              ...sd,
              licenseKey: availableLicense.key,
            });
          }
        });
        this.store.dispatch(
          new SampleDataJsonLoaded({
            data: {
              ...sampleData,
              sidedrawers: sdsWithLicenses,
            },
          })
        );
        return new SampleDataCreateSideDrawersRequested();
      })
    );
  });

  SampleDataCreateSideDrawersRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SampleDataCreateSideDrawersRequested>(
        BrandSamplesActionsTypes.SampleDataCreateSideDrawersRequested
      ),
      tap(() =>
        this.stepUpdateDispatcher({
          key: 'creatingSideDrawers',
          status: ProcessStepStatus.processing,
        })
      ),
      switchMap(() =>
        forkJoin([
          this.store.pipe(
            select(brandSampleDataInfoSideDrawersSelector),
            take(1)
          ),
          this.store.pipe(select(currentTenantSelector), take(1)),
          this.store.pipe(select(brandSampleBrandCodeSelector), take(1)),
          this.store.pipe(select(brandSampleSubscriptionIdSelector), take(1)),
          this.store.pipe(
            select(brandSampleSampleDataConfigurationSelector),
            take(1)
          ),
        ]).pipe(
          mergeMap(
            ([
              sideDrawers,
              tenant,
              brandCode,
              subscriptionId,
              sampleDataConfiguration,
            ]) => {
              if (!sampleDataConfiguration.createSD) {
                this.stepUpdateDispatcher({
                  key: 'creatingSideDrawers',
                  status: ProcessStepStatus.success,
                });
                return of(new SampleDataCreatePlansRequested());
              }
              return forkJoin(
                sideDrawers.map((sideDrawer, i) =>
                  tenant.subscriptionType === SubscriptionType.users
                    ? this.subscriptionService
                        .createSideDrawerFromTenantsSubscription(
                          tenant.id,
                          subscriptionId,
                          {
                            ...sideDrawer,
                            owner: {
                              email: sideDrawer.email,
                            },
                            brandCode,
                            dataBaseRegion: tenant.region,
                          }
                        )
                        .pipe(
                          catchError(() => of(null)),
                          tap(response => {
                            if (response?.id) {
                              this.store.dispatch(
                                new SampleDataSideDrawerIdLoaded({
                                  id: response.id,
                                  key: i,
                                })
                              );
                            }
                          })
                        )
                    : this.subscriptionService
                        .createSideDrawerFromSubscription(
                          subscriptionId,
                          {
                            ...sideDrawer,
                            brandCode,
                            dataBaseRegion: tenant.region,
                          },
                          2
                        )
                        .pipe(
                          catchError(() => of(null)),
                          tap(response => {
                            if (response?.id) {
                              this.store.dispatch(
                                new SampleDataSideDrawerIdLoaded({
                                  id: response.id,
                                  key: i,
                                })
                              );
                            }
                          })
                        )
                )
              ).pipe(
                tap(() =>
                  this.stepUpdateDispatcher({
                    key: 'creatingSideDrawers',
                    status: ProcessStepStatus.success,
                  })
                ),
                map(() => new SampleDataCreatePlansRequested())
              );
            }
          )
        )
      )
    )
  );

  SampleDataCreatePlansRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SampleDataCreatePlansRequested>(
        BrandSamplesActionsTypes.SampleDataCreatePlansRequested
      ),
      tap(() =>
        this.stepUpdateDispatcher({
          key: 'creatingPlans',
          status: ProcessStepStatus.processing,
        })
      ),
      switchMap(() =>
        forkJoin([
          this.store.pipe(select(brandSampleDataInfoPlansSelector), take(1)),
          this.store.pipe(select(currentTenantSelector), take(1)),
          this.store.pipe(select(brandSampleSideDrawersIdsSelector), take(1)),
          this.store.pipe(select(brandSampleBrandCodeSelector), take(1)),
          this.store.pipe(
            select(brandSampleSampleDataConfigurationSelector),
            take(1)
          ),
        ])
      ),
      switchMap(
        ([plans, tenant, sdIds, brandCode, sampleDataConfiguration]) => {
          if (!sampleDataConfiguration.createPlan) {
            this.stepUpdateDispatcher({
              key: 'creatingPlans',
              status: ProcessStepStatus.success,
            });
            return of(new SampleDataCreateItemsRequested());
          }

          return forkJoin(
            plans.map(plan =>
              this.planService
                .createPlan(tenant.id, {
                  brandCode,
                  orderId: parseFloat(plan.orderId),
                  sidedrawerType: plan.sidedrawerType,
                  name: plan.name,
                  description: plan.description,
                  format: plan.format ?? 'multilanguage',
                })
                .pipe(
                  catchError(() => of(null)),
                  tap(response => {
                    if (response?._id) {
                      this.store.dispatch(
                        new SampleDataPlanIdLoaded({
                          id: response._id,
                          items: plan.items,
                        })
                      );
                      this.store.dispatch(
                        new SampleDataPlansOperationsLoaded({
                          operations: [
                            ...plan.sideDrawerIndex
                              .split(',')
                              .map(sdId => parseInt(sdId, 10))
                              .map(sdIndex => ({
                                planId: response._id,
                                sideDrawerId: sdIds.get(sdIndex),
                              })),
                          ],
                        })
                      );
                    }
                  })
                )
            )
          ).pipe(
            tap(() =>
              this.stepUpdateDispatcher({
                key: 'creatingPlans',
                status: ProcessStepStatus.success,
              })
            ),
            map(() => new SampleDataCreateItemsRequested())
          );
        }
      )
    )
  );

  SampleDataCreateItemsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SampleDataCreateItemsRequested>(
        BrandSamplesActionsTypes.SampleDataCreateItemsRequested
      ),
      tap(() =>
        this.stepUpdateDispatcher({
          key: 'creatingItems',
          status: ProcessStepStatus.processing,
        })
      ),
      switchMap(() =>
        forkJoin([
          this.store.pipe(select(currentTenantSelector), take(1)),
          this.store.pipe(select(brandSamplePlanIdsSelector), take(1)),
        ])
      ),
      mergeMap(([tenant, plansWithItems]) => {
        if (plansWithItems.size === 0) {
          this.stepUpdateDispatcher({
            key: 'creatingItems',
            status: ProcessStepStatus.success,
          });
          return of(new SampleDataCheckSideDrawersPermissionsRequested());
        }
        const { id, items } = [...plansWithItems.values()][0];
        return forkJoin(
          items.map(item => {
            const aux: NewPlanItemDto = {};
            aux.name = item.name;
            aux.itemType = item.itemType;
            aux.orderId = parseFloat(item.orderId);
            aux.info = item.info;
            aux.optional = item.optional;
            if (item.itemType === 'record') {
              aux.recordTypeName = item.recordTypeName;
              aux.recordSubtypeName = item.recordSubtypeName;
              if (item.recordSubtypeOtherName) {
                aux.recordSubtypeOtherName = item.recordSubtypeOtherName;
              }
            }
            if (item.itemType === 'field') {
              aux.validation = item.validation;
            }
            return this.planService
              .createPlanItem(tenant.id, id, aux)
              .pipe(catchError(() => of(null)));
          })
        ).pipe(
          tap(() => this.store.dispatch(new SampleDataPlanIdRemoved({ id }))),
          map(() => new SampleDataCreateItemsRequested())
        );
      })
    )
  );

  SampleDataCheckSideDrawersPermissionsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SampleDataCheckSideDrawersPermissionsRequested>(
        BrandSamplesActionsTypes.SampleDataCheckSideDrawersPermissionsRequested
      ),
      tap(() =>
        this.stepUpdateDispatcher({
          key: 'checkSideDrawersPermissions',
          status: ProcessStepStatus.processing,
        })
      ),
      switchMap(() =>
        forkJoin([
          this.store.pipe(select(brandSampleSideDrawersIdsSelector), take(1)),
          this.store.pipe(
            select(brandSampleSampleDataConfigurationSelector),
            take(1)
          ),
        ])
      ),
      mergeMap(([sdIds, sampleDataConfiguration]) => {
        if (!sampleDataConfiguration.createSD) {
          this.stepUpdateDispatcher({
            key: 'checkSideDrawersPermissions',
            status: ProcessStepStatus.success,
          });
          return of(new SampleDataAssignPlansRequested());
        }
        return forkJoin(
          [...sdIds.values()].map(sdId =>
            this.networkService.getSideDrawerNetworks(sdId).pipe(
              catchError(() => of([])),
              map(networks =>
                networks.some(
                  network =>
                    network?.sidedrawerRole === SidedrawerRoles.owner ||
                    network?.sidedrawerRole === SidedrawerRoles.editor
                )
              )
            )
          )
        ).pipe(
          map((results: boolean[]) => results.some(result => !result)),
          tap(missingPermission =>
            !missingPermission
              ? this.stepUpdateDispatcher({
                  key: 'checkSideDrawersPermissions',
                  status: ProcessStepStatus.success,
                })
              : null
          ),
          mergeMap(missingPermission =>
            missingPermission
              ? of(new SampleDataCheckSideDrawersPermissionsRequested()).pipe(
                  delay(1000)
                )
              : of(new SampleDataAssignPlansRequested())
          )
        );
      })
    )
  );

  SampleDataAssignPlansRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SampleDataAssignPlansRequested>(
        BrandSamplesActionsTypes.SampleDataAssignPlansRequested
      ),
      tap(() =>
        this.stepUpdateDispatcher({
          key: 'assigningPlans',
          status: ProcessStepStatus.processing,
        })
      ),
      switchMap(() =>
        forkJoin([
          this.store.pipe(
            select(brandSampleAssignPlansOperationsSelector),
            take(1)
          ),
          this.store.pipe(
            select(brandSampleSampleDataConfigurationSelector),
            take(1)
          ),
        ])
      ),
      mergeMap(([plansToAssign, sampleDataConfiguration]) => {
        if (!sampleDataConfiguration.deliverInfoRequest) {
          this.stepUpdateDispatcher({
            key: 'assigningPlans',
            status: ProcessStepStatus.success,
          });
          return of(new SampleDataAssignCollaboratorsRequested());
        }
        return forkJoin(
          plansToAssign.map(({ sideDrawerId, planId }) =>
            this.planService.requestPlanToSideDrawer(sideDrawerId, planId).pipe(
              retry({
                count: 3,
                delay: (_, retryCount) => timer(retryCount * 1000),
              }),
              catchError(() => of(false))
            )
          )
        ).pipe(
          tap(() =>
            this.stepUpdateDispatcher({
              key: 'assigningPlans',
              status: ProcessStepStatus.success,
            })
          ),
          map(() => new SampleDataAssignCollaboratorsRequested())
        );
      })
    )
  );

  SampleDataAssignCollaboratorsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SampleDataAssignCollaboratorsRequested>(
        BrandSamplesActionsTypes.SampleDataAssignCollaboratorsRequested
      ),
      tap(() => {
        this.stepUpdateDispatcher({
          key: 'assigningCollaborators',
          status: ProcessStepStatus.processing,
        });
      }),
      switchMap(() =>
        forkJoin([
          this.store.pipe(select(brandSampleSideDrawersIdsSelector), take(1)),
          this.store.pipe(
            select(brandSampleDataInfoCollaboratorsSelector),
            take(1)
          ),
          this.store.pipe(
            select(brandSampleSampleDataConfigurationSelector),
            take(1)
          ),
        ])
      ),
      mergeMap(([sdIds, collaborators, sampleDataConfiguration]) => {
        if (!sampleDataConfiguration.addCollaborators) {
          this.stepUpdateDispatcher({
            key: 'assigningCollaborators',
            status: ProcessStepStatus.success,
          });
          return of(new SampleDataCreateVcardRequested());
        }
        return forkJoin(
          collaborators.map(collaborator =>
            this.networkService
              .createSideDrawerNetworkWithOpenId(
                sdIds.get(parseInt(collaborator.sideDrawerIndex, 10)),
                {
                  openId: collaborator.collaborator,
                  sidedrawerRole: collaborator.sidedrawerRole,
                  relation: collaborator.relation,
                }
              )
              .pipe(
                retry({
                  count: 3,
                  delay: (_, retryCount) => timer(retryCount * 1000),
                }),
                catchError(() => of(null))
              )
          )
        ).pipe(
          tap(() =>
            this.stepUpdateDispatcher({
              key: 'assigningCollaborators',
              status: ProcessStepStatus.success,
            })
          ),
          map(() => new SampleDataCreateVcardRequested())
        );
      })
    )
  );

  SampleDataCreateVcardRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SampleDataCreateVcardRequested>(
        BrandSamplesActionsTypes.SampleDataCreateVcardRequested
      ),
      tap(() => {
        this.stepUpdateDispatcher({
          key: 'creatingVcard',
          status: ProcessStepStatus.processing,
        });
      }),
      switchMap(() =>
        forkJoin([
          this.store.pipe(select(brandSampleDataInfoVcardSelector), take(1)),
          this.store.pipe(select(currentTenantSelector), take(1)),
          this.store.pipe(
            select(brandSampleSampleDataConfigurationSelector),
            take(1)
          ),
          this.store.pipe(select(brandSampleBrandCodeSelector), take(1)),
        ])
      ),
      switchMap(([vcard, tenant, sampleDataConfiguration, brandCode]) =>
        this.store.pipe(
          select(brandByBrandCodeSelector({ brandCode })),
          take(1),
          map(brand => ({ vcard, tenant, sampleDataConfiguration, brand }))
        )
      ),
      mergeMap(({ vcard, tenant, sampleDataConfiguration, brand }) => {
        if (!sampleDataConfiguration.sampleVCard || !vcard) {
          return of(
            new SampleDataProcessStepUpdated({
              step: {
                id: 'creatingVcard',
                changes: {
                  key: 'creatingVcard',
                  status: ProcessStepStatus.success,
                },
              },
            })
          );
        }
        return this.brandService.getBrandFlavors(tenant?.id, brand?.id).pipe(
          // tslint:disable-next-line: no-string-literal
          map(d => d.map(b => ({ ...b, isDefault: b['default'] }))[0]),
          switchMap((brandConfig: BrandConfig) => {
            const brandingToSave = {
              ...brandConfig,
              vCard: vcard != null ? vcard[0] : null,
            } as BrandConfig;

            return this.brandService
              .updateBrandFlavor(
                tenant.id,
                brand.id,
                brandingToSave.id,
                brandingToSave
              )
              .pipe(
                catchError(() => of(null)),
                map(
                  () =>
                    new SampleDataProcessStepUpdated({
                      step: {
                        id: 'creatingVcard',
                        changes: {
                          key: 'creatingVcard',
                          status: ProcessStepStatus.success,
                        },
                      },
                    })
                )
              );
          })
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly brandService: BrandingService,
    private readonly sideDrawersService: SideDrawersService,
    private readonly subscriptionService: SubscriptionsService,
    private readonly networkService: NetworksService,
    private readonly planService: PlansService
  ) {}

  stepUpdateDispatcher(step: ProcessStep) {
    this.store.dispatch(
      new SampleDataProcessStepUpdated({
        step: {
          id: step.key,
          changes: { ...step },
        },
      })
    );
  }
}
