import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { SideDrawer } from '../models/side-drawer.model';
import { TransferOwnershipDto } from '../../networks/models/transfer-ownership.dto';

export enum SideDrawerListActionsTypes {
  SideDrawersLoaded = '[SideDrawer Effects] SideDrawers Loaded',
  TenantSideDrawersRequested = '[] Tenant SideDrawers Requested',
  SideDrawersByBrandCodeRequested = '[SideDrawer Effects] SideDrawers By BrandCode Requested',
  SideDrawerAdded = '[New SideDrawer Dialog] New SideDrawer Added',
  SideDrawerUpdated = '[SideDrawer Table / SideDrawer details view] SideDrawer Updated',
  SideDrawerDeleted = '[SideDrawer Table / SideDrawer details view] SideDrawer Deleted',
  SideDrawersClearInformation = '[SideDrawer Effects] Clear all SideDrawer List information',
  SideDrawerRedirect = '[] SideDrawer Redirect',
  SideDrawerCheckNetworkAndCallback = '[] SideDrawer Check Network And Callback',
  SideDrawerForceNetworkAndCallback = '[] SideDrawer Force Network And Callback',
  SideDrawerCollaborateDialog = '[] SideDrawer Collaborate with this SD Dialog',
  SideDrawerTransferDialog = '[] SideDrawer Transfer Ownership with this SD Dialog',
  SideDrawerTransferDialogResult = '[] SideDrawer Transfer Ownership Success',
  SideDrawerListNextPageRequested = '[SD-Table] SideDrawer List Next Page Requested',
}

export class SideDrawersLoaded implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawersLoaded;

  constructor(public payload: { sidedrawers: SideDrawer[] }) {}
}

export class TenantSideDrawersRequested implements Action {
  readonly type = SideDrawerListActionsTypes.TenantSideDrawersRequested;
}

export class SideDrawersByBrandCodeRequested implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawersByBrandCodeRequested;

  constructor(
    public payload: {
      tenantId: string;
      brandCode: string;
      sidedrawers: SideDrawer[];
      startingAfter?: string;
    }
  ) {}
}

export class SideDrawerAdded implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawerAdded;

  constructor(public payload: { sidedrawer: SideDrawer }) {}
}

export class SideDrawerUpdated implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawerUpdated;

  constructor(public payload: { sidedrawer: Update<SideDrawer> }) {}
}

export class SideDrawerDeleted implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawerDeleted;

  constructor(public payload: { id: string }) {}
}

export class SideDrawersClearInformation implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawersClearInformation;
}

export class SideDrawerRedirect implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawerRedirect;

  constructor(public payload: { sidedrawerId: string; url: string }) {}
}

export class SideDrawerCheckNetworkAndCallback implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawerCheckNetworkAndCallback;

  constructor(public payload: { sidedrawerId: string; callback: any }) {}
}

export class SideDrawerForceNetworkAndCallback implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawerForceNetworkAndCallback;

  constructor(public payload: { sidedrawerId: string; callback: any }) {}
}

export class SideDrawerCollaborateDialog implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawerCollaborateDialog;

  constructor(public payload: { sidedrawerId: string }) {}
}

export class SideDrawerTransferDialog implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawerTransferDialog;

  constructor(
    public payload: { sidedrawerId: string; dto: TransferOwnershipDto }
  ) {}
}

export class SideDrawerTransferDialogResult implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawerTransferDialogResult;

  constructor(public payload: { success: boolean }) {}
}

export class SideDrawerListNextPageRequested implements Action {
  readonly type = SideDrawerListActionsTypes.SideDrawerListNextPageRequested;

  constructor(public payload?: { brandCode: string }) {}
}

export type SideDrawerListActions =
  | SideDrawersLoaded
  | TenantSideDrawersRequested
  | SideDrawersByBrandCodeRequested
  | SideDrawerAdded
  | SideDrawerUpdated
  | SideDrawerDeleted
  | SideDrawersClearInformation
  | SideDrawerRedirect
  | SideDrawerCheckNetworkAndCallback
  | SideDrawerForceNetworkAndCallback
  | SideDrawerCollaborateDialog
  | SideDrawerTransferDialog
  | SideDrawerListNextPageRequested;
