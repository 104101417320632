import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-sd-search',
  templateUrl: './sd-search.component.html',
  styleUrls: ['./sd-search.component.scss'],
})
export class SdSearchComponent implements OnInit, OnDestroy {
  @Output() filter = new EventEmitter<string>();
  @Input() placeholder: string;
  @Input() useDebounceTime = false;
  searchIcon = environment.cdn + 'svgs/search-regular.svg';
  searchController = new UntypedFormControl(null);
  subscription = new Subscription();
  input;

  constructor() {}

  ngOnInit(): void {
    this.input = document.querySelector('.sd-search-input');
    const filter$ = this.searchController.valueChanges.pipe(
      debounceTime(this.useDebounceTime ? 1000 : 0),
      tap(value => {
        this.filter.emit(value);
      })
    );
    this.subscription.add(filter$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
