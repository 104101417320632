import {
  NetworkSidedrawersState,
  sidedrawersNetworkReducer,
} from './sidedrawers-network.reducer';
import { ActionReducerMap } from '@ngrx/store';

export const featureName = 'networks';

export interface NetworkState {
  sidedrawersNetwork: NetworkSidedrawersState;
}

export const networkReducer: ActionReducerMap<NetworkState> = {
  sidedrawersNetwork: sidedrawersNetworkReducer,
};
