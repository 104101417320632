import {
  createActionGroup,
  createFeature,
  createReducer,
  on,
  props,
} from '@ngrx/store';

export const SfAuthActions = createActionGroup({
  source: 'SfAuth',
  events: {
    'Token Loaded': props<{ token: string }>(),
    'General Error Mode Updated': props<{
      generalErrorMode: 'dictionary' | '404' | '403' | 'generic' | 'none';
    }>(),
    'Dictionary Requested': props<{ localeId: string }>(),
    'Default Brand Configuration Requested': props<{ brandCode: string }>(),
  },
});

interface SfAuthState {
  token: string;
  generalErrorMode: 'dictionary' | '404' | '403' | 'generic' | 'none';
}

const initialState: SfAuthState = {
  token: null,
  generalErrorMode: 'none',
};

export const sfAuthFeature = createFeature({
  name: 'auth',
  reducer: createReducer(
    initialState,
    on(SfAuthActions.tokenLoaded, (state, { token }) => ({ ...state, token })),
    on(
      SfAuthActions.generalErrorModeUpdated,
      (state, { generalErrorMode }) => ({
        ...state,
        generalErrorMode,
      })
    )
  ),
});

export const sfGeneralErrorModeSelector = sfAuthFeature.selectGeneralErrorMode;
export const sfTokenSelector = sfAuthFeature.selectToken;
