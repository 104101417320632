<div class="phone-input">
  <app-country-extension-selector
    (extensionSelected)="onExtensionSelected($event)"
    [controller]="extensionController"
    [error]="extensionError"
    [fullPhoneNumber]="controller"
    [placeholder]="extensionPlaceholder"
    [startValue]="
      !!startValue && !!startValue.extension ? startValue.extension : ''
    "></app-country-extension-selector>

  <app-sd-input
    [controller]="controller"
    [error]="error"
    [placeholder]="placeholder"
    [type]="'phone'"></app-sd-input>
</div>
