<mat-form-field [hideRequiredMarker]="true">
  <div class="country-selector-prefix" matPrefix>
    <img
      *ngIf="currentFlag.length > 0"
      [src]="currentFlag"
      alt="currentFlag"
      width="20" />
  </div>
  <input
    [formControl]="controller"
    [matAutocomplete]="auto"
    [placeholder]="placeholder"
    aria-label="Number"
    matInput
    type="text" />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let option of filteredOptions | async"
      [value]="option.countryName">
      <img
        [alt]="option.countryCode"
        [src]="'/assets/flags/' + option.countryCode.toLowerCase() + '.svg'" />
      {{ option.countryName }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="controller.invalid">
    <span>{{ error }}</span>
  </mat-error>
</mat-form-field>
