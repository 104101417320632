import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sd-checkbox',
  templateUrl: './sd-checkbox.component.html',
  styleUrls: ['./sd-checkbox.component.scss'],
})
export class SdCheckboxComponent {
  @Input() value = false;
  @Input() disabled = false;
  @Input() alignItemsCenter:
    | 'auto'
    | 'normal'
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'baseline'
    | 'first baseline'
    | 'last baseline' = 'center';
  @Output() valueChange = new EventEmitter<boolean>();

  onChange(): void {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }
}
