import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  SimpleChanges,
  ViewChild,
  computed,
} from '@angular/core';
import { SdTableActionButton } from '../../models/sd-table-action-button.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import { SdTableColumn } from '../../models/sd-table-column.model';
import {
  ClearTableData,
  LoadTableData,
  TableColumnsChange,
  TableElementsPerPagesChange,
  TableElementsPerPagesOptionsChange,
  TableFilterChange,
  TableGetConfigRequested,
  TablePageChange,
  TableSaveConfigRequested,
  TableSortChange,
} from '../../store/table.actions';
import { BehaviorSubject, Observable, Subscription, take } from 'rxjs';
import {
  sdTableColumnsSelector,
  sdTableElementsPerPageOptionsSelector,
  sdTableElementsPerPageSelector,
  sdTableFilteredDataSelector,
  sdTablePageSelector,
  sdTableSortTableSelector,
} from '../../store/table.selectors';
import { debounceTime, map, tap } from 'rxjs/operators';
import { SdTableRowActions } from '../../models/sd-table-row-actions.model';
import { environment } from '../../../../../environments/environment';
import { SdTableSortEvent } from '../../models/sd-table-sort-event.model';
import { SdTableCellType } from '../../models/sd-table-cell-type.model';
import { UtilsHelper } from '../../../../core/helpers/utils.helper';
import {
  ClearTableDialogData,
  LoadTableDialogData,
  TableDialogColumnsChange,
  TableDialogElementsPerPagesChange,
  TableDialogElementsPerPagesOptionsChange,
  TableDialogFilterChange,
  TableDialogGetConfigRequested,
  TableDialogPageChange,
  TableDialogSaveConfigRequested,
  TableDialogSortChange,
} from '../../store/table-dialog.actions';
import {
  sdTableDialogColumnsSelector,
  sdTableDialogElementsPerPageOptionsSelector,
  sdTableDialogElementsPerPageSelector,
  sdTableDialogFilteredDataSelector,
  sdTableDialogPageSelector,
  sdTableDialogSortTableSelector,
} from '../../store/table-dialog.selectors';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Clipboard } from '@angular/cdk/clipboard';
import { XlsxService } from 'src/app/shared/services/xlsx.service';
import * as moment from 'moment';
import { SdTableSortDirection } from '../../models/sd-table-sort-direction.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  dictionarySelector,
  localeDefaultSelector,
  localesSelector,
} from 'src/app/dictionary/store/dictionary.selectors';
import { SdSnackBarDisplaySnackBar } from '../../../sd-snack-bar/store/sd-snack-bar.actions';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { SdTableHelper } from '../../helper/sd-table.helper';
import { SdTableSettings } from '../../models/sd-table-settings.model';
import { MyRow } from '../../models/my-row.model';
import { Dictionary } from '../../../../dictionary/models/dictionary.model';
import { SdDialogConfirmOpen } from '../../../sd-dialog-confirm/store/sd-dialog-confirm.actions';
import { PageEvent } from '@angular/material/paginator';
import { SdTableRowActionButton } from '../../models/sd-table-row-action-button.model';
import { UntypedFormControl } from '@angular/forms';
import { SdLocaleSelectorA11yTemplates } from 'src/app/shared/sd-locale-selector-a11y/model/sd-locale-selector-a11y-templates.enum';
import { SdTableRowActionIconButton } from '../../models/sd-table-row-action-icon-button.model';
import { Option } from 'src/app/shared/sd-forms/models/option.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-sd-table',
  templateUrl: './sd-table.component.html',
  styleUrls: ['./sd-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() sortableTableWithDraggingRows = false;
  @Input() spinner = false;
  @Input() sortableElementKey: string;
  @Input() sortTableBy: SdTableSortEvent;
  @Input() actionButtons: SdTableActionButton[];
  @Input() rowActions: SdTableRowActions;
  @Input() rowIconActions: SdTableRowActionIconButton[] = [];
  @Input() columns: SdTableColumn[];
  @Input() data: any[];
  @Input() totalItems: number;
  @Input() nextPageCallback: Function;
  @Input() filterChangeCallback: Function;
  @Input() filterPlaceholder: string;
  @Input() actionsPlaceholder: string;
  @Input() isDialogTable = false;
  @Input() idTable: string;
  @Input() hideActions = false;
  @Input() localeFilter = false;
  optionFilterController = new UntypedFormControl(null);
  @Input() optionsForFilter: Option[];
  @Input() optionFilterPlaceholder: string;
  @Output() optionForFilterSelected = new EventEmitter<string>();
  @Input() optionFilter = false;
  @Output() localeFilterSelected = new EventEmitter<string>();
  @Output() actionButtonsClicked = new EventEmitter<string>();
  @Output() rowButtonsClicked = new EventEmitter<{ item: any; key: string }>();
  @Output() sortedItem = new EventEmitter<{ item: any; oldItem: any }>();
  @Output() toggleItem = new EventEmitter<{ item: any; oldItem: any }>();
  filteredData$: Observable<any[]>;
  columns$: Observable<SdTableColumn[]>;
  displayColumns$: Observable<string[]>;
  elementsPerPage$: Observable<number>;
  elementsPerPageOptions$: Observable<number[]>;
  page$: Observable<number>;
  pencilIcon = environment.cdn + 'svgs/pen-solid.svg';
  ellipsisIcon: string;
  dragIcon: string;
  tableIcon: string;
  excelIcon: string;
  languageIcon: string;
  SdTableCellType = SdTableCellType;
  cdn = environment.cdn;
  // NOTE object with contains coordinates
  rightMenuTopLeftPosition = { x: '0', y: '0' };
  @ViewChild('selectorForTriggerRightMenu', { static: true })
  triggerForRightMenu: MatMenuTrigger;
  readonly fileName = 'book';
  readonly extension = '.csv'; // NOTE or '.xlsx' for Excel;
  readonly sheet = 'sheet1';
  sortTable$: Observable<SdTableSortEvent>;
  columnsForEdit: SdTableColumn[];
  dataForEdit: typeof this.data;
  defaultLocale: Locale;
  filter$ = new BehaviorSubject('');
  filterChange$ = this.filter$.asObservable().pipe(
    debounceTime(800),
    tap(filter => {
      if (!!this.filterChangeCallback) {
        this.filterChangeCallback(filter);
      }
    })
  );
  subscription = new Subscription();
  languageController = new UntypedFormControl(null);
  localesFromSignal: Signal<Locale[]> = computed(() => {
    const locales = this.store.selectSignal(localesSelector)();
    const dictionary = this.store.selectSignal(dictionarySelector)();
    const allLocalesForSelection: Locale = {
      localeid: this.allKey,
      localename: dictionary.globalparams_selectall,
      languagename: this.allKey,
      countryname: this.allKey,
      languagecode: this.allKey,
      countrycode: this.allKey,
      languagedefault: false,
      localedefault: false,
      published: false,
    };
    return [allLocalesForSelection, ...locales];
  });

  sdLocaleSelectorA11yTemplates = SdLocaleSelectorA11yTemplates;
  readonly allKey = 'all';

  constructor(
    public readonly store: Store<AppState>,
    private readonly clipboard: Clipboard,
    private readonly xlsxService: XlsxService,
    private readonly destroyref: DestroyRef
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      this.isDialogTable
        ? new TableDialogElementsPerPagesOptionsChange({
            defaultAmount: !this.sortableTableWithDraggingRows,
          })
        : new TableElementsPerPagesOptionsChange({
            defaultAmount: !this.sortableTableWithDraggingRows,
          })
    );
    this.subscription.add(this.filterChange$.subscribe());

    const dictionary = this.store.selectSignal(dictionarySelector)();
    this.tableIcon =
      environment.cdn + dictionary.tableparams_tablefieldsconfigicon;
    this.excelIcon =
      environment.cdn + dictionary.tableparams_tabledataexporticon;
    this.ellipsisIcon =
      environment.cdn + dictionary.tableparams_tableactionsicon;
    this.dragIcon =
      environment.cdn + dictionary.tableparams_tablereorderitemsicon;
    this.languageIcon =
      environment.cdn + dictionary.tableparams_tablelanguageicon;

    if (!this.idTable) {
      throw new TypeError("'idTable' is required"); // TODO dictionary
    }

    this.setDefaultLocale();

    const settings: SdTableSettings = {
      key: this.idTable,
      settings: {
        sortBy: this.sortTableBy,
        columnsForEdit: this.columns,
      },
    };

    this.store.dispatch(
      this.isDialogTable
        ? new TableDialogGetConfigRequested({ data: settings })
        : new TableGetConfigRequested({ data: settings })
    );

    this.store.dispatch(
      this.isDialogTable
        ? new LoadTableDialogData({ data: this.data })
        : new LoadTableData({ data: this.data })
    );
    if (this.isDialogTable) {
      this.filteredData$ = this.store.pipe(
        select(sdTableDialogFilteredDataSelector)
      );
      this.columns$ = this.store.pipe(select(sdTableDialogColumnsSelector));
      this.sortTable$ = this.store.pipe(select(sdTableDialogSortTableSelector));

      this.elementsPerPage$ = this.store.pipe(
        select(sdTableDialogElementsPerPageSelector)
      );
      this.elementsPerPageOptions$ = this.store.pipe(
        select(sdTableDialogElementsPerPageOptionsSelector)
      );
      this.page$ = this.store.pipe(select(sdTableDialogPageSelector));
      this.displayColumns$ = this.store.pipe(
        select(sdTableDialogColumnsSelector),
        map(columns => columns.filter(column => column.visible)),
        map(columns => columns.map(column => column.definition)),
        map(columns =>
          this.sortableTableWithDraggingRows
            ? ['reorder', ...columns, 'actions']
            : [...columns, 'actions']
        ),
        map(columns =>
          this.hideActions
            ? [...columns].filter(column => column !== 'actions')
            : columns
        )
      );
      this.setColumnsForEdit();
      this.dataForEdit = JSON.parse(JSON.stringify([...this.data]));
      return;
    }
    this.filteredData$ = this.store.pipe(select(sdTableFilteredDataSelector));
    this.columns$ = this.store.pipe(select(sdTableColumnsSelector));
    this.sortTable$ = this.store.pipe(select(sdTableSortTableSelector));
    this.elementsPerPage$ = this.store.pipe(
      select(sdTableElementsPerPageSelector)
    );
    this.elementsPerPageOptions$ = this.store.pipe(
      select(sdTableElementsPerPageOptionsSelector)
    );
    this.page$ = this.store.pipe(select(sdTablePageSelector));
    this.displayColumns$ = this.store.pipe(
      select(sdTableColumnsSelector),
      map(columns => columns.filter(column => column.visible)),
      map(columns => columns.map(column => column.definition)),
      map(columns =>
        this.sortableTableWithDraggingRows
          ? ['reorder', ...columns, 'actions']
          : [...columns, 'actions']
      ),
      map(columns =>
        this.hideActions
          ? [...columns].filter(column => column !== 'actions')
          : columns
      )
    );
    this.setColumnsForEdit();
    this.dataForEdit = JSON.parse(JSON.stringify([...this.data]));

    if (this.optionFilter) {
      this.optionFilterControllerValueChanges();
    }
  }

  private optionFilterControllerValueChanges() {
    this.optionFilterController.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyref),
        tap(optionSelected => {
          this.optionForFilterSelected.emit(optionSelected);
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.data = changes.data.currentValue;
      this.dataForEdit = JSON.parse(JSON.stringify([...this.data]));
      this.store.dispatch(
        this.isDialogTable
          ? new LoadTableDialogData({ data: this.data })
          : new LoadTableData({ data: this.data })
      );
    }
  }

  ngOnDestroy(): void {
    this.filter$.next('');
    this.filter$.complete();
    this.subscription.unsubscribe();
    if (this.isDialogTable) {
      this.store.dispatch(new ClearTableDialogData());
      return;
    }
    this.store.dispatch(new ClearTableData());
  }

  onFilterChange(filter: string): void {
    this.filter$.next(filter);
    if (this.isDialogTable) {
      this.store.dispatch(new TableDialogFilterChange({ filter }));
      this.store.dispatch(new TableDialogPageChange({ page: 0 }));
      return;
    }
    this.store.dispatch(new TableFilterChange({ filter }));
    this.store.dispatch(new TablePageChange({ page: 0 }));
  }

  onDropRowCheck(): boolean {
    if (!this.sortableTableWithDraggingRows) {
      return;
    }
    let beforeYouMustSortByOrderId = false;
    let observableTmp$: Observable<SdTableSortEvent>;
    observableTmp$ = this.store.pipe(select(sdTableSortTableSelector));
    if (this.isDialogTable) {
      observableTmp$ = this.store.pipe(select(sdTableDialogSortTableSelector));
    }
    observableTmp$
      .pipe(
        take(1),
        tap(sortBy => {
          if (
            sortBy?.active !== 'orderId' ||
            (sortBy?.active === 'orderId' && sortBy?.direction !== 'asc')
          ) {
            beforeYouMustSortByOrderId = true;
          }
        })
      )
      .subscribe();

    if (beforeYouMustSortByOrderId) {
      this.store.dispatch(
        new SdDialogConfirmOpen({
          title: 'sortalert_title',
          description: 'sortalert_description',
          buttons: {
            cancel: 'globalparams_cancel',
            confirm: 'globalparams_confirm',
          },
          callback: () => this.orderByColumnValue('orderId', 'asc'),
        })
      );
    }
    return !beforeYouMustSortByOrderId;
  }

  onDropRow(event): void {
    if (event.currentIndex === event.previousIndex) {
      return;
    }
    if (UtilsHelper.checkListWithOrderIdIsInvalid(this.data)) {
      return;
    }
    const data = [...this.data].sort(
      (a, b) =>
        a[`${this.sortableElementKey}`] - b[`${this.sortableElementKey}`]
    );
    const selectedElementIndex = data.findIndex(
      ele =>
        ele[`${this.sortableElementKey}`] ===
        event.item.data[`${this.sortableElementKey}`]
    );
    const { currentIndex, previousIndex } = event;
    const movingUp = previousIndex < currentIndex;
    const dropElementIndex =
      selectedElementIndex + currentIndex - previousIndex;

    // NOTE exception
    if (dropElementIndex < 0 || dropElementIndex >= data.length) {
      return;
    }

    let newOrderId;
    if (dropElementIndex === 0) {
      newOrderId = data[0][`${this.sortableElementKey}`] / 2;
    } else if (dropElementIndex === data.length - 1) {
      newOrderId =
        data[dropElementIndex][`${this.sortableElementKey}`] +
        (1 - data[dropElementIndex][`${this.sortableElementKey}`]) / 2;
    } else {
      newOrderId = movingUp
        ? data[dropElementIndex][`${this.sortableElementKey}`] +
          (data[dropElementIndex + 1][`${this.sortableElementKey}`] -
            data[dropElementIndex][`${this.sortableElementKey}`]) /
            2
        : data[dropElementIndex][`${this.sortableElementKey}`] -
          (data[dropElementIndex][`${this.sortableElementKey}`] -
            data[dropElementIndex - 1][`${this.sortableElementKey}`]) /
            2;
    }
    const item = { ...event.item.data };
    item[`${this.sortableElementKey}`] = newOrderId;
    this.sortedItem.emit({ item, oldItem: { ...event.item.data } });
    return;
  }

  onToggleChange(checked, event, key): void {
    const item = { ...event };
    item[key] = checked;
    this.toggleItem.emit({ item, oldItem: { ...event } });
    return;
  }

  onChangePage(event: PageEvent): void {
    if (
      !!this.nextPageCallback &&
      (event.pageIndex > event.previousPageIndex ||
        event.pageSize > event.length)
    ) {
      this.nextPageCallback();
    }
    if (this.isDialogTable) {
      this.store.dispatch(
        new TableDialogElementsPerPagesChange({
          elementsPerPage: event?.pageSize,
        })
      );
      this.store.dispatch(
        new TableDialogPageChange({ page: event?.pageIndex })
      );
      return;
    }
    this.store.dispatch(
      new TableElementsPerPagesChange({ elementsPerPage: event?.pageSize })
    );
    this.store.dispatch(new TablePageChange({ page: event?.pageIndex }));
  }

  onSortData(event): void {
    if (this.isDialogTable) {
      this.store.dispatch(new TableDialogSortChange({ event }));
      return;
    }
    this.store.dispatch(new TableSortChange({ event }));
  }

  onRightClick(event: MouseEvent, item) {
    event.preventDefault();
    this.rightMenuTopLeftPosition.x = event.clientX + 'px';
    this.rightMenuTopLeftPosition.y = event.clientY + 'px';
    this.triggerForRightMenu.menuData = { item: item };
    this.triggerForRightMenu.openMenu();
  }

  copyCellValueToClipboard(item: string | object): void {
    this.store
      .pipe(
        select(dictionarySelector),
        take(1),
        tap((dictionary: Dictionary) => {
          if (typeof item !== 'object') {
            this.clipboard.copy(SdTableHelper.removeAllHtmlTagsFromText(item));
            this.showSnackBar(dictionary.globalparams_copiedtoclipboard, 5000);
            return;
          }
          this.clipboard.copy(
            SdTableHelper.removeAllHtmlTagsFromText(JSON.stringify(item))
          );

          this.showSnackBar(dictionary.globalparams_copiedtoclipboard, 5000);
        })
      )
      .subscribe();
  }

  exportAllDataToExcel(): void {
    const dataForExport = this.getDataSortByColumnForExport();
    // NOTE deep copy
    const dataFlat = JSON.parse(JSON.stringify([...dataForExport]));
    // NOTE stringify the data for export
    dataFlat.forEach(rowObject => {
      Object.keys(rowObject).map(key => {
        rowObject[key] =
          typeof rowObject[key] === 'object'
            ? JSON.stringify(rowObject[key])
            : rowObject[key];
      });
    });

    this.xlsxService.exportTableToExcel(
      dataFlat,
      moment(new Date()).format('YYYYMMDDHHmmss-') +
        (this.idTable
          ? this.idTable + this.extension
          : this.fileName + this.extension),
      this.sheet
    );
  }

  orderByColumnValue(columnKey: string, direction: string): typeof this.data {
    let dataForSort = JSON.parse(JSON.stringify(this.dataForEdit));
    dataForSort = SdTableHelper.sortData(dataForSort, {
      active: columnKey,
      direction:
        SdTableSortDirection.asc === direction
          ? SdTableSortDirection.asc
          : SdTableSortDirection.desc,
    });
    this.actionsOnChangeOrder(dataForSort, columnKey, direction);
    return JSON.parse(JSON.stringify(dataForSort));
  }

  dropItemColumn(event: CdkDragDrop<object>): void {
    const columnsForEdit = JSON.parse(JSON.stringify(this.columnsForEdit));
    moveItemInArray(columnsForEdit, event.previousIndex, event.currentIndex);
    this.columnsForEdit = columnsForEdit;
    this.store.dispatch(
      this.isDialogTable
        ? new TableDialogColumnsChange({ columns: columnsForEdit })
        : new TableColumnsChange({ columns: columnsForEdit })
    );
    this.saveSettingsInLocalStorage();
  }

  onValueColumnChange(
    event: boolean,
    itemColumn: SdTableColumn,
    index: number
  ): void {
    const columnsForEdit = JSON.parse(JSON.stringify(this.columnsForEdit));
    columnsForEdit[index] = { ...itemColumn, visible: event };
    this.columnsForEdit = columnsForEdit;
    this.store.dispatch(
      this.isDialogTable
        ? new TableDialogColumnsChange({ columns: columnsForEdit })
        : new TableColumnsChange({ columns: columnsForEdit })
    );
    this.saveSettingsInLocalStorage();
  }

  private setColumnsForEdit(): void {
    if (!this.isDialogTable) {
      this.store
        .pipe(
          select(sdTableColumnsSelector),
          take(1),
          tap(columns => {
            this.columnsForEdit = columns;
          })
        )
        .subscribe();
    }
    if (this.isDialogTable) {
      this.store
        .pipe(
          select(sdTableDialogColumnsSelector),
          take(1),
          tap(columns => {
            this.columnsForEdit = columns;
          })
        )
        .subscribe();
    }
  }

  private setDefaultLocale(): void {
    this.store
      .pipe(
        select(localeDefaultSelector),
        take(1),
        tap(locale => {
          this.defaultLocale = locale;
        })
      )
      .subscribe();
  }

  private showSnackBar(message: string, duration: number): void {
    this.store.dispatch(
      new SdSnackBarDisplaySnackBar({
        message,
        duration,
      })
    );
  }

  private getDataSortByColumnForExport(): MyRow[] {
    let dataSorted = [];
    let observableTmp$: Observable<SdTableSortEvent>;
    observableTmp$ = this.store.pipe(select(sdTableSortTableSelector));
    if (this.isDialogTable) {
      observableTmp$ = this.store.pipe(select(sdTableDialogSortTableSelector));
    }
    observableTmp$
      .pipe(
        take(1),
        tap(sortBy => {
          if (sortBy) {
            dataSorted = this.orderByColumnValue(
              sortBy.active,
              sortBy.direction
            );
          }
          if (!sortBy) {
            dataSorted = this.orderByColumnValue('orderId', 'asc');
          }
        })
      )
      .subscribe();
    return this.sortByColumnHeader(dataSorted);
  }

  private sortByColumnHeader(dataSorted: typeof this.data): MyRow[] {
    const dataForExport: MyRow[] = [];
    dataSorted.forEach(item => {
      let rowForExport: MyRow = null;
      this.columnsForEdit.forEach(column => {
        for (const prop in item) {
          if (
            Object.prototype.hasOwnProperty.call(item, prop) &&
            column.key === prop
          ) {
            if (column.cellType === SdTableCellType.img) {
              const itemValue = <string>item[prop]?.split('/');
              // NOTE split by '/' and get last element
              const imgName = itemValue?.[itemValue?.length - 1];
              rowForExport = { ...rowForExport, [column.title]: imgName };
            }

            if (column.cellType === SdTableCellType.svg) {
              if (typeof item[prop] === 'string') {
                const itemValue = <string>item[prop]?.split('/');
                // NOTE split by '/' and get last element
                const imgName = itemValue?.[itemValue?.length - 1];
                rowForExport = { ...rowForExport, [column.title]: imgName };
              }

              if (typeof item[prop] === 'object') {
                const itemValueFlat = JSON.stringify(item[prop]);
                rowForExport = {
                  ...rowForExport,
                  [column.title]: itemValueFlat,
                };
              }
            }

            // NOTE remove html tags from string
            if (column.cellType === SdTableCellType.html) {
              const text = SdTableHelper.removeAllHtmlTagsFromText(
                <string>item[prop]
              );
              rowForExport = { ...rowForExport, [column.title]: text };
            }

            if (
              column.cellType !== SdTableCellType.img &&
              column.cellType !== SdTableCellType.svg &&
              column.cellType !== SdTableCellType.html
            ) {
              rowForExport = { ...rowForExport, [column.title]: item[prop] };
            }
          }
        }
      });
      dataForExport.push(rowForExport);
    });
    return dataForExport;
  }

  private actionsOnChangeOrder(
    dataForSort: typeof this.data,
    columnKey: string,
    direction: string
  ): void {
    this.store.dispatch(
      this.isDialogTable
        ? new TableDialogSortChange({
            event: {
              active: columnKey,
              direction:
                SdTableSortDirection.asc === direction
                  ? SdTableSortDirection.asc
                  : SdTableSortDirection.desc,
            },
          })
        : new TableSortChange({
            event: {
              active: columnKey,
              direction:
                SdTableSortDirection.asc === direction
                  ? SdTableSortDirection.asc
                  : SdTableSortDirection.desc,
            },
          })
    );

    this.store.dispatch(
      this.isDialogTable
        ? new LoadTableDialogData({ data: dataForSort })
        : new LoadTableData({ data: dataForSort })
    );
    this.store.dispatch(
      this.isDialogTable
        ? new TableDialogColumnsChange({ columns: this.columnsForEdit })
        : new TableColumnsChange({ columns: this.columnsForEdit })
    );

    this.saveSettingsInLocalStorage({
      active: columnKey,
      direction:
        SdTableSortDirection.asc === direction
          ? SdTableSortDirection.asc
          : SdTableSortDirection.desc,
    });
  }

  private saveSettingsInLocalStorage(sortBy?: SdTableSortEvent): void {
    if (!sortBy) {
      let observableForSdTableSortSelector$: Observable<SdTableSortEvent>;
      observableForSdTableSortSelector$ = this.store.pipe(
        select(sdTableSortTableSelector)
      );
      if (this.isDialogTable) {
        observableForSdTableSortSelector$ = this.store.pipe(
          select(sdTableDialogSortTableSelector)
        );
      }
      observableForSdTableSortSelector$
        .pipe(
          take(1),
          tap(sortByFromStore => {
            if (sortByFromStore) {
              sortBy = sortByFromStore;
            }
            if (!sortByFromStore) {
              sortBy = {
                active: 'orderId',
                direction: SdTableSortDirection.asc,
              };
            }
          })
        )
        .subscribe();
    }

    const settings: SdTableSettings = {
      key: this.idTable,
      settings: {
        sortBy: {
          active: sortBy.active,
          direction: sortBy.direction,
        },
        columnsForEdit: this.columnsForEdit,
      },
    };

    this.store.dispatch(
      this.isDialogTable
        ? new TableDialogSaveConfigRequested({ data: settings })
        : new TableSaveConfigRequested({ data: settings })
    );
  }

  sdTableTrackByFn(index: number, item: any): number {
    return item?.id ?? item?._id ?? index;
  }

  sdTableHeaderTrackByFn(index: number, item: SdTableColumn): number | string {
    return item?.key ?? index;
  }

  rowActionsTrackByFn(
    index: number,
    item: SdTableRowActionButton
  ): number | string {
    return item.key ?? index;
  }

  onLocaleFilterSelected(locale: string): void {
    this.localeFilterSelected.emit(locale);
  }
}
