<mat-form-field [hideRequiredMarker]="hideRequiredMarker">
  <input
    #inputElement
    [formControl]="controller"
    [leadZeroDateTime]="true"
    [mask]="mask"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [type]="type"
    matInput />
  <div
    *ngIf="
      !!prefixText &&
      prefixText.length > 0 &&
      !!controller.value &&
      controller.value.length > 0
    "
    class="prefix-container"
    matPrefix>
    <span>{{ prefixText }}</span>
  </div>

  <div class="suffix-container" matSuffix>
    <div *ngIf="!!suffixText && suffixText.length > 0">
      <span>{{ suffixText }}</span>
    </div>
    <app-sd-icon-button
      (click)="onSuffix()"
      *ngIf="!!suffix && suffix.length > 0 && !suffixIsImage"
      [disabled]="!suffixIsClickable"
      [icon]="suffix"
      [primary]="suffixIconHasPrimaryColor"></app-sd-icon-button>
    <app-sd-svg-button
      *ngIf="!!suffix && suffix.length > 0 && suffixIsImage"
      (buttonClicked)="onSuffix()"
      [color]="
          suffixIconHasPrimaryColor
            ? 'var(--primaryColor)'
            : 'var(--primaryAccentColor)'
        "
      [height]="15"
      [src]="suffix"
      [width]="15"></app-sd-svg-button>
    <app-sd-tooltip-button
      *ngIf="!!tooltip && tooltip.length > 0"
      [tooltip]="tooltip"></app-sd-tooltip-button>
    <mat-spinner
      diameter="20"
      mode="indeterminate"
      *ngIf="controller?.pending"></mat-spinner>
  </div>
  <mat-error *ngIf="controller?.invalid">
    <span>{{ error }}</span>
  </mat-error>
</mat-form-field>
